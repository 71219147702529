import { IClientLoginInfo } from 'Domain/Entities/user-client-login-info.entity';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import BidTimer from './BidTimer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

const BidLoggingProvider: FC<PropsWithChildren> = ({ children }) => {
    const { Username = '' } = useSelector((state: AppState) => state.account.clientLoginInfo) || ({} as IClientLoginInfo);
    const bids: BidTimer[] = [];

    const addBidUpdate = (refNum: string, bidValue: number, endTime: Date) => {
        const index = bids.findIndex((x) => x.refNum === refNum);

        window.logger.info('Adding Bid Update', index >= 0);

        if (index < 0) return;

        const timers = bids.splice(index, 1);

        if (timers.length !== 1) {
            //Problem... should not be more than 1 timer...
            window.logger.log('Returned Timers: ', timers);
            return;
        }

        const timer = timers[0];

        timer.setEndTime(endTime);
        timer.setBidValue(bidValue);

        window.logger.log('Log Bid Timing: ', timer.toString());

        newrelic.addPageAction('bid_response_times', {
            status: 'updated',
            ...timer.toObject()
        });
    };

    const addPlaceBid = (refNum: string, lot: string, startTime: Date) => {
        const index = bids.findIndex((x) => x.refNum === refNum);

        window.logger.info('Log Place Bid', index >= 0);

        if (index >= 0) return;

        const bidTimer = new BidTimer(refNum, lot, Username, startTime);

        bids.push(bidTimer);

        window.logger.log('Bid Timers: ', bids);
    };

    const flushBids = () => {
        while (bids.length >= 0) {
            const timer = bids.shift();

            if (!timer) continue;

            newrelic.addPageAction('bid_response_times', {
                status: 'updated',
                ...timer.toObject()
            });
        }
    };

    return <BidLoggingContext.Provider value={{ addBidUpdate, addPlaceBid, flushBids }}>{children}</BidLoggingContext.Provider>;
};

export default BidLoggingProvider;

interface IBidLoggingContext {
    addPlaceBid: (refNum: string, lot: string, startTime: Date) => void;
    addBidUpdate: (refNum: string, bidValue: number, endTime: Date) => void;
    flushBids: () => void;
}

const BidLoggingContext = createContext<IBidLoggingContext>({
    addBidUpdate: (refNum: string, bidValue: number, endTime: Date) => {
        return;
    },
    addPlaceBid: (refNum: string, lot: string, startTime: Date) => {
        return;
    },
    flushBids: () => {
        return;
    }
});

export const useBidLoggingContext = (): IBidLoggingContext => useContext(BidLoggingContext);

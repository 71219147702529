import React, { FC } from 'react';

import classes from './notify-badge.module.css';
import NotifyBadgeIcon from './NotifyBadgeIcon';

interface IWatchlistIcon {
    isVisible?: boolean;
    isMobile?: boolean;
}

const WatchlistIcon: FC<IWatchlistIcon> = ({ isVisible = true, isMobile = false }) => {
    return (
        <div className={classes['notify-badges']}>
            <NotifyBadgeIcon
                key="watchlist"
                title="Watch list"
                label="On your watch list"
                classes={[classes['watch'], isMobile ? classes['badge'] : '']}
                styles={{ visibility: isVisible ? 'visible' : 'hidden', marginTop: isMobile ? '5px' : '' }}
            />
        </div>
    );
};

export default WatchlistIcon;

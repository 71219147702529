import { DateHelper } from 'Common/Helpers/DateHelper'; 
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { useGetGradeHtml } from 'Features/AuctionControls/Hooks/useGetGradeHtml.hook';
import React, { FC } from 'react';

import { IApiVehicleDetailsViewModel } from './AuctionResults.container';

interface IAuctionResultsEntryProps {
    lot: IApiVehicleDetailsViewModel;
}

const AuctionResultsEntry: FC<IAuctionResultsEntryProps> = ({ lot }) => {

    const odometerHtml = () => {
        return (
            <>
                {lot.mileage && lot.mileage > 0 ? lot.mileage : ''} {lot.mileageText ?? '—'}
            </>
        );
    }

    const saleResultHtml = () => {
        const saleResult = lot.saleResult === "Provisionally Sold" ? "Provisional" : lot.saleResult === "Unsold" 
                                                                   ? "Not Sold" : lot.saleResult;
        return (
            <>
                <div>{saleResult}</div>
                {lot.hammerPrice.toCurreny()}
            </>
        );
    }     

    const gradehtml = useGetGradeHtml(lot?.grade);

    return (
        <ListTableRow className='auction-result'>
            <ListTableCell value1={lot.lot ?? ''} value2={lot.regNo ?? ''} />
            <ListTableCell value1={lot.make ?? ''} value2={DateHelper.ToDateString(lot.regDate ?? '')} />
            <ListTableCell value1={lot.model ?? ''} value2={lot.engineSize ?? ''} />
            <ListTableCell value1={lot.variant ?? ''} value2={lot.bodyType ?? ''} />
            <ListTableCell value1={odometerHtml()} value2={<InspectionReportLink variant="small" 
                           linkUrl={lot.inspectionURL} />} classes="halign-right" />
            <ListTableCell value1={gradehtml} classes="halign-center" />
            <ListTableCell value1={saleResultHtml()} classes="halign-center" value2="&nbsp;" />
        </ListTableRow>
    );
};

export default AuctionResultsEntry;

import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { Sale } from 'Domain/Entities/sale.entity';
import { handleSetPrimarySale, handleSetPrimarySaleLots } from 'Features/MainSale/main-sale.service';
import { setInputBoxValue } from 'Features/UserMessaging/chat-messaging.slice';
import { OtherSessionType, setHasOtherSession } from 'Infrastructure/UserAccount/user-account.slice';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { addJoinedSale, getSaleLotsList, getSalesList, joinedSalesSelectors, leaveJoinedSale, SaleLots, saleLotsSelectors, saleSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import { usePhenixWebRtcContext } from '../PhenixVideoPlayer/PhenixWebRtcContext/PhenixWebRtcProvider';
import { ISaleListItem } from './Interfaces/sale-list-item.interface';

const isGTMEnabled = JSON.stringify(process.env.REACT_APP_ENABLE_IS_GTM) === JSON.stringify("yes");

export const handleJoinSaleSuccess = (newRefNum: string, isMobile: boolean) => {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        try {
            const { mainSale: { sale: { refNum = '' } = {} } = {} } = getState();

            if (refNum === newRefNum || isMobile) {
                dispatch(handleSetPrimarySaleLots(newRefNum));
            }

        } catch (error) {
            window.logger.error(error);
        }
    };
};

export const handleSendVehicleSoldEventToGTM = (refNum: string, lotNum: string, currentBidValue: string) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        try {
            const { auctions : { sales, saleLots } } = getState();

            const { saleDescription = '' } = saleSelectors.selectById(sales, refNum) || {} as Sale;

            const { derivative = '', make = '', model = '', regNo = '' } =  ((saleLotsSelectors.selectById(saleLots, refNum ) || {} as SaleLots)?.lotsList?.find(x => x.lot === lotNum) || {} as IApiLotViewModel);

            const currency = process.env.REACT_APP_CURRENCY_CURRENCY || 'GBP';

            const tagManagerArgs = {
                dataLayer: {
                    event: 'purchase',
                    ecommerce: {
                        transaction_id: `Lot ${lotNum} : ${saleDescription} - ${refNum}`,
                        value: currentBidValue,
                        currency: currency,
                        items: [{
                            item_id: regNo,
                            item_name: `${make}, ${model} - ${derivative}`,
                            price: currentBidValue,
                            quantity: 1
                        }]
                    }
                },
                dataLayerName: 'dataLayer'
            };

            TagManager.dataLayer(tagManagerArgs);

        } catch(error) {
            window.logger.error(error);
        }
    };
};

export const useJoinSale = (): {
    isLoading: boolean;
    handleJoinSale: (siteId: string, refNum: string, suid: string, createBidderId: boolean, useGlobalBidderId: boolean) => Promise<void>;
} => {
    const [isLoading, setIsLoading] = useState(true);
    const { connection } = useWebSocketConnection();
    const dispatch: AppDispatch = useDispatch();
    const otherSessionErrorCode = 2;

    const { account: { userRole = '' } = {} } = useSelector((state: AppState) => state);

    const joinedSales = joinedSalesSelectors.selectIds(useSelector((state: AppState) => state.auctions.joinedSales));

    const { mainSale: { sale } = {} } = useSelector((state: AppState) => state);

    const isMobile = useIsMobile();

    const joinSale = async (siteId: string, refNum: string, suid: string, createBidderId: boolean, useGlobalBidderId: boolean) => {

        try {
            setIsLoading(true);

            if ((connection && !joinedSales.includes(refNum) && !sale) || isMobile)
                dispatch(handleSetPrimarySale(refNum));

            await Promise.all([dispatch(addJoinedSale({ refNum, buyerNumber: '' })), dispatch(getSaleLotsList(refNum))]);

            if (connection && !joinedSales.includes(refNum)) {
                const results = await Promise.all([
                    connection.invoke('JoinSale', siteId, refNum, userRole, createBidderId, useGlobalBidderId),
                    dispatch(handleJoinSaleSuccess(refNum, isMobile))
                ]);

                const { success = false, reason = 0, message = 'An error occured while joining sale' } = results[0] || {};

                if (!success) {
                    toastr.warning('Unable to Join Sale', message || 'An error occured while joining the sale', { timeOut: 0 });

                    if (reason === otherSessionErrorCode) {
                        //Magic number 2 = Server side enum JoinSaleErrorCodes
                        dispatch(setHasOtherSession({ hasOtherSession: true, otherSessionType: OtherSessionType.CLOSED }));
                    }

                    dispatch(leaveJoinedSale({ refNum, buyerNumber: '' }));
                }
                else if (isGTMEnabled) {
                    const tagManagerArgs = {
                        dataLayer: {
                            event: 'exists'
                        },
                        dataLayerName: 'dataLayer'
                    };

                    TagManager.dataLayer(tagManagerArgs);
                }
            }
        } catch (error) {
            window.logger.error(error);

            dispatch(leaveJoinedSale({ refNum, buyerNumber: '' }));
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading, handleJoinSale: joinSale };
};

export const useLeaveSale = (bearerToken: string, isMobile: boolean): ((siteId: string, refNum: string, workOrder: string) => void) => {
    const { connection } = useWebSocketConnection();
    const dispatch: AppDispatch = useDispatch();
    const { disposeChannelExpressObject } = usePhenixWebRtcContext();

    const leaveSale = async (siteId: string, refNum: string, workOrder: string) => {
        disposeChannelExpressObject(refNum);
        const response = await connection?.invoke('LeaveSale', siteId, refNum, bearerToken, workOrder);

        if (response) {
            dispatch(leaveJoinedSale({ refNum, buyerNumber: '' }));
            isMobile && dispatch(setInputBoxValue(''));
        }
    };

    return leaveSale;
};

export const useGetOrderedSalesList = (): { isLoading: boolean; salesList: ISaleListItem[] } => {
    const dispatch: AppDispatch = useDispatch();
    const isMobile = useIsMobile();
    const [salesList, setSalesList] = useState<ISaleListItem[]>([]);
    const { joinedSales: joinedSalesState, sales: saleState, salesIsLoading } = useSelector((state: AppState) => state.auctions);
    const { sale: { refNum: primarySaleRefNum } = {} } = useSelector((state: AppState) => state.mainSale);

    const sales = saleSelectors.selectAll(saleState);
    const joinedSales = joinedSalesSelectors.selectIds(joinedSalesState);

    useEffect(() => {
        dispatch(getSalesList());
    }, []);

    useEffect(() => {
        if (!sales || !sales.length) {
            return;
        }

        if (isMobile) {
            const salesList = sales.map((sale) => {
                return { ...sale, isJoined: joinedSales.includes(sale.refNum) } as ISaleListItem;
            });

            setSalesList(salesList);

            return;
        }

        if (!joinedSales || !joinedSales.length) {
            setSalesList(sales as ISaleListItem[]);

            return;
        }

        const orderedList: ISaleListItem[] = sales
            .filter((sale) => sale.refNum !== primarySaleRefNum)
            .sort((a, b) => {
                const aIndex = joinedSales.indexOf(a.refNum);
                const bIndex = joinedSales.indexOf(b.refNum);
                return (bIndex > -1 ? bIndex : Infinity) - (aIndex > -1 ? aIndex : Infinity);
            })
            .reduce((prev, current) => {
                if (joinedSales.includes(current.refNum)) {
                    prev.unshift({ ...current, isJoined: true });
                } else {
                    prev.push({ ...current, isJoined: false });
                }

                return prev;
            }, [] as ISaleListItem[]);

        setSalesList(orderedList);
    }, [isMobile, sales, joinedSales, primarySaleRefNum]);

    return { isLoading: salesIsLoading, salesList };
};

import { setUserRole } from 'Infrastructure/UserAccount/user-account.slice';
import React from 'react'; 
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from 'services/redux-store';

import classes from './select-role.module.scss'

const SelectUserRole = () => {
    const dispatch: AppDispatch = useDispatch(); 
    const navigate = useNavigate();

    const handleRoleSelected = (e: any) => {
        e.preventDefault();

        const target = e.target as HTMLButtonElement;

        if (target.id === 'buyer-role') {
            dispatch(setUserRole({ userRole: 'buyer' }));

            navigate('/buyer');
        }
        else if (target.id === 'vendor-role') {
            dispatch(setUserRole({ userRole: 'vendor' }));

            navigate('/vendor');
        }
    }

    return (
        <>
            <div className={classes['center']}>
                <div className={classes['select-role']}>
                    <form noValidate={false} autoComplete="off">
                        <h1>Choose Simulcast App</h1>
                        <button className={`${classes['lozenge']} ${classes['input-button']}`} title="Buyer App" id="buyer-role" onClick={handleRoleSelected}>
                            Buyer App
                            </button>
                        <br />
                        <br />
                        <button className={`${classes['lozenge']} ${classes['input-button']}`} title="Vendor App" id="vendor-role" onClick={handleRoleSelected}>
                            Vendor App
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SelectUserRole;

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SaleLots, saleLotsSelectors, vendorClientsOnlineSelectors, VendorSaleClientsOnline } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import classes from '../auction-control.module.scss';
import { useGetUserLookerSuperUser, useIsVendor } from '../auction-controls.hooks';
import AuctionStats from '../Views/AuctionStats.view';
import ActiveBidders from './ActiveBidders';

export interface IBiddersOnline {
    refNum: string;
}

const ActiveAndOnlineBidders: FC<IBiddersOnline> = ({ refNum }) => {
    const isShowOnlineBidders = JSON.stringify(process.env.REACT_APP_ENABLE_BIDDERS_ONLINE) === JSON.stringify('true');
    const isVendor = useIsVendor();
    const { isSuperUser = false } = useGetUserLookerSuperUser();
    const { noOfClients = 0 } = useSelector((state: AppState) => vendorClientsOnlineSelectors.selectById(state.auctions.vendorSaleClientsOnline, refNum) || ({} as VendorSaleClientsOnline));
    const { currentLot: { lot: lotNo = '' } = {}, lotsList = [] } = useSelector((state: AppState) => saleLotsSelectors.selectById(state.auctions.saleLots, refNum) || ({
        currentLot: { lot: '' }, lotsList: [] }));
    const { account: { permissions: { showActiveBidders = false } = {} } = {} } = useSelector((state: AppState) => state);
    const [isOffered, setIsOffered] = useState(true);
    const [clientsOnline, setClientsOnline] = useState(0);

    useEffect(() => {
        setIsOffered(false);

        if (isShowOnlineBidders && lotsList.find((lot) => lot.lot === lotNo)?.isMyLot) {
            setIsOffered(true);
            const activeBuyerCount = Number(noOfClients);

            setClientsOnline(activeBuyerCount);
        }
    }, [lotNo, noOfClients]);

    return isVendor && !isSuperUser && isOffered && (isShowOnlineBidders || showActiveBidders) ? (
        <AuctionStats>
            <div className={`${classes['bidders-online-container']} ${classes['no-space-wrap']}`}>
                BIDDERS - &nbsp;
                {isShowOnlineBidders && <span>Online: <b>{clientsOnline}</b> &nbsp;</span>}
                {showActiveBidders && <ActiveBidders refNum={refNum} />}
            </div>
        </AuctionStats>
    ) : null;
};

export default ActiveAndOnlineBidders;

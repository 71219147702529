import { DateHelper } from 'Common/Helpers/DateHelper';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import ListTable from 'Common/ListTable/ListTable';
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import NoResult from 'Common/NoResult/NoResult';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { IApiLotViewModel, LiveLotsStatus } from 'Common/Sales/Sales.interface';
import ScrollbarTab from 'Common/ScrollbarTab/ScrollbarTab';
import http from 'Infrastructure/HttpService/http.service';
import React, { FC, useEffect, useState } from 'react';

import WinningLotMobileView from './WinningLotMobileView';

interface IWinningLotsListProps {
    refNum: string;
    bearerToken: string;
    currentLotNumber: string;
}

const WinningLots: FC<IWinningLotsListProps> = ({ bearerToken, refNum, currentLotNumber }) => {
    const [winningLots, setWinningLots] = useState<IApiLotViewModel[]>([]);

    const isMobile = useIsMobile();

    useEffect(() => {
        const fetchResults = async () => {
            
            if(!refNum) return;
            
            try {
                const response = await http.get(`api/lot/${isMobile?'MobileWinningLots':'winningLots'}?refNum=${refNum}`, {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`
                    }
                });
                const { data } = response;

                setWinningLots(data as IApiLotViewModel[]);
            } catch (error) {
                setWinningLots([]);
            }
        };

        fetchResults();
    }, [currentLotNumber, refNum,isMobile]);

    const isWinningLotAvailable = winningLots?.length > 0;

    const currencySymbol = '£';

    const getWinningLotDetailsHeader = () => {
        return (
            <ListTableRow className='header'>
                <ListTableCell value1="Lot" value2="Reg." />
                <ListTableCell value1="Make" value2="Reg. Date" />
                <ListTableCell value1="Model" value2="Engine Size" />
                <ListTableCell value1="Derivative" value2="Body Style" />
                <ListTableCell value1="Odometer" value2="Inspection Report" />
                <ListTableCell value1="Hammer Price" classes="halign-right" value2="&nbsp;" />
                <ListTableCell value1="&nbsp;" value2="&nbsp;" />
            </ListTableRow>
        );
    };

    const odometerHtml = (lot: IApiLotViewModel) => {
        return (
            <>
                {lot.mileage && lot.mileage > 0 ? lot.mileage : ''} &nbsp;
                {lot.mileageText && lot.mileageText.toLowerCase() !== 'unknown' ? lot.mileageText : '—'}
            </>
        );
    };

    let totalHammerPrice = 0;

    const winningLotsDetails = winningLots?.map((lot, index) => {
        totalHammerPrice += lot?.hammer ?? 0;

        const status = (lot.status ?? -1) as LiveLotsStatus;
        let hammerPrice: string = lot.hammer?.toCurreny() ?? '';
        let hammerPriceTextColor = '';

        if (status === LiveLotsStatus.Sold) {
            hammerPrice += ' Sold';

            hammerPriceTextColor = 'sold';
        } else if (status === LiveLotsStatus.ProvisionallySold) {
            hammerPrice += ' Provisional';

            hammerPriceTextColor = 'sold-prov';
        } else if (status === LiveLotsStatus.Unsold) {
            hammerPrice += ' Unsold';

            hammerPriceTextColor = 'unsold';
        }

        return (
            <ListTableRow key={index}>
                <ListTableCell value1={lot.lot ?? ''} value2={lot.regNo ?? ''} />
                <ListTableCell value1={lot.make ?? ''} value2={DateHelper.ToDateString(lot.regDate ?? '')} />
                <ListTableCell value1={lot.model ?? ''} value2={lot.engineSize ?? ''} />
                <ListTableCell value1={lot.derivative ?? ''} value2={lot.bodyType ?? ''} />
                <ListTableCell
                    value1={odometerHtml(lot)}
                    value2={<InspectionReportLink variant="small" linkUrl={lot.inspectionURL} />}
                />
                <ListTableCell
                    value1={hammerPrice}
                    textColorClassVal1={hammerPriceTextColor}
                />
            </ListTableRow>
        );
    });

    const displayWinningLotsDetails = () => {
        return (
            <>
                {winningLotsDetails}
                <br />
                <div style={{ fontSize: '15px' }}>
                    Total Purchases: {currencySymbol}
                    {totalHammerPrice.toLocaleString()}
                </div>
            </>
        );
    };

    return (isMobile?<WinningLotMobileView winningLots={winningLots} refNum={refNum}/>:
        <ScrollbarTab>
            <ListTable>
                <>
                    {getWinningLotDetailsHeader()}
                    {isWinningLotAvailable ? displayWinningLotsDetails() : null}
                </>
            </ListTable>
            <>
                {!isWinningLotAvailable ? <NoResult><>No purchases</></NoResult> : null}
            </>
        </ScrollbarTab>
    );
};

export default WinningLots;

//TODO: create global instance with config...
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { IClientLoginInfo } from 'Domain/Entities/user-client-login-info.entity';
import { usePhenixWebRtcContext } from 'Features/PhenixVideoPlayer/PhenixWebRtcContext/PhenixWebRtcProvider';
import { setBearerToken, setClientLoginInfo } from 'Infrastructure/UserAccount/user-account.slice';
import { useUserAccountContext } from 'Infrastructure/UserAccount/UserAccountProvider';
import jwt_decode from 'jwt-decode';
import { Dispatch, FormEvent, SetStateAction, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useLocation, useParams } from 'react-router';
import { browser_session } from 'services/browser-storage';
import { AppDispatch } from 'services/redux-store';

export const useLogoutUser = (): (() => Promise<void>) => {
    const { connection, sendSocketMessage } = useWebSocketConnection();
    const { disposeChannelExpressObject } = usePhenixWebRtcContext();
    const { logout } = useUserAccountContext();

    const handleLogout = async () => {
        try {
            disposeChannelExpressObject();
            sendSocketMessage('Logout', [true]).then(() => connection?.stop());
            logout();

        }
        catch (error) {
            window.logger.error(error);
        }
    };

    return handleLogout;
};

export const useHasUserPreviouslyLoggedIn = (): void => {
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        const jwt = browser_session.get('__token');

        if (jwt) {
            dispatch(setBearerToken(jwt));

            const clientLoginInfo = getClientLoginInfoFromJWT(jwt);

            dispatch(setClientLoginInfo(clientLoginInfo));
        }
    }, []);
};

export const useIsRedirectFromWebsite = (setIsRedirectFromWebsite: Dispatch<SetStateAction<boolean>>): { user_email: string; query: URLSearchParams } => {
    const location = useLocation();

    const { user_email = '' } = useParams() as any;
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        if (location.pathname === '/') {
            setIsRedirectFromWebsite(false);
        } else if (user_email && query.get('sessionId')) {
            setIsRedirectFromWebsite(true);
        } else {
            toastr.warning('Automatic Login Issue', 'Unable to automatically log you in, please try re-entering your username and password.');

            setIsRedirectFromWebsite(false);
        }
    }, []);

    return { user_email, query };
};

export const useHandleRedirectLogin = (
    query: URLSearchParams,
    setIsRedirectFromWebsite: Dispatch<SetStateAction<boolean>>
): ((username: string, sessionId: string) => void) => {
    const { login } = useUserAccountContext();

    const handleLoginRedirect = (username: string, sessionId: string) => {
        const password = `sessionid:${sessionId}`;

        login(username, password).then(() => browser_session.set('__session', query.toString()))
            .catch(() => setIsRedirectFromWebsite(false));
    };

    return handleLoginRedirect;
};

export const useHandleLoginSubmit = (): ((e: FormEvent<HTMLFormElement>, username: string, password: string) => void) => {
    const { login } = useUserAccountContext();

    const handleLoginSubmit = (e: FormEvent<HTMLFormElement>, username: string, password: string) => {
        toastr.clean();
        e.preventDefault();
        e.stopPropagation();
        login(username, password);
    };

    return handleLoginSubmit;
};

const buyerTypeRegex = new RegExp('^B$', 'i');
const lookerTypeRegex = new RegExp('^L$', 'i');
const vendorTypeRegex = new RegExp('^M$', 'i');
const superUserTypeRegex = new RegExp('^(S|D)$', 'i');

export const getClientLoginInfoFromJWT = (jwt: string) => {
    const decoded: any = jwt_decode(jwt);
    return decoded['cli'] ? JSON.parse(decoded['cli']) : null;
};

export const isClientLoginInfoBuyerOrLooker = (clientLoginInfo: IClientLoginInfo | null | undefined): boolean => {
    const { ATP = '' } = clientLoginInfo || {};
    return buyerTypeRegex.test(ATP) || lookerTypeRegex.test(ATP);
};

export const isUrlRedirectBuyerOrLooker = (queryParams: URLSearchParams): boolean => {
    const type = queryParams.get('appletType') || '';
    return buyerTypeRegex.test(type) || lookerTypeRegex.test(type);
};

export const isClientLoginInfoVendor = (clientLoginInfo: IClientLoginInfo | null | undefined): boolean => {
    const { ATP = '' } = clientLoginInfo || {};
    return vendorTypeRegex.test(ATP);
};

export const isUrlRedirectVendor = (queryParams: URLSearchParams): boolean => {
    const type = queryParams.get('appletType') || '';
    return vendorTypeRegex.test(type);
};

export const isClientLoginInfoSuperUser = (clientLoginInfo: IClientLoginInfo | null | undefined): boolean => {
    const { ATP = '' } = clientLoginInfo || {};

    return superUserTypeRegex.test(ATP);
};

import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import WelcomeScreen from 'Common/WelcomeScreen/WelcomeScreen';
import { BiddingState, biddingStateSelectors } from 'Features/AuctionControls/bidding-state.slice';
import AuctionListView from 'Features/AuctionsList/Views/AuctionList.view';
import MainSale from 'Features/MainSale/MainSale.container';
import { useJoinSessionSale } from 'Pages/Common/buyer-vendor.service';
import classes from 'Pages/Common/Views/buyer-vendor-view.module.css';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

const BuyerVendorContainer: FC = () => {
    const { sale: primarySale } = useSelector((state: AppState) => state.mainSale);

    const { mainSale: { currentLot = {}, sale = {} } = {} } = useSelector((state: AppState) => state);

    useJoinSessionSale();

    const isMobile = useIsMobile();

    const { askingPrice = '' } = useSelector((state: AppState) => biddingStateSelectors.selectById(state, sale.refNum) || ({} as BiddingState));

    const vehicleName = currentLot?.lotDetails?.make || '';

    const regNo = currentLot?.lotDetails?.regNo || '';

    const auctionName = sale?.branch || '';

    return (
        <>
            {/*   GTM: gtm-primary-sale, gtm-auction class and gtm attributes required for GA. */}
            <div className={`gtm-auction gtm-primary-sale ${classes['buyer-vendor-view-body']}`}
                data-gtm-auction-name={auctionName} data-gtm-vehicle-name={vehicleName}
                data-gtm-vehicle-reg={regNo} data-gtm-next-bid-value={askingPrice}>

                <AuctionListView />

                {(Boolean(primarySale) || isMobile) ? <MainSale isMobile={isMobile} /> : <WelcomeScreen />}

            </div>

        </>
    );
};

BuyerVendorContainer.whyDidYouRender = true;

export default BuyerVendorContainer;

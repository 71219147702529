import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

// // eslint-disable-next-line @typescript-eslint/no-explicit-any
// declare const newrelic: any;

//TODO: Move to somewhere... maybe Account or ?
export const useIsBuyer = (): boolean => {
    const { userRole } = useSelector((state: AppState) => state.account);

    return (userRole == 'buyer');
};

//TODO: Move to somewhere... maybe Account or ?
export const useIsVendor = (): boolean => {
    const { userRole } = useSelector((state: AppState) => state.account);

    return (userRole == 'vendor');
};
//TODO: Move to somewhere... maybe Account or ? Also split to seperate hooks, isLooker and is SuperUser
export const useGetUserLookerSuperUser = (): { isLooker: boolean; isSuperUser: boolean } => {
    const {
        account: { isLooker = false, clientLoginInfo: { ISU = 'False' } = {} }
    } = useSelector((state: AppState) => state);

    return { isLooker, isSuperUser: ISU === 'True' };
};

declare global {
    interface Number {
        toCurreny(options?: CurrencyOptions): string;
    }
}

type CurrencyOptions = { locale?: string; currency?: string; maxFractionDigits?: number; minFractionDigits?: number };

const locale = process.env.REACT_APP_CURRENCY_LOCALE || 'en-GB';

const currency = process.env.REACT_APP_CURRENCY_CURRENCY || 'GBP';

Number.prototype.toCurreny = function (this: number, options: CurrencyOptions) {
    const useCurrency = options?.currency || currency;
    const useLocale = options?.locale || locale;
    const useMaxDecimal = options?.maxFractionDigits || 0;
    const useMinDecimal = options?.minFractionDigits || 0;

    return new Intl.NumberFormat(useLocale, {
        style: 'currency',
        currency: useCurrency,
        maximumFractionDigits: useMaxDecimal,
        minimumFractionDigits: useMinDecimal
    }).format(this);
};

export { };

import React, { FC, ReactElement, ReactNode } from 'react';

import classes from './data-table.module.scss';

interface IDataTableHeader {
    item?: string;
    children?: ReactNode;
}

const DataTableHeader: FC<IDataTableHeader> = ({ item, children }) => {
    return (
        <div className={classes['key']}>
            {item}{children}
        </div>

    );
};

export default DataTableHeader;

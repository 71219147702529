import { IAuctionControl } from 'Features/AuctionControls/AuctionControls.container';
import { BlockedSale, blockedSalesSelectors } from 'Infrastructure/UserAccount/user-account.slice';
import React, { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { JoinedSale, joinedSalesSelectors, SaleLots, saleLotsSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import { BiddingHistory, biddingHistorySelectors } from './bidding-details.slice';
import classes from './bidding-history.module.css';
import { BidHistoryMessageTypes } from './bidding-messages.types';
import BidHistoryMessage from './BidHistoryMessage';

interface IBiddingDetailsProps {
    refNum: string;
    children?: ReactElement<IAuctionControl>;
}

(window as any).fixBidingDetailsHeight = () => {
    const tabView = document.getElementById('pricing-bidding-container');
    const mobileFooter = document.getElementById('mobile-footer');

    if (tabView && mobileFooter) {
        const tabViewRect = tabView.getBoundingClientRect();
        const mobileFooterRect = mobileFooter.getBoundingClientRect();
        const correctHeight = mobileFooterRect.top - tabViewRect.top;

        tabView.style.height = `${correctHeight}px`;
    }
};

const BiddingDetails: FC<IBiddingDetailsProps> = ({ refNum, children }) => {
    const { joinedSales } = useSelector((state: AppState) => state.auctions);
    const { buyerNumber = '' } = (joinedSalesSelectors.selectById(joinedSales, refNum) as JoinedSale) || {};
    const { history, account: { userId = '', permissions : { showManheimBidders = false } = {} } = {} } = useSelector((state: AppState) => state);
    const { messages = [] } = (biddingHistorySelectors.selectById(history, refNum) as BiddingHistory) || {};
    const { isBlocked: isUserBlocked } = useSelector((state: AppState) => (blockedSalesSelectors.selectById(state, refNum) as BlockedSale) || {});
    const { lotsList = [], currentLot: { lot: currentLotNo = ''} = {} } = useSelector((state: AppState) => saleLotsSelectors.selectById(state.auctions.saleLots, refNum) || ({} as SaleLots));

    useEffect(() => {
        if (isUserBlocked) {
            messages.slice(
                0,
                messages.findIndex((x) => x.type === BidHistoryMessageTypes.MESSAGE_BLOCK)
            );
        }
    }, [isUserBlocked]);

    useEffect(() => {
        window.onresize = (window as any).fixBidingDetailsHeight;
    }, []);

    useEffect(() => {
        (window as any).fixBidingDetailsHeight();
    });

    const { userRole } = useSelector((state: AppState) => state.account);
    const isShowManheimBids: boolean = ((userRole === 'vendor') && showManheimBidders && (lotsList.find((lot) => lot.lot === currentLotNo)?.isMyLot));

    const buyerNumberTitle = userRole === 'buyer' ? 'Bidder Number : ' : 'Seller Number : ';

    return (
        <div className={classes['timeline']}>
            <div className={classes['bid-detail-header']}>
                <div className={classes['title']}>Bid detail</div>
                <div className={classes['bidder-number']}>
                    {buyerNumberTitle} {buyerNumber}
                </div>
            </div>
            {isUserBlocked ? (
                <div className={`${classes['unavailable']}`}>You have been disconnected from this auction. Please contact the branch for details.</div>
            ) : null}
            {children}
            <div className={classes['history']}>
                <div className={classes['scrollbar-v']}>
                    <ul className={classes['items']}>
                        { messages.map((message, index) => <BidHistoryMessage key={index} bidMessage={message} index={index} isShowManheimBids={isShowManheimBids} userId={userId} userRole={userRole} />) }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default BiddingDetails;

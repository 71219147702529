//See this accepted answer: https://stackoverflow.com/questions/39877156/how-to-extend-string-prototype-and-use-it-next-in-typescript
declare global {
    interface String {
        isEmptyOrWhitespace(): boolean;
        isNullishWhitespaceOrEmpty(value: string): boolean;
        isEqualIgoreCase(this: string, value: string): boolean;
    }
}
const whitespaceRegex = new RegExp(/^\s*$/);

String.prototype.isEmptyOrWhitespace = function (this: string) {
    if (!this) {
        return true; //Is Empty string;
    }

    return whitespaceRegex.test(this.toString()); //Test for whitespace;
};

String.prototype.isNullishWhitespaceOrEmpty = function (value: string) {
    if (!value) {
        return true; //is null or undefined;
    }

    return value.isEmptyOrWhitespace();
};

String.prototype.isEqualIgoreCase = function (this: string, value: string): boolean {
    if (typeof value !== 'string') return false;

    return this.toLowerCase() === value.toLowerCase();
};

export {};

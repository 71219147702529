export const setObjectKeyValue = (obj: Record<string, any>, key: string, value: any): Record<string, any> => {
    //TODOxMF: maybe try chaning this to .some array method
    Object.keys(obj).forEach((k) => {
        if (k === key) {
            obj[k] = value;

            return;
        }
        if (Array.isArray(obj[k])) {
            return;
        }
        if (obj[k] && typeof obj[k] === 'object') {
            setObjectKeyValue(obj[k], key, value);
        }
    });

    return obj;
};

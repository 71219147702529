export enum AuctionControlButtonTitle {
    PREPARE_TO_BID_TITLE = 'Prepare to Bid',
    NOT_HIGEST_BIDDER_TITLE = 'Bid',
    IS_HIGHEST_BIDDER_TITLE = 'Highest Bidder',
    BID_SUBMITTED_TITLE = 'Bid Submitted',
    IS_LOOKER_TITLE = 'View Only',
    PREPARE_TO_SELL_TITLE = 'Prepare to Sell',
    CLICK_TO_SELL_TITLE = 'Click to Sell',
    ON_SALE_TITLE = 'On Sale',
    UNAUTHORISED_TO_SELL_TITLE = 'Unauthorised to Sell'
}

import React, { FC, ReactNode } from 'react';

import classes from './dialog-container.module.scss';

interface IDialogContainer {
    isOpen: boolean;
    handleClose?: () => void;
    children: ReactNode;
}

const DialogContainer: FC<IDialogContainer> = ({ isOpen, handleClose, children }) => {
    if (isOpen) {
        return (
            <div className={classes['dialog-overlay']}>
                <div className={classes['dialog-container']}>
                    <div className={classes['dialog-content']}>
                        {handleClose && (
                            <span title="Close" className={classes['close-btn']} onClick={handleClose}>
                                &#10006;
                            </span>
                        )}

                        {children}
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default DialogContainer;

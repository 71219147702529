/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
 
const isDebugging = JSON.stringify(process.env.REACT_APP_ENABLE_IS_DEBUGGING) === JSON.stringify("true") || false;
 
const logger = {
    log: (message: string, ...params: any[]): void => {

        if (isDebugging) {
            console.log(message, ...params);
        }
    },
    error: (message: any, ...params: any[]): void => {
        if (isDebugging) {
            console.error(message, ...params);
        }
    },
    warn: (message: string, ...params: any[]): void => {
        if (isDebugging) {
            console.warn(message, ...params);
        }
    },
    info: (message: string, ...params: any[]): void => {
        if (isDebugging) {
            console.info(message, ...params);
        }
    }
};

export default logger;

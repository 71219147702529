import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import mobileBlankImageURL from 'img/content/spacer_mobile.jpg';
import blankImageURL from 'img/content/spacer.jpg';
import React, { FC } from 'react';

interface IVendorLogo {
    cssClasses?: string;
    vendorLogoURL: string;
}

const VendorLogo: FC<IVendorLogo> = ({ cssClasses = '', vendorLogoURL }) => {
    const isMobile = useIsMobile();

    const handleVendorLogoURLError = (e: any) => {
        e.target.onerror = null;

        e.target.src = isMobile ? mobileBlankImageURL : blankImageURL;
    };

    return (
        vendorLogoURL ? <img className={cssClasses} src={vendorLogoURL} alt="Vendor Logo" onError={handleVendorLogoURLError} /> : null
    );
};

export default VendorLogo;

import React, { FC, MouseEvent } from 'react';

import classes from './close-button.module.css';

interface ICloseButton {
    onCloseMainAuction?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    title: string;
    cssClass?:string;
}

const CloseButton: FC<ICloseButton> = ({ onCloseMainAuction, title, cssClass='' }) => {
    return (
        <a href="#" onClick={onCloseMainAuction} className={`${classes['closer']} ${cssClass}`}  title={title}></a>
    );
};

export default CloseButton;

import Heading from 'Common/Heading/Heading';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import React, { FC, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from '../chat-messaging.module.css';
import { ChatMessage, ChatMessageType } from '../chat-messaging.slice';
import ChatMessageCaller from '../Components/ChatMessageCaller';
import ChatMessageOperator from '../Components/ChatMessageOperator';

interface IChatMessagesList {
    messages: Array<ChatMessage>;
}

const ChatMessagesList: FC<IChatMessagesList> = ({ messages = [] }) => {
    const listRef = useRef<HTMLDivElement>(null);
    const { sale } = useSelector((state: AppState) => state.mainSale);
    const isMobile = useIsMobile();

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current?.scrollHeight;
        }
    }, [messages]);

    return (
        <div ref={listRef} className={`${classes['chat-messages']} ${classes['scrollbar-v']}`}>
            {(isMobile && !sale) ? <Heading size={'medium'} color={'blue'} textAlign={'center'}>To use the message function please join a sale.</Heading>
                : messages.map((message, i) => message.messageType === ChatMessageType.operator
                    ? <ChatMessageOperator key={`${ChatMessageType.operator}_${i}`} message={message.text} />
                    : <ChatMessageCaller key={`${ChatMessageType.caller}_${i}`} message={message.text} />
                )}
        </div>
    );
};

export default ChatMessagesList;

/* eslint-disable @typescript-eslint/no-var-requires */
/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react';

const isEnabled = process.env.REACT_APP_ENABLE_WHY_DID_YOU_RENDER === 'true' || false;

window.logger.log('wdyr:enabled', process.env.REACT_APP_ENABLE_IS_DEBUGGING);

if (process.env.NODE_ENV === 'development' && isEnabled) {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    const ReactRedux = require('react-redux');

    whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackExtraHooks: [[ReactRedux, 'useSelector']]
    });
}

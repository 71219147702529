import { EntityState } from '@reduxjs/toolkit';
import { Sale } from 'Domain/Entities/sale.entity';
import { blockedSalesSelectors } from 'Infrastructure/UserAccount/user-account.slice';
import { JoinedSale, joinedSalesSelectors, SaleLots, saleLotsSelectors, saleSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import { BiddingHistory, biddingHistorySelectors, messageRecieved, setSaleHistoryCurrentLot } from './bidding-details.slice';
import { BidHistoryMessageTypes } from './bidding-messages.types';

export const isLotUpdateInSequence = (message: any) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        const { refNum, lot, type }: { refNum: string; lot: string; type: number } = message;

        if (type !== BidHistoryMessageTypes.LOT_CHANGED) {
            return;
        }

        const { history } = getState();
        const { saleLots } = getState().auctions;

        const { lotsList: lots = [] } = (saleLotsSelectors.selectById(saleLots, refNum) as SaleLots) ?? {};

        const { currentLot } = (biddingHistorySelectors.selectById(history, refNum) as BiddingHistory) ?? {};

        if (lot !== currentLot) {
            dispatch(setSaleHistoryCurrentLot({ refNum, lot }));
        }

        if (lots?.length) {
            let expected = '';
            const currentLotNo = parseInt(currentLot) || 0;

            lots.some((item) => {
                if (item.status === 0 && parseInt(item.lot) > currentLotNo) {
                    expected = item.lot;

                    return true;
                }
            });

            if (lot != currentLot && expected != lot) {
                dispatch(
                    messageRecieved({
                        refNum,
                        lot,
                        cAC: '',
                        type: BidHistoryMessageTypes.WARNING_1,
                        message: 'This lot is out of sequence',
                        currentBidUser: '',
                        recievedDate: ''
                    })
                );
            }
        }
    };
};

export const handleIsUserInClosedSale = (message: any) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        const { refNum, type } = message;

        if (type === BidHistoryMessageTypes.BIDDING_CLOSED) {
            return;
        }

        //const { sales: saleState } = getState().auctions;
        const {
            history,
            auctions: { joinedSales: joinedSalesState = {} as EntityState<JoinedSale>, sales: saleState = {} as EntityState<Sale> } = {}
        } = getState();
        const sales = saleSelectors.selectAll(saleState);
        const joinedSaleIds = joinedSalesSelectors.selectIds(joinedSalesState);

        const { branchId, siteId, saleDescription } = sales.find((x) => x.refNum === refNum) || {};

        sales
            .filter((s) => s.refNum !== refNum && s.branchId === branchId && s.siteId === siteId)
            .forEach((s) => {
                const isJoinedSale = joinedSaleIds.includes(s.refNum);

                if (isJoinedSale) {
                    const biddingHistory = biddingHistorySelectors.selectById(history, s.refNum) || ({} as BiddingHistory);
                    const hasNotActiveMessage = biddingHistory.messages.some((x) => x.type === BidHistoryMessageTypes.NOT_ACTIVE_SALE);

                    if (!hasNotActiveMessage) {
                        dispatch(
                            messageRecieved({
                                refNum: s.refNum,
                                lot: '',
                                cAC: '',
                                type: BidHistoryMessageTypes.NOT_ACTIVE_SALE,
                                message: `The sale you have selected is not the active sale for this lane. The active sale is ${saleDescription}`,
                                currentBidUser: '',
                                recievedDate: ''
                            })
                        );
                    }
                }
            });
    };
};

export const handleAddBidHistoryMessage = (message: any) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        const { refNum, type } = message;

        const entity = blockedSalesSelectors.selectById(getState(), refNum);

        if (!entity?.isBlocked) {
            dispatch(messageRecieved(message));
        }
    };
};

export default {};

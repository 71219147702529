import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import React, { FC, ReactElement } from 'react';

import { ITabContent } from './TabContent'; 

interface ITabContainer 
{
    activeTab: string | number;
    children: ReactElement<ITabContent>[];
}

const TabContainer: FC<ITabContainer> = ({ activeTab, children }) => {
    const isMobile = useIsMobile();
     
    return (
        <div>
            {children.map((child, index) => {
                const { label } = child.props;

                return (label === activeTab || (!activeTab && isMobile && index === 0)) ? 
                <div key={`${child.key}_${index}`} id={label} className='gtm-page gtm-active'>
                    {child}
                 </div> : null;
                })
            }
        </div>
    );
};

export default TabContainer;

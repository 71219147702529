import NoResult from 'Common/NoResult/NoResult';
import React, { FC } from 'react';

import classes from './inspection-report-pdf.module.scss'
interface IInspectionReportPdf {
    inspectionReportUrl: string; 
}

interface ITimeoutReload {
    handleReload: () => void;
    inspectionReportUrl: string;
}

const TimeoutReload: FC<ITimeoutReload> = ({ handleReload, inspectionReportUrl }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h1>Loading Inspection report timed out </h1>
                {'  '}
                <button onClick={handleReload}>Try Reload</button>
            </div>
            <p>OR</p>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: 'center' }}>
                <h2>
                    Click Icon to open in another Tab{'  '}
                    <a
                        href={inspectionReportUrl}
                        target="_blank"
                        title="Inspection Report"
                        rel="noopener noreferrer"
                        className="status-badge insp-report inspection-report-trigger"
                        style={{
                            filter: !inspectionReportUrl ? 'grayscale(100%) opacity(60%)' : '',
                            pointerEvents: !inspectionReportUrl ? 'none' : 'auto'
                        }}></a>
                </h2>
            </div>
        </div>
    );
};

const InspectionReportPdf: FC<IInspectionReportPdf> = ({ inspectionReportUrl }) => {
    if (inspectionReportUrl === undefined) return null; 
     
    if (inspectionReportUrl) {
        const httpsInspectionUrl = inspectionReportUrl.replace('http://', 'https://');

        return (
            <div className={classes['inspection-report']}>
                <iframe src={`/pdfjs/web/viewer.html?file=${httpsInspectionUrl}`} width="100%" height="100%"></iframe>
            </div>
        );
    } else {
    return <NoResult><>There is no Inspection Report available for the current lot</></NoResult>;
    }
};

export default InspectionReportPdf;

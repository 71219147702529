import './image-gallery.css';

import { IApiVehicleImageViewModel } from 'Common/Sales/Sales.interface';
import React, { FC, MouseEvent, useEffect, useState } from 'react';

interface IImageGallery {
    images: IApiVehicleImageViewModel[];
}

const ImageGallery: FC<IImageGallery> = ({ images }) => {


    const [imageIndex, setImageIndex] = useState(0);
    const totalImages = images?.length ?? 0;

    useEffect(() => {
        setImageIndex(0);
    }, [images]);

    const nextImage = () => {
        setImageIndex((imageIndex) => (imageIndex + 1) % totalImages);
    };

    useEffect(() => {

        const interval = setInterval(() => {
            nextImage();
        }, 3000);
        return () => clearInterval(interval);

    }, []);

    const currentImage = images && images[imageIndex];

    return (
        <>
            <div className='gallery'>
                <div className='slides'>
                    <img src={currentImage?.imageUrl} className='slide' alt={`vehicle_image_${imageIndex}_${currentImage?.photoCaption}`} />
                </div>
                <p className='image-status'>{`${imageIndex + 1} of ${totalImages}`}</p>
            </div>
        </>
    );
};

export default ImageGallery;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component, ComponentType, ErrorInfo } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const withErrorBoundary = <P extends object>(WrappedComponent: ComponentType<P>) => {
    return class ErrorBoundary extends Component<P> {
        state = {
            hasError: false,
            error: null,
            errorInfo: null
        };

        static getDerivedStateFromError(error: unknown) {
            // Update state so the next render will show the fallback UI.
            return { hasError: true, error };
        }

        componentDidCatch = (error: unknown, errorInfo: ErrorInfo) => {
            window.logger.error(error, errorInfo);
        };

        render() {
            if (this.state.hasError) {
                return (
                    <div>
                        <h2>An Error Occured...</h2>
                        {/* <code>{JSON.stringify(this.state.error, null, 4)}</code> */}
                    </div>
                );
            }

            return <WrappedComponent {...(this.props as P)} />;
        }
    };
};

export default withErrorBoundary;

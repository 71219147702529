import Accordion from 'Common/Accordion/Accordion';
import AccordionContent from 'Common/Accordion/AccordionContent';
import AccordionTitle from 'Common/Accordion/AccordionTitle';
import ExpandIcon from 'Common/Accordion/ExpandIcon';
import DataTable from 'Common/DataTable/DataTable';
import DataTableColumn from 'Common/DataTable/DataTableColumn';
import DataTableColumns from 'Common/DataTable/DataTableColumns';
import DataTableInner from 'Common/DataTable/DataTableInner';
import Heading from 'Common/Heading/Heading';
import { DateHelper } from 'Common/Helpers/DateHelper';
import useIntersectionObserver from 'Common/Hooks/useIntersectionObserver.hook';
import WatchlistIcon from 'Common/NotifyBadgeIcon/WatchlistIcon';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { useGetPricingInfo } from 'Features/AuctionControls/Hooks/useGetPricingInfo.hook';
import noImageURL from 'img/content/no-image.svg';
import React, { FC, useEffect, useRef, useState } from 'react'

import FieldDisplay from '../FieldDisplay/FieldDisplay';
import auctionHeaderCss from '../MainSaleHeader/auction-header.module.scss';
import LotHeaderLights from '../MainSaleHeader/LotHeaderLights';
import classes from './all-lots-card-view.module.css';
import { IAllLotsItem } from './AllLotsListItem'

const AllLotsMobileView: FC<IAllLotsItem> = ({ lot, isCurrentLot, divCurrentLotRef }) => {
	const imageRef = useRef(null);
	
	const [imageURL, setImageURL] = useState(noImageURL);

	const isFated = [1, 2, 3].includes(lot.status || 0);

	const isIntersectedInDOM = useIntersectionObserver(imageRef);
	
	const {capRetail, capClean, capAverage, glassesGuide, make, model, derivative, transmission, inspectionFuelType, 
		   regDate, exteriorColour, bodyType, mileage, mileageText, warranted, regNo, lightboxImageUrl, vendor,
		   location, v5, sureCheck, sureCheckText, sureCheckResult, checkPointCertificateId, watching, inspectionURL } = lot;
	
	const prices = useGetPricingInfo({capRetail, capClean, capAverage, glassesGuide});
	
	const lotName = `${make || ''} - ${model || ''} - ${derivative || ''}`;							
	
	const vehicleDetails = [transmission, inspectionFuelType, DateHelper.ToDateString(regDate ?? ''), 
	                       exteriorColour, bodyType, (mileage ? mileage + " " + (mileageText ?? '') : ''), 
						   (`${(warranted == undefined) ? '' : `${!warranted ? 'Unw' : 'W'}arranted`}`)].filter(e => e).join(" - ");			   

    useEffect(() => {

		setImageURL(isIntersectedInDOM && lightboxImageUrl ? lightboxImageUrl : noImageURL);

	}, [isIntersectedInDOM]);

	return (
		<div className={`${isCurrentLot ? classes['div-current-Lot'] : ''} ${isFated ? classes['fated-lot'] : ''}`} 
		     ref={isCurrentLot ? divCurrentLotRef : null}>
			<section className={classes['lot-info-container']}>
				<section className={classes['section-image-and-number-plate']}>
					<Heading color='blue' size='small'>Lot {lot.lot}:</Heading>
					<span className={auctionHeaderCss['vehicle-reg']}>{regNo}</span>
					<img ref={imageRef} data-src={lot.lot} className={classes['lot-image']}
					     src={imageURL} onError={({ currentTarget }) => (currentTarget.src = noImageURL)} />
				</section>

				<section className={classes['section-lot-details']}>
					<Heading color='blue' size='small'>{lotName}</Heading>
					<div>{vehicleDetails}</div>
					{vendor && <div>Vendor: {vendor}</div>}
					<div>Location: {location}</div>
				</section>
			</section>

			<section className={classes['section-lot-lights']}>
				<LotHeaderLights
					v5={!Boolean(v5)}
					surecheck={Boolean(sureCheck)}
					surecheckText={sureCheckText ?? ''}
					sureCheckResult={sureCheckResult ?? ''}
					checkpoint={checkPointCertificateId ?? 0}
					announcement={''}
					remarks={''}
					watching={watching ?? false}
					lotDetails={lot}
					isShowVendorOnline={false} />
				<InspectionReportLink variant="small" linkUrl={inspectionURL} />
				{watching && <WatchlistIcon />}
			</section>

			<Accordion>
				<AccordionTitle>
					<p> Pricing Information</p>
					<ExpandIcon type='arrow' />
				</AccordionTitle>
				<AccordionContent>
					<DataTable className='expander-vehicle-details'>
						<DataTableColumns>
							<DataTableColumn>
								<DataTableInner className='vehicle-details-inner'>
									{
										prices.map((price, i) => <FieldDisplay key={i} displayName={price.label} value={price.value} />)
									}
								</DataTableInner>
							</DataTableColumn>
						</DataTableColumns>
					</DataTable>

				</AccordionContent>
			</Accordion>
		</div>
	)
}

export default AllLotsMobileView
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { IPricingInformationProps } from 'Domain/Entities/PricingInfoEntities.entity';
import { useGetPricingInfo } from 'Features/AuctionControls/Hooks/useGetPricingInfo.hook';
import React, { FC } from 'react';

import classes from './pricing-information.module.scss';

const PricingInformation: FC<IPricingInformationProps> = ({ glassesGuide, capRetail, capClean, capAverage, siv, inspectionDamageCost}) => {

    const isMobile = useIsMobile();

    const prices = useGetPricingInfo({capRetail, capClean, capAverage, glassesGuide, inspectionDamageCost, siv});

    return (
        <>
            <div className={classes['pricing-information-main']}>
                {isMobile ? (<span className={classes['pricing-information-title']}>Pricing Information </span>) :
                    (<h1 className={classes['pricing-information-title']}>
                        <div className={classes['tooltip']}>
                            <span className={classes['symbol']}>£</span>
                            <span className={classes['tooltip-text']}>Pricing Information</span>
                        </div>
                    </h1>)}
                <div className={classes['pricing-information']}>

                    {prices.map((price, key) => {
                        return (
                            <div className={`${classes['price-container']} ${classes[price.className]}`} key={key}>
                                <div className={classes['price-label']}>
                                    {price.label.split(' ').map((l, key) => (
                                        <span key={key}>{l}</span>
                                    ))}
                                </div>
                                <div className={classes['price-value']}>{price.value}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default PricingInformation;

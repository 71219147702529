import React, { FC } from 'react';

import classes from '../../../Dropdown/dropdown.module.scss';

interface IPreferencesDropdownIcon {
    color?: string;
    title?: string;
    iconName?: string;
}

const PreferencesDropdownIcon: FC<IPreferencesDropdownIcon> = ({ color = 'blackIcon', title = '', iconName = '' }) => {
    return (
        <>
            <span className={classes.label}>{title}</span>
            {
                iconName?.length && 
                    <span className={`material-icons md-24 ${classes[color]} ${classes['d']}`} >
                        {iconName}
                    </span>
            }
            <span className={classes.a}>
                <span className={classes.on}></span>
            </span>
        </>
    );
};

export default PreferencesDropdownIcon;

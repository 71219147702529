import React, { FC, ReactNode } from 'react'

import classes from './heading.module.css';

interface IHeadingProps {
    children: ReactNode;
    color: 'blue' | 'dark-blue';
    size?: 'small' | 'medium' | 'large' | 'extra-large';
    textAlign?: 'start' | 'center' | 'end'
}

const Heading: FC<IHeadingProps> = ({ children, size, textAlign, color = "dark-blue" }) => {
    return (
        <h1 className={`${classes[color]} ${size ? classes[size] : ''} ${classes['heading-component']} ${textAlign ? classes[textAlign] : ''}`}>
            {children}
        </h1>
    )
}

export default Heading;

import React, { FC } from 'react';

import classes from '../chat-messaging.module.css';

interface IChatMessageCaller {
    message: string;
}

const ChatMessageCaller: FC<IChatMessageCaller> = ({ message }) => {
    return (
        <div className={`${classes['caller']} ${classes['chat-message']}`}>
            <p className={classes['name']}>You</p>
            <p className={classes['message']}>{message}</p>
        </div>
    );
};

export default ChatMessageCaller;

import { HubConnection } from '@microsoft/signalr';
import { setBiddingInfo } from 'Features/AuctionControls/bidding-state.slice';
import { handleAddBidHistoryMessage, handleIsUserInClosedSale, isLotUpdateInSequence } from 'Features/BiddingHistory/bidding-details.service';
import { BidHistoryMessageTypes } from 'Features/BiddingHistory/bidding-messages.types';
import { useBidLoggingContext } from 'Features/BidLogging/BidLoggingProvider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSaleCurrentLot } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useConfigureSignalRBidMethods = (connection: HubConnection | undefined): void => {
    const dispatch = useDispatch();
    const { userRole, userId } = useSelector((state: AppState) => state.account);
    const { addBidUpdate } = useBidLoggingContext();

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on('bidHistoryUpdate', (message) => {
            window.logger.log('bidHistoryUpdate ', message);

            newrelic.addPageAction('signalr_event_bidHistoryUpdate', message);

            dispatch(handleAddBidHistoryMessage(message));

            dispatch(isLotUpdateInSequence(message));

            dispatch(handleIsUserInClosedSale(message));

            if (message.type === BidHistoryMessageTypes.BIDDING_CLOSED) {
                dispatch(setSaleCurrentLot(message));
            }
        });

        connection.on('bidInformationUpdate', (message) => {
            window.logger.log('bidInformationUpdate', message);

            newrelic.addPageAction('signalr_event_bidInformationUpdate', message);

            const { currentBid, refNum, askingPrice, cidb, currentBidUser, cid } = message;

            addBidUpdate(refNum, currentBid, new Date());

            if (currentBid >= 0 && askingPrice >= 0) {
                dispatch(
                    setBiddingInfo({
                        refNum,
                        currentBid,
                        askingPrice,
                        isUser: (!userId.isEmptyOrWhitespace() && userId.isEqualIgoreCase(currentBidUser)),
                        userId,
                        cid,
                        cidb
                    })
                );
            }
        });        

        return () => {
            connection.off('bidHistoryUpdate');

            connection.off('bidInformationUpdate');

            connection.off('blockedStatusUpdate');

            connection.off('activeBidders');
        };
    }, [connection, userRole, userId]);
};

import Accordion from 'Common/Accordion/Accordion';
import AccordionContent from 'Common/Accordion/AccordionContent';
import AccordionTitle from 'Common/Accordion/AccordionTitle';
import ExpandIcon from 'Common/Accordion/ExpandIcon';
import DataTable from 'Common/DataTable/DataTable';
import DataTableCell from 'Common/DataTable/DataTableCell';
import DataTableHeader from 'Common/DataTable/DataTableHeader';
import DataTableRow from 'Common/DataTable/DataTableRow';
import React, { FC } from 'react';

import { DateHelper } from '../../../../../Common/Helpers/DateHelper';
import RefreshLink from '../../../../../Common/ListTable/RefreshLink';
import { ISaleHistory } from '../../../../../Domain/Entities/ISalesHistory';
import classes from './sale-history-accordion.module.scss';

interface ISaleHistoryProps {
    saleHistory: ISaleHistory | undefined;
    onRefreshSaleHistory: () => void;
}

const SaleHistoryAccordion: FC<ISaleHistoryProps> = ({ saleHistory, onRefreshSaleHistory }) => {
    const saleHistoryTitle = 'Sale History (Physical and Simulcast)';

    const handleOnRefresh = (e: React.MouseEvent<HTMLAnchorElement>): void => {

        e.preventDefault();

        onRefreshSaleHistory();
    };

    return (
        <Accordion>
            <AccordionTitle title={saleHistoryTitle}>
                <p>
                    {saleHistoryTitle}
                </p>
                <ExpandIcon />
            </AccordionTitle>
            <AccordionContent>

                <div className={classes['sale-history']}>
                    <div className={classes['sale-history-entries']}>
                       PREVIOUS NUMBER OF ENTRIES
                               </div>
                    <div>
                        {saleHistory?.numberOfEntries}
                    </div>
                    <div>
                        <RefreshLink handleOnRefresh={handleOnRefresh} />
                    </div>
                </div>
                <DataTable className='multi-column'>
                    <DataTableRow>
                        <DataTableHeader item={'OFFERED'} />
                        <DataTableHeader item={'BEST BID'} />
                        <DataTableHeader item={'CHANNEL'} />
                        <DataTableHeader item={'BRANCH'} />
                    </DataTableRow>
                    {saleHistory?.saleHistories.map((item, index) => {
                        return (
                            <DataTableRow key={index}>
                                <DataTableCell item={DateHelper.ToDateDotString(item.saleStartDate ?? '')} />
                                <DataTableCell item={item?.bestBid ? '£'+item?.bestBid : '£0.00'} />
                                <DataTableCell item={item?.saleChannel} />
                                <DataTableCell item={item?.branch} />
                            </DataTableRow>)
                    })}
                </DataTable>

            </AccordionContent>
        </Accordion >
    )


}


export default SaleHistoryAccordion;
import { IVehicleImage } from 'Pages/Common/buyer-vendor.slice';
import React, { FC, MouseEvent, useEffect, useRef } from 'react';

import GalleryThumbnail from './GalleryThumbnail';
import classes from './vehicle-gallery.module.css';

interface IGalleryThumbnailList {
    vehicleImages: Array<IVehicleImage>;
    currentImageIndex: number;
    handleImageChange: (e: MouseEvent<HTMLAnchorElement>, index: number) => void;
}

const GalleryThumbnailList: FC<IGalleryThumbnailList> = ({ vehicleImages, currentImageIndex, handleImageChange }) => {
    const thumbnailsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        updateImageCurrentActiveClass(currentImageIndex);
    }, [currentImageIndex]);

    const handleThumbnailOnClick = (e: MouseEvent<HTMLAnchorElement>, index: number) => {
        e.preventDefault();

        updateImageCurrentActiveClass(index);

        handleImageChange(e, index);
    };

    const updateImageCurrentActiveClass = (index: number) => {
        if (thumbnailsRef.current) {
            for (const el of thumbnailsRef.current.children) {
                el.firstElementChild?.classList.remove(classes['current']);
            }

            thumbnailsRef.current.children[index].firstElementChild?.classList.add(classes['current']);
        }
    };

    return (
        <div className={classes['thumbnails']}>
            <div ref={thumbnailsRef} className={classes['inner']} style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll' }}>
                {vehicleImages.map((item: IVehicleImage, index: number) => (
                    <GalleryThumbnail
                        key={index}
                        index={index}
                        imageUrl={item.imageUrl}
                        currentImageIndex={currentImageIndex}
                        handleImageChange={handleThumbnailOnClick}
                        title={item.photoCaption || ''}
                    />
                ))}
            </div>
        </div>
    );
};

export default GalleryThumbnailList;

import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import React, { FC, ReactElement, RefObject, useEffect, useRef } from 'react';

import classes from './auto-height-scrollbar.module.scss';

interface IAutoHeightScrollbar {
    children: ReactElement | ReactElement[];
    announcement?: string;
    remark?: string;
}

const AutoHeightScrollbar: FC<IAutoHeightScrollbar> = ({ children, announcement, remark }) => {
    const isMobile = useIsMobile();

    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const isPortrait = window.innerHeight > window.innerWidth;

        if (isMobile && isPortrait && window.innerWidth <= 1024 && elementRef.current) {
            const mobileFooter = document.getElementById('mobile-footer');

            if (mobileFooter) {
                elementRef.current.style.height = mobileFooter.getBoundingClientRect()?.top - elementRef.current?.getBoundingClientRect().top + 'px';
            }
        }
    }, [isMobile, elementRef.current, announcement, remark]);

    return (
        <div className={classes['auto-height-scrollbar-container']} ref={isMobile ? elementRef : null}>
            {children}
        </div>
    );
};

export default AutoHeightScrollbar;

import React, { FC, ReactElement } from 'react';

import classes from './list-table.module.scss';

interface IListtable {
    children: ReactElement | ReactElement[];
    className?: 'fixed-layout' | 'all-lots-layout';
}

const ListTable: FC<IListtable> = ({ children, className }) => {
    return (
        <div className={`${classes['list-table']} ${className ? classes[className]:''}`}>
            {children}
        </div>
    );
};

export default ListTable;

import Heading from 'Common/Heading/Heading';
import { DateHelper } from 'Common/Helpers/DateHelper';
import React, { FC } from 'react'; 

import CloseButton from '../CloseButton/CloseButton'; 
import classes from './auction-header.module.scss';

export interface IAuctionHeader {
    lane: string;
    saleDescription?: string;
    saleDate: Date;
    auctioneers?: string[];
    isMobile: boolean;
    onCloseMainAuction?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const AuctionHeader: FC<IAuctionHeader> = ({
    lane = '',
    saleDescription = '',
    saleDate = '',
    auctioneers = [],
    isMobile,
    onCloseMainAuction
}) => {
    const date = DateHelper.ToDateString(saleDate);
    const time = DateHelper.GetTime(saleDate);

    const dateFrag = date ? date : '';
    const timeFrag = time ? time : '';

    return (
        <>
            <div className={classes['auction']}>
                <Heading size={'small'} color={'dark-blue'}>
                    {lane} <span className={classes['sale-date']}>{dateFrag}</span> <span className={classes['sale-time']}>{timeFrag}</span>
                </Heading>
                <p className={classes['subheadline']}>{saleDescription}</p>
            </div>
            {!isMobile && (<div className=''>
                <p className={classes['your-auctioneers']}>Your auctioneers for today are: {auctioneers.join(', ')}</p>
            </div>)}

            {/*GTM: auction-close-trigger class required for GA. */}
            <div className={classes['close-button']}>
                <CloseButton onCloseMainAuction={onCloseMainAuction} title="Close Auction" cssClass="auction-close-trigger" />
            </div>
        </>

    );
};

export default AuctionHeader;

import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import React, { FC } from 'react';

const ClientsOnlineHeader: FC = () => {
    return (
        <ListTableRow className='header'>
            <ListTableCell value1="Company" />
            <ListTableCell value1="Name" />
            <ListTableCell value1="Account Number" />
            <ListTableCell value1="Bidder Number" />
            <ListTableCell value1="Proxy Bidder" />
        </ListTableRow>
    );
};

export default ClientsOnlineHeader;

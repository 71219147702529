import React, { FC, ReactElement, ReactNode } from 'react';

import classes from './data-table.module.scss';

interface IDatatable {
    className?: 'expander-vehicle-details' | 'value-only' | 'multi-column';
    children: ReactElement | ReactElement[] | ReactNode;
}

const DataTable: FC<IDatatable> = ({ className, children }) => {
    return (
        <div className={`${classes['data-table']} ${className ? classes[className]:''}`}>
                {children}           
        </div>
    );
};

export default DataTable;

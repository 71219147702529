/* eslint-disable @typescript-eslint/no-explicit-any */
import logger from 'Infrastructure/DebugLogger/debug-logger.service';

declare global {
    interface Window {
        logger: {
            log: (message: string, ...params: any[]) => void;
            error: (message: any, ...params: any[]) => void;
            warn: (message: string, ...params: any[]) => void;
            info: (message: string, ...params: any[]) => void;
        };
    }
}

window.logger = logger;

import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import React, { FC } from 'react';

const TodaysAuctionResultsHeader: FC = () => {
    return (
        <>
            <ListTableRow className='header'>
                <ListTableCell value1="Branch" value2="Sale" classes="column-height" />
                <ListTableCell value1="Make" value2="Model" classes="column-height" />
                <ListTableCell value1="Reg Date" value2="Engine Size" classes="column-height" />
                <ListTableCell value1="Cap Clean" value2="Reserve" classes="column-height" />
            </ListTableRow>
            <ListTableRow className='header'>
                <ListTableCell value1="Lot" value2="Reg" classes="column-height" />
                <ListTableCell value1="Derivative" value2="Body Style" classes="column-height" />
                <ListTableCell value1="&nbsp;" value2="&nbsp;" classes="column-height" />
                <ListTableCell value1="Sale Result" value2="&nbsp;" classes="column-height" />
            </ListTableRow>
        </>
    );
};

export default TodaysAuctionResultsHeader;

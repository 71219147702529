import Heading from 'Common/Heading/Heading';
import { setHasOtherSession } from 'Infrastructure/UserAccount/user-account.slice';
import { useUserAccountContext } from 'Infrastructure/UserAccount/UserAccountProvider';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import classes from '../account.module.scss';

const TransferSession: FC = () => {
	const dispatch = useDispatch();

	const { logout } = useUserAccountContext();

	const handleTransferSession = () => {
		dispatch(setHasOtherSession(undefined));
	}

	const handleCancelTransfer = () => {
		logout();
	}

	return (
		<div className={`${classes['content-overlay']} ${classes.row}`}>
			<div className={`${classes.inset} ${classes.box}`}>
				<div className={classes.inner}>
					<Heading size={'large'} color={'dark-blue'}>
						IT LOOKS LIKE YOU&apos;RE ALREADY
						ONLINE
					</Heading>

					<div className={`${classes['rounded-border-transfer-div']} ${classes['row']}`}>
						<span className={classes['transfer-arrows']}>⇄</span>
						<Heading size={'medium'} color={'dark-blue'}>Transfer to this new session</Heading>
						<Heading size={'medium'} color={'dark-blue'}>Join a new session and leave the current session automatically</Heading>
						<button className={`${classes['input-button']} ${classes['lozenge']}`} onClick={handleTransferSession} title='Transfer'>
							Transfer
						</button>
					</div>

					<button className={`${classes['input-button-transparent']} ${classes.lozenge} ${classes.row}`}
						onClick={handleCancelTransfer} title='Cancel'> Cancel </button>
				</div>
			</div>
		</div>
	)
}

export default TransferSession;

import moment from 'moment';

export const browser_cookies = {
    get: (key: string): string => {
        const matched = `; ${document.cookie}`.match(`;\\s*${key}=([^;]+)`);
        
        return matched ? matched[1] : '';
    },
    set: (key: string, value: string, expires: Date | null = null): void => {
        if (!expires) {
            expires = new Date(moment().add(1, 'day').toString());
        }

        document.cookie = `${key}=${value};${expires}=${expires.toUTCString()};path=/`;
    },
    delete: (key:string):void => {
        document.cookie = `${key}=;Expires=${new Date(1970, 1, 1).toUTCString()};path=/`; 
    }
};

export const browser_session = {
    get: (key: string): string => {
        return sessionStorage.getItem(key) || '';
    },
    set: (key: string, value: string): void => {
        sessionStorage.setItem(key, value);
    },
    remove: (key: string): void => {
        sessionStorage.removeItem(key);
    },
    clear: (): void => {
        sessionStorage.clear();
    }
};

export const browser_local = {
    get: (key: string): string | null => {
        return localStorage.getItem(key);
    },
    set: (key: string, value: string): void => {
        localStorage.setItem(key, value);
    },
    remove: (key: string): void => {
        localStorage.removeItem(key);
    },
    clear: (): void => {
        localStorage.clear();
    }
};

import CloseButton from 'Features/MainSale/Components/CloseButton/CloseButton';
import React, { FC, MouseEvent, useState } from 'react';

import classes from './status-badge.module.scss';

interface IStatusHelpBadgeProps {
    children?: JSX.Element | JSX.Element[];
    classNames: Array<string>;
    gtmTitle: string;
    title: JSX.Element;
    styles?: Record<string, string>;
}

const StatusBadge: FC<IStatusHelpBadgeProps> = ({ title, children, classNames = [], styles = {}, gtmTitle = '' }) => {

    const badgeClasses = classNames.filter((image) => image !== '').map((image) => classes[image]).join(' ');

    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    const handleOpenOverlay = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsOverlayOpen(true);
    };

    const handleCloseOverlay = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsOverlayOpen(false);
    };

    return (
        <>
            {children ? (
                <div className={classes['status-help']}>
                    <a href="#" title={gtmTitle} onClick={handleOpenOverlay} className={`${classes['status-badge']} ${badgeClasses} gtm-status-badge`} style={styles}>
                        {title}
                    </a>
                </div>
            ) : (
                <span title={gtmTitle} className={`${classes['status-badge']} ${badgeClasses} gtm-status-badge`} style={styles}>
                    {title}
                </span>
            )}
            {children && isOverlayOpen ? (
                <div className={classes['help-overlay']}>
                    <div className={classes['closer']}>
                        <CloseButton onCloseMainAuction={handleCloseOverlay} title="Close" />
                    </div>
                    {children}
                </div>
            ) : null}
        </>
    );
};

export default StatusBadge;

import React, { FC, ReactElement } from 'react';

import classes from './announcement-remark.module.scss';

interface IAnnouncementRemark {
    announcement: string;
    remark: string;
}

const AnnouncementRemark: FC<IAnnouncementRemark> = ({ announcement, remark }) => {
    if (!announcement && !remark) return null;
    
    return (
        <div className={classes['messages']}>
            {
                announcement.length > 0 && <p className={`${classes['announce']} ${classes['message']}`}>
                                                <span className={classes['label']}>Announcements:</span> {announcement}
                                            </p>
            }
            {
                remark.length > 0 && <p className={`${classes['remark']} ${classes['message']}`}>
                                        <span className={classes['label']}>Remarks:</span> {remark}
                                     </p>
            }
        </div>
    );
};

export default AnnouncementRemark;
import checkpointNegative from 'img/help/checkpoint-negative.png';
import checkpointPositive from 'img/help/checkpoint-positive.png';
import checkpoint from 'img/help/checkpoint.png';
import React, { FC } from 'react';

import classes from "../status-badge.module.scss";

const CheckpointIcon = () => {
    return (
        <div className="content">
            <h3 className={classes['title']}>Manheim CheckPoint</h3>
            <p>
                CheckPoint is our automatic pre-sale check that provides more information about a vehicle’s history and usage. We check the MOT history for
                mileage discrepancies, and refer to DVLA, TfL and local authority databases for previous private hire use.{' '}
            </p>
            <p className={classes['images']}>
                <img src={checkpoint} title="CheckPoint" />
            </p>
            <p>
                A green CheckPoint symbol indicates that no issues have been flagged. An amber CheckPoint symbol indicates that something has been flagged by
                our checks. Full details are available in the vehicle’s inspection report.
            </p>
            <p className={classes['images']}>
                <img src={checkpointPositive} title="CheckPoint" />
                <img src={checkpointNegative} title="CheckPoint" />
            </p>
            <p className={classes['note']}>
                Note: CheckPoint is automatically applied to all applicable cars registered after 2012 and a nominal £2 fee is added to the invoice after
                purchase. If CheckPoint has not been applied, then this is clearly indicated by a grey CheckPoint symbol.
            </p>
        </div>
    );
};

export default CheckpointIcon;

import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import { BlockedSale, blockedSalesSelectors } from 'Infrastructure/UserAccount/user-account.slice';
import React, { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from './auction-control.module.scss';
import { useGetUserLookerSuperUser, useIsBuyer, useIsVendor } from './auction-controls.hooks';
import ActiveAndOnlineBidders from './Components/ActiveAndOnlineBidders';
import BidIntentButton from './Components/BidIntentButton';
import CurrentBidStatus from './Components/CurrentBidStatus';
import OnSaleStatus from './Components/OnSaleStatus';
import ReserveStatus from './Components/ReserveStatus';
import { useAuctionControlButtonState } from './Hooks/useAuctionControlButtonState.hook';
import { useBiddingIntentProps } from './Hooks/useBiddingIntentProps.hook';
import { useBidIntentImOut } from './Hooks/useBidIntentImOut.hook';
import { useBidIntentMayBid } from './Hooks/useBidIntentMayBid.hook';
import { useClickToBid } from './Hooks/useClickToBid.hook';
import { useClickToSell } from './Hooks/useClickToSell.hook';
import { useGetBiddingState } from './Hooks/useGetBiddingState.hook';
import { useIsButtonDisabled } from './Hooks/useIsButtonDisabled.hook';
import { useIsVendorOnSale } from './Hooks/useIsVendorOnSale.hook';
import { useIsVendorsLot } from './Hooks/useIsVendorsLot.hook';
import AuctionStats from './Views/AuctionStats.view';
import UserAuctionControls from './Views/UserAuctionControls.view';

export interface IAuctionControl {
    refNum: string;
    siteId: string;
    buyerNumber: string;
    lotDetails: IApiLotViewModel;
    type?: 'preview' | 'mobile';
}

const AuctionControls: FC<IAuctionControl> = ({ refNum, siteId, buyerNumber, lotDetails, type = '' }) => {
    const { lot = '', reserve = '', workOrder = '' } = lotDetails;
    const isMyLot = useIsVendorsLot(refNum, lot);

    const { isLooker = false, isSuperUser = false } = useGetUserLookerSuperUser();
    const { currentBid, askingPrice, currentLot, haveBid, highestBid, isOnSale, isPaused, isUserIn } = useGetBiddingState(refNum);
    const { isBlocked = false } = useSelector((state: AppState) => blockedSalesSelectors.selectById(state, refNum) || ({} as BlockedSale));

    const isBuyer = useIsBuyer();
    const isVendor = useIsVendor();
    const handleClickToSell = useClickToSell();
    const handleClickToBid = useClickToBid();
    const handleBidIntentImOut = useBidIntentImOut();
    const handleBidIntentMayBid = useBidIntentMayBid();
    const isVendorOnSale = useIsVendorOnSale(isVendor, isOnSale);

    // prettier-ignore
    const { title, colour, trigger } =
        useAuctionControlButtonState(currentLot, currentBid, askingPrice, haveBid, highestBid, isMyLot, isOnSale, isLooker, isBlocked);
    const { intentTitle, intentColour, intentTrigger } = useBiddingIntentProps(isLooker, isUserIn);
    const isDisabled = useIsButtonDisabled(isLooker, isPaused, isBlocked, currentLot, askingPrice, currentBid);

    const handleMainActionOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        if (isDisabled || isVendorOnSale) return;

        if (isVendor && isMyLot) {
            handleClickToSell(currentBid, lot, siteId, refNum, buyerNumber);
        } else if (isBuyer && !haveBid) {
            handleClickToBid(askingPrice, lot, siteId, refNum, buyerNumber, workOrder);
        }
    };

    const handleBidIntnentOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (isDisabled) return;

        if (isBuyer && isUserIn) {
            handleBidIntentImOut(refNum, siteId, lot, buyerNumber, currentBid);
        } else if (isBuyer && !isUserIn) {
            handleBidIntentMayBid(refNum, siteId, lot, buyerNumber, currentBid);
        }
    };

    {
        /*   GTM: trigger contains sell-trigger class required for GA. 
          GTM: intentTrigger contains im-out-trigger and  may-bid-trigger class required for GA.*/
    }

    return (
        <div className={`${classes['bid-controls']} ${type ? classes[type] : ''}`}>
            <div className="inner">
                <ActiveAndOnlineBidders refNum={refNum} />
                <AuctionStats>
                    <CurrentBidStatus currentBid={currentBid} />
                    <ReserveStatus showReserve={isSuperUser || (isVendor && isMyLot)} reserve={reserve} />
                    <OnSaleStatus show={askingPrice > 0 && isOnSale} />
                </AuctionStats>
                <UserAuctionControls btnTitle={title} btnColour={colour} btnTrigger={trigger} btnIsDisabled={isDisabled} btnOnClick={handleMainActionOnClick}>
                    <div className={`${classes['intent-btn-wrapper']}`}>{isBuyer && (
                        <BidIntentButton
                            title={intentTitle}
                            colour={intentColour}
                            trigger={intentTrigger}
                            isDisabled={isDisabled}
                            onClick={handleBidIntnentOnClick}
                        />
                    )}
                        {isBuyer && highestBid > 0 && <span className={classes['user-bid']}>Your highest bid: {highestBid.toCurreny()}</span>}
                    </div>
                </UserAuctionControls>
            </div>
        </div>
    );
};

export default AuctionControls;

import React, { FC } from 'react';

import classes from './notify-badge.module.css';
import NotifyBadgeIcon from './NotifyBadgeIcon';

interface INotifyBadgesList {
    announcement: string;
    isMobile?: boolean;
    remarks: string;
    watching: boolean;
    isSecondarySale?: boolean;
}

const NotifyBadgeList: FC<INotifyBadgesList> = ({ announcement, remarks, watching, isMobile = false, isSecondarySale = false }) => {

    const isAnnouncementsNullOrWhitespace = announcement?.isEmptyOrWhitespace() ?? true;
    const isRemarksNullOrWhitespace = remarks?.isEmptyOrWhitespace() ?? true;
    const isWatching = watching ?? false;

    return <div className={classes['notify-badges']}>
        {(!isAnnouncementsNullOrWhitespace && <NotifyBadgeIcon
            key="announcements"
            title="Announcements"
            label={isMobile ? '' : "Announcement on this lot"}
            classes={[classes['announcements'], isMobile ? classes['badge'] : '']}
            styles={{ visibility: isAnnouncementsNullOrWhitespace ? 'hidden' : 'visible', marginTop: isMobile ? '5px' : '' }}
            isSecondarySale={isSecondarySale}
        />)}
        {(!isRemarksNullOrWhitespace && <NotifyBadgeIcon
            key="remarks"
            title="Remarks"
            label={isMobile ? '' : "Remarks on this lot"}
            classes={[classes['remarks'], isMobile ? classes['badge'] : '']}
            styles={{ visibility: isRemarksNullOrWhitespace ? 'hidden' : 'visible', marginTop: isMobile ? '5px' : '' }}
            isSecondarySale={isSecondarySale}
        />)}
        {(isWatching && <NotifyBadgeIcon
            key="watchlist"
            title="Watch list"
            label="On your watch list"
            classes={[classes['watch'], isMobile ? classes['badge'] : '']}
            styles={{ visibility: isWatching ? 'visible' : 'hidden', marginTop: isMobile ? '5px' : '' }}
            isSecondarySale={isSecondarySale}
        />)}
    </div>;
};

export default NotifyBadgeList;

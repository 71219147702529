import vendorOfflineIcon from 'img/vendor-offline.svg';
import vendorOnlineIcon from 'img/vendor-online.svg';
import React, { FC } from 'react';

import classes from './sale-preview.module.scss';

interface ISalePreviewVendorLogo {
    vendorOnline: boolean;
}

const SalePreviewVendorLogo: FC<ISalePreviewVendorLogo> = ({ vendorOnline }) => {
    return <div className={classes['secondary-vendor-logo']}
                style={{ backgroundImage: `url( ${vendorOnline ? vendorOnlineIcon : vendorOfflineIcon} )` }} />
};

export default SalePreviewVendorLogo;
import isLandscape from 'Common/Helpers/OrientationCheck';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import React, { ChangeEvent, FC, FormEvent, MouseEvent, RefObject, useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from '../chat-messaging.module.css';
import { setInputBoxValue } from '../chat-messaging.slice';
import ChatMessageButton from '../Components/ChatMessageButton';

interface IChatMessageSendForm {
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    inputRef: RefObject<HTMLInputElement>;
}

const ChatMessagesSendForm: FC<IChatMessageSendForm> = ({ handleSubmit, inputRef }) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const { prevInputValue } = useSelector((state: AppState) => state.chat);
    const { sale } = useSelector((state: AppState) => state.mainSale);
    const [messageText, setMessageText] = useState('');

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        await handleSubmit(e);
        emptyTextBox();
    };

    const handleMessageOnChange = (e: ChangeEvent<HTMLInputElement>) => setMessageText(e.target.value);

    const handleClearMessageOnClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        emptyTextBox();
    };

    const emptyTextBox = () => {
        setMessageText('');
        isMobile && dispatch(setInputBoxValue(''));
    }

    const storeTextBoxValueOnMobile = () => {
        if (isMobile && sale)
            dispatch(setInputBoxValue(inputRef.current?.value || ''));

        if (isIOS && window.visualViewport!.pageTop > 0)
            window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
        if (isMobile && prevInputValue)
            setMessageText(prevInputValue);
    }, [isMobile]);


    return (
        <>
            <form className={classes['chat-ui']} onSubmit={onSubmit} autoComplete="off">
                <div className={classes.chatBoxControls}>
                    <input
                        ref={inputRef}
                        type="text"
                        name="message"
                        placeholder="Type your message here"
                        className={classes['chat-input']}
                        required
                        minLength={1}
                        maxLength={100}
                        pattern="[\w\d\s\W]{1,100}"
                        value={messageText}
                        onChange={handleMessageOnChange}
                        onBlur={storeTextBoxValueOnMobile}
                    />
                    <ChatMessageButton
                        title="Cancel"
                        text="&#xd7;"
                        type="button"
                        onClick={handleClearMessageOnClick}
                        isDisabled={messageText.isEmptyOrWhitespace()}
                    />
                    <ChatMessageButton
                        title="Send"
                        text="&raquo;"
                        type="submit"
                        isDisabled={messageText.isEmptyOrWhitespace() || (isMobile && !sale)}
                    />
                </div>
            </form>
        </>
    );
};

export default ChatMessagesSendForm;

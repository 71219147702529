import { createAction, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import moment from 'moment';
import { JoinedSale } from 'services/auctions.slice';

import { BidHistoryMessageTypes } from './bidding-messages.types';

export interface BiddingMessage {
    currentBidUser: string;
    cAC: string;
    lot: string;
    message: string;
    refNum: string;
    type: BidHistoryMessageTypes;
    recievedDate: string;
}

export type LotFieldUpdate = {
    refNum: string;
    fieldName: string;
    value: string;
};

export type BiddingHistory = {
    refNum: string;
    messages: Array<BiddingMessage>;
    currentLot: string;
    isClosed: boolean;
};

const biddingHistoryAdapter = createEntityAdapter<BiddingHistory>({
    selectId: (message) => message.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

const joinedSaleAction = createAction<JoinedSale>('auctions/addJoinedSale'); //From Auctions slice
const leaveSaleAction = createAction<JoinedSale>('auctions/leaveJoinedSale'); //From Auctions Slice

const biddingHistorySlice = createSlice({
    name: 'bidding',
    initialState: biddingHistoryAdapter.getInitialState(),
    reducers: {
        messageRecieved: {
            reducer: (state, { payload }: { payload: BiddingMessage }) => {
                const { refNum, type } = payload;
                const entity = state.entities[refNum];
                let isDuplicateLot = false;

                if (payload.type === BidHistoryMessageTypes.LOT_CHANGED)
                {
                    isDuplicateLot = (entity?.messages.filter(msg => msg.type == BidHistoryMessageTypes.LOT_CHANGED
                                     && msg.lot == payload.lot && msg.message === payload.message).length) != 
                                     (entity?.messages.filter(msg => msg.type == BidHistoryMessageTypes.LOT_RETRACTED 
                                     && msg.lot == payload.lot).length);

                }

                if (entity && !isDuplicateLot) {
                    let messages = [payload, ...entity.messages];

                    messages.reduce((acc: string[], current: BiddingMessage, i, arr) => {
                        if (!acc.includes(current.lot)) {
                            acc.push(current.lot);
                        }

                        if (acc.length > 3) {
                            messages = arr.filter((x) => x.lot !== current.lot);
                        }

                        return acc.slice(0, 3);
                    }, []);

                    const changes = {
                        messages,
                        isClosed: type === BidHistoryMessageTypes.BIDDING_CLOSED
                    };

                    biddingHistoryAdapter.updateOne(state, { id: refNum, changes });
                }

                return state;
            },
            prepare: (message: BiddingMessage) => {
                const payload = Object.assign(message, { recievedDate: Date.now.toString() });
                return { payload };
            }
        },
        setSaleHistoryCurrentLot: (state, { payload }) => {
            const { refNum, lot: currentLot } = payload;

            const entity = state.entities[refNum];

            if (entity) {
                const changes = {
                    currentLot
                };

                biddingHistoryAdapter.updateOne(state, { id: refNum, changes });
            }

            return state;
        }
    },
    //Side effects when joining/leaving sale create/delete bidding history object
    extraReducers: (builder) => {
        builder.addCase(joinedSaleAction, (state, { payload }) => {
            const { refNum } = payload;

            if (!state.ids.includes(refNum)) {
                const messages = new Array<BiddingMessage>();

                messages.push({
                    refNum,
                    type: 0,
                    lot: '',
                    message: 'Waiting for the sale to start',
                    currentBidUser: '',
                    cAC: '',
                    recievedDate: moment().utc().toISOString()
                });

                biddingHistoryAdapter.addOne(state, { refNum, currentLot: '', messages, isClosed: false });
            }
        }),
            builder.addCase(leaveSaleAction, (state, { payload }) => {
                const { refNum } = payload;

                if (state.ids.includes(refNum)) {
                    biddingHistoryAdapter.removeOne(state, refNum);
                }
            });
    }
});

export const { messageRecieved, setSaleHistoryCurrentLot } = biddingHistorySlice.actions;

export const biddingHistorySelectors = biddingHistoryAdapter.getSelectors();

export default biddingHistorySlice.reducer;

import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider'; 
  
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useBidIntentMayBid = (): ((refNum: string, siteId: string, lot: string, buyerNumber: string, bid: number, partner?: string) => void) => {
    const { sendSocketMessage } = useWebSocketConnection(); 

    const handleBidIntentMayBid = (refNum: string, siteId: string, lot: string, buyerNumber: string, bid: number, partner = '') => {
        const start = performance.now();

        sendSocketMessage('MayBid', [bid, lot, siteId, refNum, buyerNumber, partner])
            .then(() => {
                const duration = performance.now() - start;

                newrelic.addPageAction('may_bid_button', {
                    status: 'updated',
                    duration
                }); 
            })
            .catch((error) => {
                window.logger.error(error);
            });
    };

    return handleBidIntentMayBid;
};

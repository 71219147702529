import { HubConnection, HubConnectionState, LogLevel } from '@microsoft/signalr';
import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import { useConfigureSignalRBidMethods } from './SignalR/useConfigureSignalRBidMethods.hook';
import { useConfigureSignalRLotMethods } from './SignalR/useConfigureSignalRLotMethods.hook';
import { useConfigureSignalRSaleMethods } from './SignalR/useConfigureSignalRSaleMethods.hook';
import { useConfigureSignalRUserMethods } from './SignalR/useConfigureSignalRUserMethods.hook';
import { useConfigureSignalRUtilMethods } from './SignalR/useConfigureSignalRUtilMethods.hook';
import { useCreateSignalRConnection } from './SignalR/useCreateSignalRConnection.hook';
import { useStartSignalRConnection } from './SignalR/useStartSignalRConnection.hook';
import { useWatchSignalRConnectionState } from './SignalR/useWatchSignalRConnectionState.hook';

interface IWebSocketProvider {
    children: ReactNode;
}

const WebSocketProvider: FC<IWebSocketProvider> = ({ children }) => {
    const { bearerToken = '' } = useSelector((state: AppState) => state.account);
    const { connection } = useCreateSignalRConnection(bearerToken);
    const { connectionStatus, setConnectionStatus } = useWatchSignalRConnectionState(connection);
    const startSignalR = useStartSignalRConnection(connection, setConnectionStatus);

    useConfigureSignalRUserMethods(connection);
    useConfigureSignalRSaleMethods(connection);
    useConfigureSignalRLotMethods(connection);
    useConfigureSignalRBidMethods(connection);
    useConfigureSignalRUtilMethods(connection);

    const sendMessage = (method: string, params: unknown[] = []): Promise<unknown> => {
        if (connection?.state === HubConnectionState.Connected) {
            return connection.invoke(method, ...params);
        }

        return Promise.reject(`Connection not in Connected state ${connection?.state}`);
    };

    return (
        <WebSocketContext.Provider value={{ connection, connectionStatus, startConnection: startSignalR, sendSocketMessage: sendMessage }}>
            {children}
        </WebSocketContext.Provider>
    );
};

interface IWebSocketContext {
    connection?: HubConnection;
    connectionStatus: HubConnectionState;
    startConnection: () => Promise<void>;
    sendSocketMessage: (method: string, params?: unknown[]) => Promise<unknown>;
}

export default WebSocketProvider;

export const WebSocketContext = createContext<IWebSocketContext>({
    connectionStatus: HubConnectionState.Disconnected,
    startConnection: () => Promise.reject(),
    sendSocketMessage: async () => Promise.reject()
});

export const useWebSocketConnection = (): IWebSocketContext => useContext(WebSocketContext);

import React, { FC, ReactElement } from 'react';

import classes from './data-table.module.scss';

interface IDataTableInner {
    className?: 'vehicle-details-inner';
    children: ReactElement[] | ReactElement;
}

const DataTableInner: FC<IDataTableInner> = ({ className,children }) => {
    return (
        <div className={`${classes['inner']}  ${className ? classes[className] : ''}`}>
                {children}
            </div>
      
    );
};

export default DataTableInner;

import ListTable from 'Common/ListTable/ListTable';
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import ScrollbarTab from 'Common/ScrollbarTab/ScrollbarTab';
import React, { FC } from 'react';

import ClientsOnlineHeader from './ClientsOnlineHeader';

export interface ILiveClientsOnline {
    accountNo: string;
    accType: string;
    bidderNo: string;
    company: string;
    name: string;
    proxyBidder: boolean;
}

interface IClientsOnlineProps {
    clientsOnline: ILiveClientsOnline[];
    proxyClientsOnline: ILiveClientsOnline[];
}

const ClientsOnline: FC<IClientsOnlineProps> = ({ clientsOnline = [], proxyClientsOnline = [] }) => {
    const clientsOnlineDetails = [...clientsOnline, ...proxyClientsOnline].map((item, index) => {
        return (
            <ListTableRow key={index}>
                <ListTableCell value1={item.company ?? ''} />
                <ListTableCell value1={item.name ?? ''} />
                <ListTableCell value1={item.accountNo ?? ''} />
                <ListTableCell value1={item.bidderNo ?? ''} />
                <ListTableCell value1={item.proxyBidder ? 'YES' : 'NO'} />
            </ListTableRow>
        );
    });

    return (
        <ScrollbarTab>
            <ListTable>
                <>
                    <ClientsOnlineHeader />
                    {clientsOnlineDetails}
                </>
            </ListTable>
        </ScrollbarTab>
    );
};

export default ClientsOnline;

import React, { FC } from 'react';

import classes from '../auction-control.module.scss';

export interface ICurrentBidStatus {
    currentBid: number | undefined;
}

const CurrentBidStatus: FC<ICurrentBidStatus> = ({ currentBid = 0 }) => {
    return <p className={classes.current}>Current Bid: {currentBid ? currentBid.toCurreny() : ''}</p>;
};

export default CurrentBidStatus;

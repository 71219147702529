import Accordion from 'Common/Accordion/Accordion';
import AccordionContent from 'Common/Accordion/AccordionContent';
import AccordionTitle from 'Common/Accordion/AccordionTitle';
import ExpandIcon from 'Common/Accordion/ExpandIcon';
import DataTable from 'Common/DataTable/DataTable';
import DataTableCell from 'Common/DataTable/DataTableCell';
import DataTableColumn from 'Common/DataTable/DataTableColumn';
import DataTableColumns from 'Common/DataTable/DataTableColumns';
import DataTableHeader from 'Common/DataTable/DataTableHeader';
import DataTableInner from 'Common/DataTable/DataTableInner';
import DataTableRow from 'Common/DataTable/DataTableRow';
import { DateHelper } from 'Common/Helpers/DateHelper';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import moment from 'moment';
import React, { FC } from 'react';

import { ISaleHistory } from '../../../../Domain/Entities/ISalesHistory';
import { LiveStockExtraDetails } from '../../../../Domain/Entities/live-stock-extra-details.entity';
import FieldDisplay from '../FieldDisplay/FieldDisplay';
import SaleHistoryAccordion from './SaleHistoryAccordion/SaleHistoryAccordion';

interface ICurrentLotDetailsAccordion {
    lotDetails: IApiLotViewModel;
    vehicleServiceHistory: any[];
    saleHistory: ISaleHistory | undefined;
    onRefreshSaleHistory: () => void;
    showSalesHistory: boolean;
    extraLotDetails: LiveStockExtraDetails;
}

const CurrentLotDetailsAccordion: FC<ICurrentLotDetailsAccordion> = ({ onRefreshSaleHistory, saleHistory, lotDetails, vehicleServiceHistory, showSalesHistory, extraLotDetails }) => {
    const mileageDisplay = (vehicleDetails: IApiLotViewModel): string => {
        return `${vehicleDetails?.mileage ?? ''} ${vehicleDetails?.warranted === undefined ? '' : vehicleDetails?.warranted ? '(Warranted)' : '(Unwarranted)'}`;
    };
    const vehicleDetailsTitle = `Vehicle Details ${lotDetails?.mileage ? '-' : ''} ${mileageDisplay(lotDetails)}`;
    const specificationTitle = 'Specification';
    const serviceHistoryTitle = `Service History - Number of Services - ${vehicleServiceHistory.length}`;
    const privateHire = Object.keys(extraLotDetails).length === 0 ? '' : extraLotDetails?.privateHire ? 'Yes' : 'No';
    const policeUnit = Object.keys(extraLotDetails).length === 0 ? '' : extraLotDetails?.policeUnit ? 'Yes' : 'No';

    //TODO: Move IApiLotViewModel to domain and add method to get specifications split to array.
    const specs = lotDetails?.specification?.split('|').reduce(
        (accumulator, item, index) => {
            if (index % 2 === 0) {
                accumulator.column1.push(item);
            } else {
                accumulator.column2.push(item);
            }

            return accumulator;
        },
        { column1: [], column2: [] } as Record<string, string[]>
    ) || { column1: [], column2: [] };

    const currentDate = moment(new Date()).toDate();

    currentDate.setHours(0, 0, 0, 0);

    return (
        <div>

            { showSalesHistory &&
                (<SaleHistoryAccordion saleHistory={saleHistory} onRefreshSaleHistory={onRefreshSaleHistory} />)
            }
            <Accordion>
                <AccordionTitle title={vehicleDetailsTitle}>
                    <p>
                        {vehicleDetailsTitle}
                    </p>
                    <ExpandIcon />
                </AccordionTitle>
                <AccordionContent>
                    <DataTable className='expander-vehicle-details'>
                        <DataTableColumns>
                            <DataTableColumn>
                                <DataTableInner className='vehicle-details-inner'>
                                    <FieldDisplay displayName="REG NO" value={lotDetails?.regNo} />
                                    <FieldDisplay displayName="REG DATE" value={DateHelper.ToDateString(lotDetails?.regDate ?? '')} />
                                    <FieldDisplay displayName="ODOMETER" value={mileageDisplay(lotDetails)} isWarning={!lotDetails?.warranted} />
                                    <FieldDisplay displayName="VIN" value={lotDetails?.vin} />
                                    <FieldDisplay displayName="COLOUR" value={lotDetails?.exteriorColour} />
                                    <FieldDisplay displayName="INTERIOR COLOUR" value={lotDetails?.interiorColour} />
                                    <FieldDisplay displayName="OWNERSHIP" value={lotDetails?.v5Location === 'Y' ? 'With Manheim' : lotDetails?.v5Location} />
                                    {/*<FieldDisplay displayName="PRIVATE HIRE" value={privateHireText} isWarning={Boolean(isPrivateHire)} />*/}
                                    <FieldDisplay displayName="TOTAL LOSS" value={lotDetails?.vCar} isWarning={lotDetails?.vCar === 'Yes' ? true : false} />
                                    <FieldDisplay displayName="RUNNER" value={lotDetails?.runner} isWarning={lotDetails?.runner === 'No' ? true : false} />
                                    <FieldDisplay displayName="IMPORTED" value={lotDetails?.import} isWarning={lotDetails?.import === 'Yes' ? true : false} />
                                    <FieldDisplay displayName="TAXI" value={privateHire} isWarning={privateHire === 'Yes' ? true : false} />
                                    <FieldDisplay displayName="POLICE CAR" value={policeUnit} isWarning={policeUnit === 'Yes' ? true : false} />
                                    <FieldDisplay displayName="VAT" value={lotDetails?.vatStatus} />
                                    <FieldDisplay displayName="NO OF REG KEEPERS" value={lotDetails?.formerKeepers} />
                                    <FieldDisplay displayName="LOCATION" value={lotDetails?.location} />
                                    <FieldDisplay displayName="VENDOR" value={lotDetails?.vendor} />
                                </DataTableInner>
                            </DataTableColumn>
                        </DataTableColumns>
                    </DataTable>

                </AccordionContent>
            </Accordion>
            <Accordion>
                <AccordionTitle title={specificationTitle}>
                    <p>{specificationTitle}</p>
                    <ExpandIcon />
                </AccordionTitle>
                <AccordionContent>
                    <DataTable className='value-only'>
                        <DataTableColumns>
                            <DataTableColumn>
                                <DataTableInner>
                                    {specs.column1.map((spec, index) => (
                                        <DataTableRow key={`column1_${index}`}>
                                            <DataTableCell item={spec} />
                                        </DataTableRow>
                                    ))}

                                </DataTableInner>
                            </DataTableColumn>
                            <DataTableColumn>
                                <DataTableInner>
                                    {specs.column2.map((spec, index) => (
                                        <DataTableRow key={`column2_${index}`}>
                                            <DataTableCell item={spec} />
                                        </DataTableRow>

                                    ))}
                                </DataTableInner>
                            </DataTableColumn>
                        </DataTableColumns>
                    </DataTable>
                </AccordionContent>
            </Accordion>
            <Accordion>
                <AccordionTitle title={'Service History'}>
                    <p>{serviceHistoryTitle}</p>
                    <ExpandIcon />
                </AccordionTitle>
                <AccordionContent>
                    <DataTable className='multi-column'>
                        <>
                            <DataTableRow>
                                <DataTableHeader item={'Service Date'} />
                                <DataTableHeader item={'Odometer'} />
                                <DataTableHeader item={'Serviced By'} />
                                <DataTableHeader item={'Service Record Type'} />
                            </DataTableRow>
                            {vehicleServiceHistory?.map((item, index: number) => {
                                return (
                                    <DataTableRow key={index}>
                                        <DataTableCell item={DateHelper.ToDateDotString(item.serviceDate ?? '')} />
                                        <DataTableCell item={item?.serviceOdometer.toLocaleString()} />
                                        <DataTableCell item={item?.serviceStation} />
                                        <DataTableCell item={item?.serviceType} />
                                    </DataTableRow>
                                );
                            })}
                        </>
                    </DataTable>
                </AccordionContent>
            </Accordion>
        </div >
    );
};

export default CurrentLotDetailsAccordion;

import { express, net } from 'phenix-web-sdk';
import { useEffect, useState } from 'react';

export type ChannelExpress = typeof express.ChannelExpress;
export type AdminClient = typeof net.AdminApiProxyClient;

export const useCreatePhenixChannel = (
    adminProxyClientBackendUri: string
): { channel: ChannelExpress; adminClient: AdminClient; createChannel: () => ChannelExpress } => {
    const [channel, setChannel] = useState<ChannelExpress | null>(null);
    const [adminClient, setAdminClient] = useState<AdminClient | null>(null);

    useEffect(() => {
        const channelExpressOptions = {
            features: ['real-time', 'dash', 'hls']
        };

        const adminApiProxyClient = new net.AdminApiProxyClient();

        adminApiProxyClient.setBackendUri(adminProxyClientBackendUri);

        setAdminClient(adminApiProxyClient);

        Object.assign(channelExpressOptions, { adminApiProxyClient });

        setChannel(new express.ChannelExpress(channelExpressOptions));

        return () => {
            channel?.dispose();
            setChannel(null);
            setAdminClient(null);
        };
    }, []);

    const createChannel = () => {
        return new express.ChannelExpress({
            features: ['hls', 'dash', 'real-time'],
            pcastExpress: channel.getPCastExpress(),
            onError: (error: any) => window.logger.error(error),
            treatBackgroundAsOffline: true
        });
    };

    return { channel, adminClient, createChannel };
};

import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { BiddingState, biddingStateSelectors } from 'Features/AuctionControls/bidding-state.slice';
import { ISaleListItem } from 'Features/AuctionsList/Interfaces/sale-list-item.interface';
import { handleLeavePrimarySale } from 'Features/MainSale/main-sale.service';
import React, { FC, MouseEvent, RefObject, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaleLots, saleLotsSelectors } from 'services/auctions.slice';
import { browser_cookies } from 'services/browser-storage';
import { AppDispatch, AppState } from 'services/redux-store';

import SalePreview from '../SalePreview/SalePreview';
import SaleSummary from '../SaleSummary';
import SaleToggleControl from '../SaleToggleControl';
import classes from './auction-sale.module.css';


interface IAuctionSummaryProps {
    handleJoinSale: (siteId: string, refNum: string, suid: string, createBidderId: boolean, useGlobalBidderId: boolean) => Promise<void>;
    handleLeaveSale: (siteId: string, refNum: string, workOrder: string) => void;
    handleSetMainSale: (refNum: string) => void;
    handleToggleBidHistory: (refNum: string, saleListViewRef: RefObject<HTMLDivElement>) => void;
    openBidHistoryRefNum: string;
    sale: ISaleListItem;
    isFlyoutOpen: boolean;
    isTabletMode?: boolean;
}

const AuctionSaleView: FC<IAuctionSummaryProps> = ({
    handleJoinSale,
    handleLeaveSale,
    handleSetMainSale,
    handleToggleBidHistory,
    openBidHistoryRefNum,
    sale,
    isFlyoutOpen,
    isTabletMode = false
}) => {
    const dispatch: AppDispatch = useDispatch();
    const { sendSocketMessage } = useWebSocketConnection();
    const suid = browser_cookies.get('__suid');
    const saleListViewRef = useRef<HTMLDivElement>(null);
    const isBidHistoryOpen = openBidHistoryRefNum === sale.refNum;
    const { isJoined } = sale;

    const { saleLots } = useSelector((state: AppState) => state.auctions);

    const { currentLot = {} } = (saleLotsSelectors.selectById(saleLots, sale.refNum) as SaleLots) || {};

    const { askingPrice = '' } = useSelector((state: AppState) => biddingStateSelectors.selectById(state, sale.refNum) || ({} as BiddingState));

    const vehicleName = currentLot?.lotDetails?.make || '';

    const regNo = currentLot?.lotDetails?.regNo || '';

    const auctionName = sale?.branch || '';

    const { bearerToken } = useSelector((state: AppState) => state.account);
    const primarySale = useSelector((state: AppState) => state.mainSale);

    const [isMinimised, setIsMinimised] = useState(false);

    const handleMinimiseSecondarysale = () => {
        setIsMinimised(!isMinimised);
    };

    const toggleShowPreview = (e?: MouseEvent<HTMLDivElement>) => {
        if (e) e.preventDefault();

        isJoined ? handleLeaveSale(sale.siteId, sale.refNum, currentLot?.workOrder ?? '')
            : handleJoinSale(sale.siteId, sale.refNum, suid, true, false);
    };

    const onJoinSale = (e?: any) => {
        if (e) e.preventDefault();

        if (!isJoined) {
            handleJoinSale(sale.siteId, sale.refNum, suid, true, false);
        }
    };

    const leavePrimarySale = async () => {
        if (primarySale.sale) {
            const { refNum, siteId } = primarySale.sale;

            window.logger.log(`LEAVING: ${refNum}`);
            const response = await sendSocketMessage('LeaveSale', [siteId, refNum, bearerToken, currentLot?.workOrder ?? '']);

            if (response) {
                dispatch(handleLeavePrimarySale(refNum));
            }
        }
    };

    return (
        <>
            {/*   GTM: gtm-secondary-sale, gtm-auction class and gtm attributes required for GA. */}
            <div
                ref={saleListViewRef}
                className={`gtm-secondary-sale gtm-auction ${isMinimised ? 'gtm-preview-minimised' : ''} ${classes['auction-sale-container']} ${isJoined ? classes.active : ''}`}
                onDoubleClick={onJoinSale}
                data-gtm-auction-name={auctionName}
                data-gtm-vehicle-name={vehicleName}
                data-gtm-vehicle-reg={regNo}
                data-gtm-next-bid-value={askingPrice}>
                {(!isFlyoutOpen || !isJoined) && (
                    <SaleSummary
                        displayName={sale.displayName}
                        laneNumber={sale.lane}
                        saleDate={sale.saleDate}
                        saleDescription={sale.saleDescription}
                        isTabletMode={isTabletMode}
                        onJoinSale={onJoinSale}
                        toggleShowPreview={toggleShowPreview}
                        leavePrimarySale={leavePrimarySale}
                        isFlyoutOpen={isFlyoutOpen}
                    />
                )}
                {(!isTabletMode || (isTabletMode && !primarySale.sale)) && <SaleToggleControl isActive={isJoined} toggleShowPreview={toggleShowPreview} />}
                {isFlyoutOpen && isJoined && (
                    <SalePreview
                        sale={sale}
                        currentLot={currentLot}
                        handleSetMainSale={handleSetMainSale}
                        handleToggleBidHistory={handleToggleBidHistory}
                        saleListViewRef={saleListViewRef}
                        handleMinimiseSecondarysale={handleMinimiseSecondarysale}
                        isMinimised={isMinimised}
                        isBidHistoryOpen={isBidHistoryOpen}
                    />
                )}
            </div>
        </>
    );
};

export default AuctionSaleView;

import React, { FC, ReactElement, RefObject } from 'react';

import classes from './scrollbar-tab.module.scss';

interface IScrollbarTab {
    children: ReactElement | ReactElement[];
    className?: 'scroll';
    scrollTabRef? : RefObject<HTMLDivElement>;
}

const ScrollbarTab: FC<IScrollbarTab> = ({ children, className, scrollTabRef = null }) => {
    return (
        <div className={`${classes['scrollbar-v']} ${classes['scrollbar-v-tabs']} ${className ? classes[className] : '' }`}
             ref={scrollTabRef}>
            {children}
        </div>
    );
};

export default ScrollbarTab;

import React, { CSSProperties, FC, MouseEvent } from 'react';

import cssModule from './notify-badge.module.css';

interface INotifyBadgeIcon {
    title: string;
    label: string;
    classes?: Array<string>;
    styles?: CSSProperties;
    isSecondarySale?: boolean;
}

const NotifyBadgeIcon: FC<INotifyBadgeIcon> = ({ title, label, classes = [], styles = {}, isSecondarySale }) => {
    const cls = classes.join(' ');

    const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
    };

    return (
        (isSecondarySale ? <span className={`${cls} ${cssModule['badge']}`} title={title}> </span> :
            <a href="#" onClick={handleOnClick} title={title} className={`${cls} ${cssModule['badge']}`} style={styles}>
                <span className={cssModule['label']}>{label}</span>
            </a>)
    );
};

export default NotifyBadgeIcon;

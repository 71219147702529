import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import React, { FC, MouseEvent, ReactElement } from 'react';

import classes from './tabs.module.scss';

export interface ITabLabel {
    label: string;
    title: string;
    hideTab?: boolean;
    cssClass?: string;
    activeTab?: string;
    onClick?: (label: string) => void;
    gtmInspectionReportClass?: 'inspection-report-trigger';
    children?: ReactElement;
}

const TabLabel: FC<ITabLabel> = ({ label, title, hideTab = false, activeTab = "", cssClass = "", onClick, gtmInspectionReportClass, children }) => {
    const isMobile = useIsMobile();

    if (isMobile) {
        const isActiveClass = activeTab === label ? classes.active : '';

        const handleTabOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();

            if (onClick)
                onClick(label);
        };

        return (
            <div>
                <a href="#" onClick={handleTabOnClick} title={title}
                    className={`${classes['mobile-nav-button']} ${classes[cssClass]} ${gtmInspectionReportClass ? gtmInspectionReportClass : ''} ${isActiveClass} gtm-mobile-nav-button`}>
                    <span className={classes.icons}>
                        <span className={`${classes['icon']} ${classes['icon-off']}`}></span>
                        <span className={`${classes['icon']} ${classes['icon-on']}`}></span>
                    </span>
                    <span className={classes.label}>{title}</span>
                    {children}
                </a>
            </div>
        );
    }

    if (hideTab) return null;

    return (
        <a className={gtmInspectionReportClass} title={title} data-target={label} id={`tab-${label}`} key={label}>
            {title} <span className={classes.l}></span>
        </a>
    );
};

export default TabLabel;

export enum StreamStatus {
    NOT_PLAYING = 'not-playing',
    JOIN_ERROR = 'join-error',
    BAD_JOIN_STATUS = 'join-response-not-ok',
    JOIN_SUCCESS = 'join-successful',
    NO_STREAM_PLAYING = 'no-stream-playing',
    STREAM_ENDED = 'stream-ended',
    FAILED_TO_PLAY = 'failed-to-play',
    BAD_SUBSCRIBE_STATUS = 'subscribe-response-not-ok',
    SUBSCRIBE_ERROR = 'subscribe-error',
    STREAM_IS_PLAYING = 'stream-is-playing',
    IS_ERRORED = 'is-errored'
}

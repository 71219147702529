import { AuthorizationResponse } from 'Domain/Entities/AuthorizationResponse';
import http, { HttpError } from 'Infrastructure/HttpService/http.service';
import { OtherSessionType, setBearerToken, setHasOtherSession, setPermissions } from 'Infrastructure/UserAccount/user-account.slice';
import { getClientLoginInfoFromJWT } from 'Pages/Account/account.service';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { browser_cookies, browser_local, browser_session } from 'services/browser-storage';
import { AppDispatch } from 'services/redux-store';

const invalidLoginMessage = `${process.env.REACT_APP_INVALID_LOGIN_MESSAGE || ''}`;
const toastrTimeout = +(process.env.REACT_APP_TOASTR_TIMEOUT || 30000);

export const useUserAuthentication = (): {
    loginUser: (username: string, password: string) => Promise<void>;
    logoutUser: () => Promise<void>;
} => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const jwt = browser_session.get('__token');

        if (jwt) dispatch(setBearerToken(jwt));
    }, []);

    const loginUser = async (username: string, password: string) => {
        await http
            .post<AuthorizationResponse>('/api/account/login', { username, password }, {})
            .then((res) => {
                const { token, permissions } = res.data;

                const cli = getClientLoginInfoFromJWT(token);

                window.logger.log(JSON.stringify(res));
                browser_session.set('__token', token);
                browser_local.set('__timestamp', Date.now().toString());

                dispatch(setHasOtherSession({ hasOtherSession: cli.IHES, otherSessionType: OtherSessionType.TRANSFER }));
                dispatch(setBearerToken(token));
                dispatch(setPermissions(permissions ?? ({} as Permissions)));
                return res.data;
            })
            .catch((err) => {
                const httpError = err as HttpError;

                if (httpError.isAxiosError) {
                    const { response } = httpError;

                    if (response?.status === 401 || response?.status === 403) {
                        toastr.warning(
                            'Login Error',
                            'The username or password entered is not recognised as having a valid ID. Please check the details you have entered and try again. For support, please contact the Buyer Services Team on 0333 136 1750 or email them via buyerservices@manheim.co.uk.',
                            { timeOut: 0 }
                        );
                    }
                    else if (response?.status === 429) {
                        toastr.error(invalidLoginMessage, '',
                            {
                                timeOut: toastrTimeout,
                                progressBar: false,
                            }
                        );
                    }
                    else if (response?.status === 404) {
                        toastr.error(
                            'Automatic Login Error',
                            'An error occured while trying to automatically log you in, please try re-entering your username and password.'
                        );
                    }
                }

                dispatch(setBearerToken(''));
                return Promise.reject();
            });
    };

    const logoutUser = () => {
        browser_cookies.delete('__suid');
        dispatch(setBearerToken(''));
        dispatch({ type: 'logout' });
        browser_local.clear();
        browser_session.clear();
        return Promise.resolve();
    };

    return { loginUser, logoutUser };
};

import { HubConnection } from '@microsoft/signalr';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useConfigureSignalRUtilMethods = (connection: HubConnection | undefined): void => {
    const { userRole } = useSelector((state: AppState) => state.account);

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on('renderCLD', (message) => {
            newrelic.addPageAction('signalr_event_renderCLD', message);
        });

        connection.on('broadcastMessage', (response) => {
            newrelic.addPageAction('signalr_event_broadcastMessage', response);
        });

        connection.on('reqCBD', (response) => {
            newrelic.addPageAction('signalr_event_reqCBD', response);
            //window.logger.log('reqCBD ', response);
        });

        connection.on('reqCLL', (response) => {
            newrelic.addPageAction('signalr_event_reqCLL', response);

            //window.logger.log(response)
        });

        connection.on('newLogin', (response) => {
            newrelic.addPageAction('signalr_event_newLogin', response);

            window.logger.log('new Login', response);
        });

        return () => {
            connection.off('renderCLD');

            connection.off('broadcastMessage');

            connection.off('reqCBD');

            connection.off('reqCLL');

            connection.off('newLogin');
        };
    }, [connection, userRole]);
};

import React, { FC } from 'react';

import classes from './toggle-switch.module.scss';

interface IToggleSwitchProps {
  isActive: boolean;
  onToggle?: () => void;
}


const ToggleSwitch: FC<IToggleSwitchProps> = ({isActive, onToggle}) => {	
  return (
    <div onClick={onToggle} className={`${classes.toggleSwitch} ${isActive ? classes.active : ''}`}>
    	<span className={classes.handle}></span>
    	<div className={classes.track}></div>
  	</div>
	)
}

export default ToggleSwitch;

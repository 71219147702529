import { useEffect, useState } from 'react';

import { useIsBuyer, useIsVendor } from '../auction-controls.hooks';
import { AuctionControlColours } from '../Enums/auction-ctl-btn-colours.enum';
import { AuctionControlButtonTitle } from '../Enums/auction-ctl-btn-title.entum';
import { AuctionControlButtonTrigger } from '../Enums/auction-ctl-btn-trigger.enum';

export const useAuctionControlButtonState = (
    currentLot: string,
    currentBid: number,
    askingPrice: number,
    isHighestBidder: boolean,
    highestBid: number,
    isMyLot: boolean,
    isOnSale: boolean,
    isLooker = false,
    isBlocked = false
): { title: string; colour: string; trigger: string } => {
    const [actionButtonProps, setActionButtonProps] = useState<{ title: string; colour: AuctionControlColours }>({
        title: AuctionControlButtonTitle.PREPARE_TO_BID_TITLE.toString(),
        colour: AuctionControlColours.PREPARE_TO_BID_COLOUR
    });
    const [trigger, setTrigger] = useState(AuctionControlButtonTrigger.NO_TRIGGER);

    const isBuyer = useIsBuyer();
    const isVendor = useIsVendor();
    const isUserLooker = () => isLooker;
    const isUserBlocked = () => isBlocked;
    const isBuyerPrepareToBid = () => isBuyer && (!currentLot || !askingPrice);
    const isBuyerNotHighestBidder = () => isBuyer && askingPrice > 0 && !isHighestBidder;
    const isBuyerBidSubmitted = () => isBuyer && isHighestBidder && highestBid <= 0;
    const isBuyerHighestBidder = () => isBuyer && isHighestBidder && highestBid > 0;
    const isVendorPrepareToSell = () => isVendor && (!currentLot || !currentBid);
    const isVendorLotOnSale = () => isVendor && isMyLot && isOnSale;
    const isVendorLotNotOnSale = () => isVendor && isMyLot && !isOnSale;
    const isNotVendorLot = () => isVendor && currentLot && !isMyLot;

    useEffect(() => {
        switch (true) {
            case isUserLooker(): {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.IS_LOOKER_TITLE,
                    colour: AuctionControlColours.IS_LOOKER_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isUserBlocked():
                break;
            case isBuyerPrepareToBid(): {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.PREPARE_TO_BID_TITLE.toString(),
                    colour: AuctionControlColours.PREPARE_TO_BID_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isBuyerNotHighestBidder(): {
                setActionButtonProps({
                    title: `${AuctionControlButtonTitle.NOT_HIGEST_BIDDER_TITLE} ${askingPrice.toCurreny()}`,
                    colour: AuctionControlColours.NOT_HIGEST_BIDDER_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.BUYER_TRIGGER);
                break;
            }
            case isBuyerBidSubmitted(): {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.BID_SUBMITTED_TITLE.toString(),
                    colour: AuctionControlColours.BID_SUBMITTED_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isBuyerHighestBidder(): {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.IS_HIGHEST_BIDDER_TITLE.toString(),
                    colour: AuctionControlColours.IS_HIGHEST_BIDDER_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isVendorPrepareToSell(): {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.PREPARE_TO_SELL_TITLE.toString(),
                    colour: AuctionControlColours.PREPARE_TO_SELL_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isNotVendorLot(): {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.UNAUTHORISED_TO_SELL_TITLE.toString(),
                    colour: AuctionControlColours.UNAUTHORIZED_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isVendorLotOnSale(): {
                setActionButtonProps({
                    title: `${AuctionControlButtonTitle.ON_SALE_TITLE.toString()} ${currentBid.toCurreny()}`,
                    colour: AuctionControlColours.ON_SALE_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
                break;
            }
            case isVendorLotNotOnSale(): {
                setActionButtonProps({
                    title: `${AuctionControlButtonTitle.CLICK_TO_SELL_TITLE.toString()} ${currentBid.toCurreny()}`,
                    colour: AuctionControlColours.CLICK_TO_SELL_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.SELLER_TRIGGER);
                break;
            }
            default: {
                setActionButtonProps({
                    title: AuctionControlButtonTitle.IS_LOOKER_TITLE,
                    colour: AuctionControlColours.IS_LOOKER_COLOUR
                });
                setTrigger(AuctionControlButtonTrigger.NO_TRIGGER);
            }
        }
    }, [currentLot, askingPrice, isHighestBidder, highestBid, isBlocked, isMyLot, isOnSale, isLooker]);

    return { ...actionButtonProps, trigger };
};

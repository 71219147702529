import { LiveLotsStatus } from 'Common/Sales/Sales.interface';
import React, { FC, ReactElement, useEffect, useState } from 'react';

import pricingClasses from '../../Features/MainSale/Components/CurrentLotDetails/pricing-information.module.scss';
import classes from './fatedstatuswithcurrency.module.css';

interface IFatedStatusWithCurrency {
    price: number;

    itemStatus?: number
}

const FatedStatusWithCurrency: FC<IFatedStatusWithCurrency> = ({ price, itemStatus = -1 }) => {


    const [hammerText, setHammerText] = useState("");
    const [hammerPriceTextColor, setHammerPriceTextColor] = useState("");

    useEffect(() => {
        const status = itemStatus as LiveLotsStatus;

        if (status === LiveLotsStatus.Sold) {
            setHammerText(' Sold');

            setHammerPriceTextColor('sold');
        } else if (status === LiveLotsStatus.ProvisionallySold) {
            setHammerText(' Provisional');

            setHammerPriceTextColor('sold-prov');
        } else if (status === LiveLotsStatus.Unsold) {
            setHammerText(' Unsold');

            setHammerPriceTextColor('unsold');
        }
    }, [itemStatus]);

    return (<div className={`${classes['fate-bold']}`}><span className={`${classes[hammerPriceTextColor]}`}>
        <span className={pricingClasses['symbol']}></span>{price?.toCurreny() || 0} {hammerText}
    </span></div>);
};

export default FatedStatusWithCurrency;

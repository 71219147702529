import { HubConnectionState } from "@microsoft/signalr";
import { useDialogManagerContext } from "Common/DialogManager/DialogManagerProvider";
import { DIALOG_TYPES } from "Common/DialogManager/Enums/dialog-types.enum";
import { useInternetNavigator } from "Common/InternetNavigator/useInternetNavigator";
import { useWebSocketConnection } from "Common/WebSocketConnection/WebSocketProvider";
import { useEffect } from "react";

const useShowConnectionDisconnectDialog = () => {

    const { showModal, hideModal } = useDialogManagerContext();
    const { connectionStatus = HubConnectionState.Disconnected } = useWebSocketConnection();
    const { isOffline } = useInternetNavigator();
    const isReconnecting = connectionStatus === HubConnectionState.Reconnecting;

    useEffect(() => {
        if (isReconnecting || isOffline) {
            showModal(DIALOG_TYPES.CONNECTION_DISCONNECT_DIALOG, {
                toastTitle: isReconnecting ? "Service Reconnecting" : "Oops it looks like you have lost connection, please check your internet connection and refresh Simulcast",
                iconName: isReconnecting ? '' : "wifi_off"
            })
        }
        else {
            hideModal();
        }

    }, [isOffline, isReconnecting])

}


export default useShowConnectionDisconnectDialog;
export default class BidTimer {
    public refNum: string;
    public lot: string;
    private username: string;
    private startTime: Date;
    private endTime: Date | undefined;
    private responseTimeMs: number | undefined;
    private bidValue: number | undefined;

    constructor(refNum: string, lot: string, username: string, startTime: Date) {
        this.refNum = refNum;
        this.lot = lot;
        this.username = username;
        this.startTime = startTime;
    }

    public setEndTime(endTime: Date): void {
        this.endTime = endTime;
        this.responseTimeMs = this.calculateResponseTime();
    }

    public calculateResponseTime(): number | undefined {
        if (this.endTime) {
            return this.endTime.valueOf() - this.startTime.valueOf();
        }

        return undefined;
    }

    public setBidValue(value: number): void {
        this.bidValue = value;
    }

    public toString(): string {
        return `RefNum: ${this.refNum}, \n
                Lot: ${this.lot}, \n
                Username: ${this.username}, \n
                \t Start Time: ${this.startTime}, \n
                \t End Time: ${this.endTime}, \n
                \t Response Time: ${this.responseTimeMs}`;
    }

    public toObject(): Record<string, unknown> {
        return {
            refNum: this.refNum,
            lot: this.lot,
            username: this.username,
            startTime: this.startTime.toUTCString(),
            endedTime: this.endTime?.toUTCString(),
            responseTimeMs: this.responseTimeMs,
            bidValue: this.bidValue
        };
    }
}

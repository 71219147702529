import React, { useEffect, useState } from 'react';
 
export const useInternetNavigator = (): { isOffline: boolean, isReconnected: boolean } => {
    const [isReconnected, setIsReconnected] = useState<boolean>(false);
    const [isOnline, setIsOnline] = useState<boolean>(false);
    const [isOffline, setIsOffline] = useState(false);

    useEffect(() => {
        if (!isOnline) {
            setIsReconnected(false);
        }
        else if (!isReconnected && isOnline) {
            setIsReconnected(true);
        }
    }, [isOnline]);

    const setIsOnlineStatus = (isOnline: boolean) => {
        setIsOnline(isOnline);
        setIsOffline(!isOnline);
    }

    useEffect(() => {
        window.addEventListener("online", () => { setIsOnlineStatus(true) });
        window.addEventListener("offline", () => { setIsOnlineStatus(false) });
        return (() => {
            window.removeEventListener("online", () => setIsOnlineStatus(true));
            window.removeEventListener("offline", () => setIsOnlineStatus(false));
        })
    }, [])
    return { isOffline, isReconnected };
};

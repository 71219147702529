import React, { FC, MouseEvent } from 'react';

import classes from '../chat-messaging.module.css';

interface IChatButton {
    isDisabled?: boolean;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    styles?: Record<string, string>;
    text: string;
    title: string;
    type?: 'button' | 'submit' | 'reset';
}

const ChatMessageButton: FC<IChatButton> = ({ text, title = 'click', onClick, type = 'button', isDisabled = false, styles = {} }) => {
    return (
        <button type={type} title={title} disabled={isDisabled} className={classes['chat-button']} style={styles} onClick={onClick}>
            {text}
        </button>
    );
};

export default ChatMessageButton;

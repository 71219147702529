import Heading from 'Common/Heading/Heading';
import mobileIcon from 'img/mobile-icon.svg';
import { useUserAccountContext } from 'Infrastructure/UserAccount/UserAccountProvider';
import React, { FC } from 'react';

import classes from '../account.module.scss';

const forgotPasswordLink = `${process.env.REACT_APP_FORGOT_PASSWORD_LINK || ''}`;
const customerServiceMail = `${process.env.REACT_APP_CUSTOMER_SERVICE_MAIL || ''}`;
const customerServiceMobile = `${process.env.REACT_APP_CUSTOMER_SERVICE_MOBILE || ''}`;


const SessionTransferred: FC = () => {

    const { logout } = useUserAccountContext();

    const handleClose = () => {
        logout();
    }

    return (
        <div className={`${classes['content-overlay']} ${classes.row}`}>
            <div className={`${classes.inset} ${classes.box}`}>
                <div className={`${classes.inner} ${classes['session-transferred-div']}`}>
                    <span className={classes['transfer-arrows']}>⇄</span>

                    <Heading size={'medium'} color={'dark-blue'}>
                        IT LOOKS LIKE YOU HAVE JOINED ON ANOTHER DEVICE
                    </Heading>

                    <Heading size={'small'} color={'blue'}>
                        This device has automatically been signed out,
                        you don&apos;t have to do anything.
                    </Heading>

                    <button className={`${classes['input-button']} ${classes.lozenge}`} title='Close'
                        onClick={handleClose}> CLOSE </button>

                    <Heading size={'medium'} color={'blue'}>
                        This wasn&apos;t you?
                    </Heading>

                    <Heading size={'small'} color={'blue'}>
                        If this wasn&apos;t you and you are concerned about the security of your account, please follow these steps
                    </Heading>

                    <a href={forgotPasswordLink} className={`${classes['input-button-transparent']} ${classes.lozenge}`}
                        title='Reset Your Password'> RESET YOUR PASSWORD </a> 

                    <Heading size={'small'} color={'dark-blue'}>
                        Alternatively you can contact customer services
                    </Heading>

                    <Heading size={'small'} color={'blue'} textAlign='start'>
                        {(customerServiceMail?.length > 0) && <div className={classes.row}>✉ <u>{customerServiceMail}</u></div>}
                        {(customerServiceMobile?.length > 0) && <div><img src={mobileIcon} /> <u>{customerServiceMobile}</u></div>}
                    </Heading>

                </div>
            </div>
        </div>
    )
}

export default SessionTransferred;

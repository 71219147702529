import { DateHelper } from 'Common/Helpers/DateHelper';
import ListTable from 'Common/ListTable/ListTable';
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import { IApiLotSummaryViewModel, IApiLotViewModel, IApiProxyBidLotViewModel } from 'Common/Sales/Sales.interface';
import ScrollbarTab from 'Common/ScrollbarTab/ScrollbarTab';
import React, { FC } from 'react';

import ProxyBidsHeader from './ProxyBidsHeader';

interface IProxyBidsProps {
    onRefresh: () => void;
    proxyBidLots: IApiProxyBidLotViewModel[];
}

const ProxyBids: FC<IProxyBidsProps> = ({ onRefresh, proxyBidLots }) => {

    const odometerHtml = (lot: IApiLotViewModel) => {
        return (
            <>
                {lot.mileage && lot.mileage > 0 ? lot.mileage : ''} &nbsp;
                {lot.mileageText && lot.mileageText.toLowerCase() !== 'unknown' ? lot.mileageText : '—'}
            </>
        );
    };

    const proxyBidDetails = proxyBidLots?.map((lot, index) => {

        return (
            <ListTableRow key={index}>
                <ListTableCell value1={lot.lot ?? ''} value2="&nbsp;" />
                <ListTableCell value1={lot.make ?? ''} value2={DateHelper.ToDateString(lot.regDate ?? '')} />
                <ListTableCell value1={lot.model ?? ''} value2={lot.regNo ?? ''} />
                <ListTableCell value1={lot.bidValue?.toCurreny() ?? ''} value2={lot.bodyType ?? ''} />
                <ListTableCell value1="&nbsp;" value2={odometerHtml(lot)} />
            </ListTableRow>
        );
    });

    return (
        <ScrollbarTab>
            <ListTable className='fixed-layout'>
                <>
                    <ProxyBidsHeader onRefresh={onRefresh} />
                    {proxyBidDetails}
                </>
            </ListTable>
        </ScrollbarTab>
    );
};

export default ProxyBids;

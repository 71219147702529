import React, { FC, FormEvent, MouseEvent, ReactElement } from 'react';

import { ITabLabel } from './TabLabel';
import classes from './tabs.module.scss';

interface ITabs {
    activeTab: number | string;
    onChange: (e: MouseEvent<HTMLDivElement>, label: string) => void;
    children: ReactElement<ITabLabel>[];
}

const Tabs: FC<ITabs> = ({ activeTab, onChange, children }) => {
    return (
        <div className={classes['page-nav']}>
            <nav className={classes.tabs}>
                {children
                    .filter((element) => !element.props.hideTab)
                    .map((element, index) => (
                        <div
                            key={`${element.key}_${index}`} title={element.props.title}
                            className={`${element.props.label === activeTab ? `${classes.active} ${classes.focused}` : ''} ${classes.tab} gtm-tab gtm-menu-item`}
                            onClick={(e) => onChange(e, element.props.label)}>
                            {element}
                        </div>
                    ))}
            </nav>
            <span className={classes.line} id="section-nav-line"></span>
        </div>
    );
};

export default Tabs;

import ListTable from 'Common/ListTable/ListTable';
import ScrollbarTab from 'Common/ScrollbarTab/ScrollbarTab';
import { Sale } from 'Domain/Entities/sale.entity';
import { IApiVehicleDetailsViewModel } from 'Features/MainSale/Components/AuctionResults/AuctionResults.container';
import http from 'Infrastructure/HttpService/http.service';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { saleSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import classes from './todays-auction-results.module.scss'
import TodaysAuctionResultsEntry from './TodaysAuctionResultsEntry';
import TodaysAuctionResultsHeader from './TodaysAuctionResultsHeader';
import TodaysAuctionResultsSummary from './TodaysAuctionResultsSummary';

const TodaysAuctionResults: FC = () => {
    const { sales } = useSelector((state: AppState) => state.auctions);

    const salesList = (saleSelectors.selectAll(sales) as Sale[]) || {};

    const { bearerToken } = useSelector((state: AppState) => state.account);

    const [selectedSale, setSelectedSale] = useState('');

    const [todaysAuctionResults, setTodaysAuctionResults] = useState<IApiVehicleDetailsViewModel[]>([]);

    const [renderedAuctionResults, setRenderedAuctionResults] = useState<IApiVehicleDetailsViewModel[]>(todaysAuctionResults);

    const fetchTodaysAuctionResults = () => {
        http.get(`/Api/Vehicle/VehicleSold?refNum=-1`, {
            headers: {
                Authorization: `Bearer ${bearerToken}`
            }
        })
            .then((response) => {
                setTodaysAuctionResults(response.data);

                if (selectedSale) {
                    if (selectedSale !== '-1') {
                        setRenderedAuctionResults(response.data.filter((sale: IApiVehicleDetailsViewModel) => sale.refNum === selectedSale));
                    } else {
                        setRenderedAuctionResults(response.data);
                    }
                } else {
                    setRenderedAuctionResults(response.data);
                }
            })
            .catch((error) => {
                window.logger.error(error);
            });
    };

    useEffect(() => {
        fetchTodaysAuctionResults();
    }, []);

    const refreshList = () => {
        fetchTodaysAuctionResults();
    };

    const dropDownEntries = salesList.map((sale, index) => (
        <option id={sale.refNum} key={index} value={sale.refNum}>
            {sale.saleDescription}
        </option>
    ));

    let totalSold = 0;
    let totalUnsold = 0;
    let totalProvisionalSold = 0;

    const handleSelection = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedSale(e.target.value);

        if (e.target.value !== '-1') {
            setRenderedAuctionResults(todaysAuctionResults.filter((sale) => sale.refNum === e.target.value));
        } else {
            setRenderedAuctionResults(todaysAuctionResults);
        }
    };

    const todaysAuctionResultDetails = renderedAuctionResults?.map((lot, index) => {
        if (lot.saleResult === 'Sold') {
            totalSold += 1;
        } else if (lot.saleResult === 'Provisionally Sold') {
            totalProvisionalSold += 1;
        } else if (lot.saleResult === 'Unsold') {
            totalUnsold += 1;
        }

        return <TodaysAuctionResultsEntry key={index} lot={lot} />;
    });

    return (
        <div className={classes['overlay-main']}>
            <div className={`${classes['overlay-main']} ${classes['help-overlay']}`} style={{ display: 'block' }}>
                <select className={`${classes['options-menu']} ${classes['drop-down']}`} onChange={handleSelection} value={selectedSale}>
                    <option className="" id="-1" value="-1">
                        Select Auction - All
                    </option>
                    {dropDownEntries}
                </select>
                &nbsp;
                <button className={classes['refresh-button']} onClick={refreshList}>
                    Refresh List
                </button>
                <br />
                <br />
                <div className="content">
                    <ListTable>
                        <TodaysAuctionResultsSummary totalSold={totalSold} totalProvisional={totalProvisionalSold} totalUnsold={totalUnsold} />
                        <TodaysAuctionResultsHeader />
                    </ListTable>
                    <ScrollbarTab className="scroll">
                        <ListTable>{todaysAuctionResultDetails}</ListTable>
                    </ScrollbarTab>
                </div>
            </div>
        </div>
    );
};

export default TodaysAuctionResults;

import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import React, { FC } from 'react';

const AuctionResultsHeader: FC = () => {
    return (
        <ListTableRow className='header'>
            <ListTableCell value1="Lot" value2="Reg." />
            <ListTableCell value1="Make" value2="Reg. Date" />
            <ListTableCell value1="Model" value2="Engine Size" />
            <ListTableCell value1="Derivative" value2="Body Style" />
            <ListTableCell value1="Odometer" value2="Inspection Report" classes="halign-right" />
            <ListTableCell value1="Grade" value2="&nbsp;" classes="halign-center" />
            <ListTableCell value1="Result Value" value2="&nbsp;" classes="halign-center"/>         
        </ListTableRow>
    );
};

export default AuctionResultsHeader;

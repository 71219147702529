import { useDialogManagerContext } from 'Common/DialogManager/DialogManagerProvider';
import { DIALOG_TYPES } from 'Common/DialogManager/Enums/dialog-types.enum';
import isLandscape from 'Common/Helpers/OrientationCheck';
import LogoutButton from 'Common/LogoutButton/LogoutButton';
import { useMobileSideNavContext } from 'Common/MobileSideNavProvider/MobileSideNavProvider';
import BiddingDetails from 'Features/BiddingHistory/BiddingDetails.container';
import { handleSetPrimarySale, handleSetPrimarySaleLots } from 'Features/MainSale/main-sale.service';
import React, { FC, MouseEvent, RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { joinedSalesSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import { useIsMobile } from '../../../Common/Hooks/useIsMobile.hook';
import { useGetOrderedSalesList, useJoinSale, useLeaveSale } from '../auctions-list.service';
import AuctionSaleView from '../Components/AuctionSale/AuctionSale.view';
import AuctionSaleHeader from '../Components/AuctionSaleHeader/AuctionSaleHeader';
import UserRole from '../Components/UserRole/UserRole';
import { ISaleListItem } from '../Interfaces/sale-list-item.interface';
import classes from './auction-list.module.css';

const AuctionListView: FC = () => {
    const { isLoading, salesList } = useGetOrderedSalesList();
    const { isMobileSideNavOpen, toggleMobileSideNav } = useMobileSideNavContext();
    const dispatch: AppDispatch = useDispatch();
    const flyoutRef = useRef<HTMLDivElement>(null);
    const { bearerToken } = useSelector((state: AppState) => state.account);
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(true);
    const [bidHistoryRefNum, setBidHistoryRefNum] = useState('');
    const bidHistoryRef = useRef<HTMLDivElement>(null);
    const [activeBidSection, setActiveBidSection] = useState<RefObject<HTMLDivElement>>();
    const joinedSales = joinedSalesSelectors.selectIds(useSelector((state: AppState) => state.auctions.joinedSales));
    const { refNum: primarySaleRefNum = '' } = useSelector((state: AppState) => state.mainSale.sale) || {};
    const [isTabletMode, setTabletMode] = useState(false);
    const mainSale = useSelector((state: AppState) => state.mainSale);
    const { userRole } = useSelector((state: AppState) => state.account);
    const releaseVersionNo = process.env.REACT_APP_RELEASE_VERSION_NO || '';

    const { sale: { currentLot: { workOrder: currentWorkOrder = '' } = {}, refNum: mainSaleRefNum } = {} } = useSelector((state: AppState) => state.mainSale);
    const { showModal } = useDialogManagerContext();
    const { clientLoginInfo } = useSelector((state: AppState) => state.account);
    const { handleJoinSale } = useJoinSale();
    const isMobile = useIsMobile();

    const handleLeaveSale = useLeaveSale(bearerToken, isMobile);

    const handleSetMainSale = (refNum: string) => {
        dispatch(handleSetPrimarySale(refNum));
        dispatch(handleSetPrimarySaleLots(refNum));
    };

    const handleToggleFlyoutMinMax = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setIsFlyoutOpen(!isFlyoutOpen);
    };

    const joinSale = async (siteId: string, refNum: string, suid: string) => {
        const joinedSale = salesList.find((x) => x.isJoined) || ({} as ISaleListItem);

        //get the only sale joined from joined sales list and call leaveSale for that
        if (joinedSale?.refNum && isMobile && isMobileSideNavOpen) {
            handleLeaveSale(joinedSale.siteId, joinedSale.refNum, currentWorkOrder);
        }

        handleJoinSale(siteId, refNum, suid, true, false);
    };

    const handleToggleBidHistory = (refNum: string, saleListElement: RefObject<HTMLDivElement>) => {
        if (refNum === bidHistoryRefNum) {
            setBidHistoryRefNum('');

            setActiveBidSection(undefined);
        } else {
            setActiveBidSection(saleListElement);

            setBidHistoryRefNum(refNum);
        }
    };

    useEffect(() => {
        window.logger.log('sales list updated ', salesList);
    }, [activeBidSection?.current, salesList]);

    useEffect(() => {
        if (!joinedSales.includes(bidHistoryRefNum) || bidHistoryRefNum === primarySaleRefNum) {
            setBidHistoryRefNum('');

            setActiveBidSection(undefined);
        }
    }, [joinedSales, primarySaleRefNum]);

    const handleOpenLogoutDialog = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        showModal(DIALOG_TYPES.LOGOUT_DIALOG, { username: clientLoginInfo?.Username, showLogoutDialog: true });
    };

    useEffect(() => {

        if (isMobile) {
            setTabletMode(false);
            setIsFlyoutOpen(true);
            return;
        }

        const userAgent = navigator.userAgent.toLowerCase();
        const mobileUserAgents = ['iphone', 'android', 'mobile', 'ipad'];

        if (userAgent.includes('macintosh') && window.innerWidth > 900 && window.innerWidth < 1200) {
            setTabletMode(true);
            setIsFlyoutOpen(!mainSale.sale);
            return;
        }

        for (const tabletUserAgent of mobileUserAgents) {
            if (userAgent.includes(tabletUserAgent)) {
                setTabletMode(true);
                setIsFlyoutOpen(!mainSale.sale);
                return;
            }
        }

    }, [mainSale.sale, isMobile]);

    useEffect(() => {
        window.logger.log('sales list updated ', salesList);

        if (bidHistoryRefNum) {
            bidHistoryRef.current!.style.display = 'block';

            updateFlyoutDetailPosition();
        } else {
            bidHistoryRef.current!.style.display = 'none';
        }
    }, [bidHistoryRefNum, activeBidSection?.current, salesList]);

    const updateFlyoutDetailPosition = () => {
        if (activeBidSection?.current && bidHistoryRef.current) {
            const _flyout = flyoutRef.current;

            const _flyoutDetailSection = activeBidSection.current;

            const listItemTop = _flyoutDetailSection!.clientTop;
            const listItemY = _flyoutDetailSection!.getBoundingClientRect().y;
            const flyoutY = _flyout!.getBoundingClientRect().y;

            let offsetTop = listItemTop + listItemY;

            if (offsetTop > flyoutY) offsetTop -= flyoutY;

            bidHistoryRef.current!.style.top = `${offsetTop}px`;
        }
    };

    useEffect(() => {
        const isLandscapeMode = isLandscape();

        if ((salesList?.filter((x) => x.isJoined).length > 1) && isMobile && !isLandscapeMode)
            salesList.filter((sale) => sale.refNum != mainSaleRefNum).forEach((sale) => handleLeaveSale(sale.siteId, sale.refNum, ''));
    }, [isMobile, window.innerWidth]);

    return (
        <div
            ref={flyoutRef}
            className={`${classes['side-drawer']} ${classes.flyout} ${isMobileSideNavOpen ? '' : classes['hide-sidebar']} ${isFlyoutOpen ? '' : classes.minimised} gtm-${isFlyoutOpen ? 'maximised' : 'minimised'}`}>
            <UserRole userRole={userRole} releaseVersionNo={releaseVersionNo} />
            <AuctionSaleHeader
                isTabletMode={isTabletMode}
                isFlyoutOpen={isFlyoutOpen}
                hideMobileSalesList={() => toggleMobileSideNav()}
                handleToggleFlyoutMinMax={handleToggleFlyoutMinMax}
            />

            <div
                onScroll={updateFlyoutDetailPosition}
                id="flyout-sections"
                className={`${classes['scrollbar-v']} ${classes.body} ${classes['mobile-menu-viewport']}`}>
                {isLoading ? (
                    <div className={`${classes['text-center']}`}>Loading Sales...</div>
                ) : salesList.length <= 0 ? (
                    <div className={`${classes['text-center']}`}>No Sales Available</div>
                ) : (
                    salesList.map((obj: ISaleListItem, i: number) => (
                        <AuctionSaleView
                            key={obj.refNum}
                            sale={obj}
                            handleJoinSale={joinSale}
                            handleLeaveSale={handleLeaveSale}
                            handleSetMainSale={handleSetMainSale}
                            openBidHistoryRefNum={bidHistoryRefNum}
                            handleToggleBidHistory={handleToggleBidHistory}
                            isFlyoutOpen={isFlyoutOpen}
                            isTabletMode={isTabletMode}
                        />
                    ))
                )}
            </div>

            {isMobile && (<div className={classes.footer}>
                <LogoutButton handleOpenLogoutDialog={handleOpenLogoutDialog} />
            </div>)}
            {/* <!-- Flyout Bid History --> */}
            <div className={classes['flyout-detail-container']} style={{ overflowY: 'hidden', zIndex: 9999 }}>
                <div className={`${classes['flyout-detail']} ${classes['shadow-2']} ${bidHistoryRefNum ? 'gtm-w-detail-' : ''}`}
                    ref={bidHistoryRef} id="flyout-detail">
                    {/* <!-- Timeline --> */}

                    <BiddingDetails refNum={bidHistoryRefNum} />

                    {/* <!-- /Timeline --> */}
                </div>
            </div>
            {/* <!-- /Flyout Bid History --> */}
        </div>
    );
};

export default AuctionListView;

import './utils/window.utils';
import './wdyr';
import './index.css';
import './utils/string.utils';
import './utils/array.utils';
import './utils/number.utils';

import DialogManagerProvider from 'Common/DialogManager/DialogManagerProvider';
import ToastAlert from 'Common/ToastAlert/ToastAlert';
import WebSocketProvider from 'Common/WebSocketConnection/WebSocketProvider';
import BidLoggingProvider from 'Features/BidLogging/BidLoggingProvider';
import PhenixWebRtcProvider from 'Features/PhenixVideoPlayer/PhenixWebRtcContext/PhenixWebRtcProvider';
import WatchlistSoundProvider from 'Features/WatchlistSound/WatchlistSoundPovider';
import UserAccountProvider from 'Infrastructure/UserAccount/UserAccountProvider';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './services/redux-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;
newrelic.setCustomAttribute('environment', process.env.REACT_APP_ENVIRONMENT ?? 'local');

const isGTMEnabled = JSON.stringify(process.env.REACT_APP_ENABLE_IS_GTM) === JSON.stringify("yes");

if (isGTMEnabled) {
    const gtmId = process.env.REACT_APP_GTM_ID || '';
    const tagManagerArgs = {
        gtmId: gtmId,
        dataLayerName: 'dataLayer'
    };

    TagManager.initialize(tagManagerArgs);
}

const element = document.getElementById('root');

if (element) {
    const root = createRoot(element);
    
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <WatchlistSoundProvider>
                    <WebSocketProvider>
                        <UserAccountProvider>
                            <DialogManagerProvider>
                                <BidLoggingProvider>
                                    <PhenixWebRtcProvider>
                                        <Router>
                                            <App />
                                            <ToastAlert />
                                        </Router>
                                    </PhenixWebRtcProvider>
                                </BidLoggingProvider>
                            </DialogManagerProvider>
                        </UserAccountProvider>
                    </WebSocketProvider>
                </WatchlistSoundProvider>
            </Provider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import ToggleSwitch from 'Common/ToggleSwitch/ToggleSwitch';
import React, { FC } from 'react';

interface IAlertSoundIcon {
    isActive: boolean;
    toggleSoundOnClick: () => void;
}

const AlertSoundIcon: FC<IAlertSoundIcon> = ({ isActive, toggleSoundOnClick }) => {
    return (
        <>
            <span className="label">Alert sounds</span>
            <span className="toggle-switch">
                <ToggleSwitch isActive={isActive} onToggle={toggleSoundOnClick} />
            </span>
        </>
    );
};

export default AlertSoundIcon;

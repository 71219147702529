import React, { FC, ReactElement, useEffect, useRef } from 'react';

import classes from './accordion.module.scss';

export interface IAccordionContent {
    children: ReactElement | ReactElement[];
}

const AccordionContent: FC<IAccordionContent> = ({ children }) => {
    return <div className={classes.content}>{children}</div>;
};

export default AccordionContent;

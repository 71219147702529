import React, { FC } from 'react'
import { v4 as uuidv4 } from 'uuid';

import { BiddingMessage } from './bidding-details.slice';
import classes from './bidding-history.module.css';
import { BidHistoryMessageTypes } from './bidding-messages.types';
import { useGetBidUpdateMessage } from './Hooks/useGetBidUpdateMessage.hook';

interface IBidHistoryMessage {
  bidMessage: BiddingMessage;
  index: number;
  isShowManheimBids: boolean
  userId: string;
  userRole: string;
}

const BidHistoryMessage: FC<IBidHistoryMessage> = ({ bidMessage, index, isShowManheimBids, userId = '', userRole = 'buyer' }) => {
  const { type, message, lot } = bidMessage;
  const key = `${index}_${type}_${uuidv4()}`;

  switch (type) {
    case BidHistoryMessageTypes.NOT_STARTED: {
      return (
        <li key={key} className={classes['timing']}>
          Waiting for the sale to start
        </li>
      );
    }

    case BidHistoryMessageTypes.LOT_CHANGED: {
      return (
        <li key={key} className={`b9 ${classes['new-lot']}`}>
          {message || `Received new lot: Lot ${lot}`}
        </li>
      );
    }

    case BidHistoryMessageTypes.BID_UPDATED: {
      const { message, css, bidType } = useGetBidUpdateMessage(bidMessage, isShowManheimBids, userId, userRole);

      return (
        <li key={key} className={`b6 ${classes['bid']} ${classes['online-bid']} ${classes[css]} ${classes['grouped']}`}>
          {bidType && <span className={`${classes['span-bid-type-image']} ${classes[`${bidType}-bid`]}`} />}
          <span key={key} className="amount">
            {message}
          </span>
        </li>
      );
    }

    case BidHistoryMessageTypes.BID_REJECTED: {
      return (
        <li key={key} className={`b4 ${classes['bid']} ${classes['auctioneer']}`}>
          {message}
        </li>
      );
    }

    case BidHistoryMessageTypes.BIDDING_CLOSED: {
      return (
        <li key={key} className={classes['timing']}>
          <span key={key} className={classes['title']}>
            Bidding closed –
          </span>{' '}
          {message || 'Thank you for participating in today’s auction.'}
        </li>
      );
    }

    case BidHistoryMessageTypes.BIDDING_PAUSED: {
      return (
        <li key={key} className={classes['pause']}>
          <span key={key} className={classes['title']}>
            The Block is currently paused:
          </span>
          {message || `the current vehicle being sold is not available to bid or buy online. The next vehicle available is Lot ${lot}.`}
        </li>
      );
    }

    case BidHistoryMessageTypes.BIDDING_RESUMED: {
      return (
        <li key={key} className={classes['play']}>
          <span key={key} className={classes['title']}>
            The Block is now resumed:
          </span>{' '}
          {message || `Online bidding is welcome on this Lot`}
        </li>
      );
    }

    case BidHistoryMessageTypes.BID_RETRACTED: {
      return (
        <li key={key} className={`b4 ${classes['bid']} ${classes['auctioneer']} ${classes['grouped']}`}>
          {message || 'Auctioneer retracted the last bid'}
        </li>
      );
    }

    case BidHistoryMessageTypes.LOT_SOLD: {
      return (
        <li key={key} className={`b9 ${classes['sold']}`}>
          SOLD - {message || '£ xxx.xx - Hall'}
        </li>
      );
    }

    case BidHistoryMessageTypes.LOT_UNSOLD: {
      return (
        <li key={key} className={`${classes['not-sold']}`}>
          {message || 'LOT NOT SOLD'}
        </li>
      );
    }

    case BidHistoryMessageTypes.LOT_SOLD_PROVISIONAL: {
      return (
        <li key={key} className={`${classes['sold-provisional']}`}>
          PROVISIONAL - {message || '£ xxx.xx - Online Bidder 1234'}
        </li>
      );
    }

    case BidHistoryMessageTypes.AUCTIONEER_MESSAGE: {
      return (
        <li key={key} className={`${classes['message']}`}>
          AUCTIONEER - {message}
        </li>
      );
    }

    case BidHistoryMessageTypes.MESSAGE_ANNOUNCEMENT: {
      return (
        <li key={key} className={`${classes['announcement']}`}>
          <span key={key} className="title">
            {message || 'New Announcement received from Auctioneer:'}
          </span>
        </li>
      );
    }

    case BidHistoryMessageTypes.MESSAGE_REMARK: {
      return (
        <li key={key} className={`${classes['remark']}`}>
          <span key={key} className="title">
            {message || 'New Remarks received from Auctioneer:'}
          </span>
        </li>
      );
    }

    case BidHistoryMessageTypes.MESSAGE_MILEAGE: {
      return (
        <li key={key} className={`${classes['announcement']}`}>
          <span key={key} className="title">
            {message || 'Updated vehicle information received from auctioneer:'}
          </span>
        </li>
      );
    }

    case BidHistoryMessageTypes.MESSAGE_BLOCK: {
      // return <li className={`${classes['disconnect']}`}>You have been disconnected from this auction. Please contact the branch for details.</li>;
      return <>{null}</>; //Dont display this message as handled using div in container.
    }

    case BidHistoryMessageTypes.LOT_RETRACTED: {
      return (
        //TODO: check this is correct?
        <li key={key} className={`b6 ${classes['bid']} ${classes['auctioneer']}`}>
          {message || 'All previous bids on this Lot have been retracted by the Auctioneer'}
        </li>
      );
    }

    case BidHistoryMessageTypes.MESSAGE_UNBLOCK: {
      return (
        <li key={key} className={`${classes['reconnect']}`}>
          {message || `You have now been reconnected to this auction`}
        </li>
      );
    }

    case BidHistoryMessageTypes.WARNING_1: {
      return (
        <li key={key} className={`b3 ${classes['bid']} ${classes['warning']} ${classes['grouped']}`}>
          {message}
        </li>
      );
    }

    case BidHistoryMessageTypes.WARNING_2: {
      return (
        <li key={key} className={`b2 ${classes['bid']} ${classes['warning']} ${classes['grouped']}`}>
          {message}
        </li>
      );
    }

    case BidHistoryMessageTypes.WARNING_3: {
      return (
        <li key={key} className={`b1 ${classes['bid']} ${classes['warning']} ${classes['grouped']}`}>
          {message}
        </li>
      );
    }

    case BidHistoryMessageTypes.NOT_ACTIVE_SALE: {
      return (
        <li key={key} className={`b3 ${classes['bid']} ${classes['warning']} ${classes['grouped']}`}>
          {message}
        </li>
      );
    }

    default: {
      return <>{null}</>;
    }
  }
}

export default BidHistoryMessage;
import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { saleLotsSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

const WatchlistSoundProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isWatchlistSoundActive, setIsWatchlistSoundActive] = useState(true);
    const [audio] = useState(new Audio(`${process.env.PUBLIC_URL}/ALARM.MP3`));
    const { saleLots } = useSelector((state: AppState) => state.auctions);
    const [updatedLotInfo, setUpdatedLotInfo] = useState<{ refNum: string; currentLotNo: string}>();

    const playWatchlistSound = (refNum: string, currentLotNo: string) => {
        if (!isWatchlistSoundActive || window.location.pathname.toLowerCase().includes('vendor')) return;

        const lots = saleLotsSelectors.selectById(saleLots, refNum);

        if (!lots) return;

        const lotNo = parseInt(currentLotNo);

        const hasWatchingVehicle = lots.lotsList
            .filter((x) => parseInt(x.lot) >= lotNo && (x.status !== 1 || x.status !== 2 || x.status !== 3))
            .slice(0, 3)
            .some((x) => x.watching);

        if (hasWatchingVehicle) {
            playSound();
        }
    };

    const toggleWatchlistSound = () => {
        setIsWatchlistSoundActive(!isWatchlistSoundActive);
    };

    const playSound = () => {
        isWatchlistSoundActive ? audio.play() : audio.pause();
    };

    const setSaleLotInformation = (refNum: string, currentLotNo: string) => {
        setUpdatedLotInfo({refNum, currentLotNo});
    };

    useEffect(() => {
        if (updatedLotInfo?.refNum && updatedLotInfo.currentLotNo)
        {
            playWatchlistSound(updatedLotInfo.refNum, updatedLotInfo.currentLotNo);
            setSaleLotInformation("", "");
        }
    }, [saleLots.entities]);

    return (
        <WatchlistSoundContext.Provider value={{ isWatchlistSoundActive, toggleWatchlistSound, playWatchlistSound, playSound, setSaleLotInformation }}>
            {children}
        </WatchlistSoundContext.Provider>
    );
};

export default WatchlistSoundProvider;

interface IWatchlistSoundContext {
    isWatchlistSoundActive: boolean;
    toggleWatchlistSound: () => void;
    playWatchlistSound: (refNum: string, currentLotNo: string) => void;
    playSound: () => void;
    setSaleLotInformation: (refNum: string, currentLotNo: string) => void;
}

export const WatchlistSoundContext = createContext<IWatchlistSoundContext>({
    isWatchlistSoundActive: false,
    toggleWatchlistSound: () => {
        return;
    },
    playWatchlistSound: (refNum: string, currentLotNo: string) => {
        return;
    },
    playSound: () => {
        return;
    },
    setSaleLotInformation: (refNum: string, currentLotNo: string) => { 
        return; 
    }
});

export const useWatchlistSoundContext = () => useContext(WatchlistSoundContext);

import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import ListTable from 'Common/ListTable/ListTable';
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import NoResult from 'Common/NoResult/NoResult';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import AutoHeightScrollbar from 'Common/ScrollbarTab/AutoHeightScrollbar';
import ScrollbarTab from 'Common/ScrollbarTab/ScrollbarTab';
import React, { FC, useEffect, useRef } from 'react';

import AllLotsListItem from './AllLotsListItem';
import AllLotsMobileView from './AllLotsMobileView';

export interface IAllLotsProps {
    allLots: IApiLotViewModel[];
    currentLotNumber: string;
}

const AllLots: FC<IAllLotsProps> = ({ allLots, currentLotNumber }) => {

    const isMobile = useIsMobile();

    const divCurrentLotRef = useRef<HTMLDivElement>(null), scrollTabRef = useRef<HTMLDivElement>(null);

    const renderAllLotsItem = () => {
        const AllLotsItem = isMobile ? AllLotsMobileView : AllLotsListItem;

        return  <>{allLots.map((lot, i) => <AllLotsItem key={i} lot={lot} isCurrentLot={currentLotNumber === lot.lot}
                                                        divCurrentLotRef={divCurrentLotRef} />)}
                </>
    }

    const renderMobileAllLotsUI = () => {
        return (allLots.length ? <AutoHeightScrollbar><ScrollbarTab scrollTabRef={scrollTabRef}>{renderAllLotsItem()}</ScrollbarTab></AutoHeightScrollbar>
                               : <NoResult><>To View All Lots Please Select A Sale</></NoResult>);
    }

    useEffect(() => {
        if (divCurrentLotRef.current && scrollTabRef.current)
            scrollTabRef.current?.scrollTo({ behavior: 'smooth', top: (divCurrentLotRef.current.offsetTop -(isMobile ? 165: 315))});
    }, [currentLotNumber]);

    return (
        <>
            {
                isMobile ? renderMobileAllLotsUI()
                         : <ScrollbarTab scrollTabRef={scrollTabRef}>
                                <ListTable className='all-lots-layout'>
                                    <ListTableRow className='header'>
                                        <ListTableCell value1="Lot" value2="Reg." />
                                        <ListTableCell value1="Make" value2="Reg. Date" />
                                        <ListTableCell value1="Model" value2="Engine Size" />
                                        <ListTableCell value1="Derivative" value2="Body Style" />
                                        <ListTableCell value1="Odometer" value2="Inspection Report" classes="halign-right" />
                                        <ListTableCell value1="Grade" value2="&nbsp;" classes="halign-center" />
                                        <ListTableCell value1="Watchlist" value2="&nbsp;" classes="halign-center" />
                                    </ListTableRow>
                                    {renderAllLotsItem()}
                                </ListTable>
                            </ScrollbarTab>
            }
        </>
    );
};

export default AllLots;

import { RefObject, useEffect, useState } from 'react';

const  useIntersectionObserver = (elementRef: RefObject<Element>): boolean => {
    const [isIntersected, setIsIntersected] = useState<boolean>(false);

    const updateEntry = ([entry]: IntersectionObserverEntry[], observer: IntersectionObserver): void => {  

        if (entry.isIntersecting) {
            observer.unobserve(entry.target);
            setIsIntersected(true);
        }
    }
    
    useEffect(() => {
        const element = elementRef?.current;

        if (!Boolean(window.IntersectionObserver) || !element) return;

        const observer = new IntersectionObserver(updateEntry);

        observer.observe(element);

        return () => observer.disconnect();
    }, [elementRef]);

    return isIntersected;
}

export default useIntersectionObserver;

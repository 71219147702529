import { HubConnectionState } from '@microsoft/signalr';
import withErrorBoundary from 'Common/ErrorBoundary/withErrorBoundary';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import MobileSideNavProvider from 'Common/MobileSideNavProvider/MobileSideNavProvider';
import Navigation from 'Common/Navigation/Navigation.container';
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { OtherSessionType } from 'Infrastructure/UserAccount/user-account.slice';
import SessionTransferred from 'Pages/Account/Views/SessionTransferred.view';
import TransferSession from 'Pages/Account/Views/TransferSession.view';
import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from './layout.module.scss';

const Layout: FC<PropsWithChildren> = ({ children }) => {
    const { connectionStatus } = useWebSocketConnection();
    const { otherSession } = useSelector((state: AppState) => state.account);
    const isMobile = useIsMobile();
    let visibiltyTimeout: ReturnType<typeof setTimeout>;

    const handleVisibilityChange = () => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        if (visibiltyTimeout) clearTimeout(visibiltyTimeout);

        if (isMobile && document.visibilityState === 'visible') {
            if (connectionStatus !== HubConnectionState.Connected) {
                visibiltyTimeout = setTimeout(() => location.reload(), 500);
            }
        }
    };

    useEffect(() => {

        if (!isMobile) return;

        document.addEventListener('visibilitychange', handleVisibilityChange, false);

        return () => {
            if (visibiltyTimeout)
                clearTimeout(visibiltyTimeout);
            document.removeEventListener('visibilitychange', handleVisibilityChange, false);
        }

    }, [connectionStatus !== HubConnectionState.Connected]);

    if (otherSession?.hasOtherSession) {
        return otherSession.otherSessionType === OtherSessionType.TRANSFER ? <TransferSession /> : <SessionTransferred />;
    }

    return (
        <MobileSideNavProvider>
            <div className={`${classes.layout} ${isMobile ? 'gtm-mobile' : ''}`}>
                <header className={classes.header}>
                    <Navigation />
                </header>

                <main className={classes.content}>
                    {children}
                </main>
            </div>
        </MobileSideNavProvider>
    );
};

export default withErrorBoundary(Layout);

export type Sale = {
    auctionId: string;
    audUrl: string;
    branch: string;
    branchId: string;
    brand?: any;
    conId?: string;
    currencyDelimiter: string;
    currencyPosition: string;
    currencySymbol: string;
    defaultRole?: any;
    displayBidderDetails?: any;
    displayName: string;
    isBMW: boolean;
    isClosed: boolean;
    isCyber: boolean;
    isEU: boolean;
    isEUPostCard?: any;
    isFranchise: boolean;
    isFranchiseSale: boolean;
    isPostCard: boolean;
    lane: string;
    messages: any[];
    partners: any[];
    refNum: string;
    region: number;
    saleCulture: string;
    saleDate: Date;
    saleDateUtc: Date;
    saleDescription: string;
    saleSubType?: any;
    saleTime?: any;
    saleType?: any;
    siteId: string;
    siteMedia?: any;
    translatedBranch?: any;
    translatedDescription?: any;
    vidUrl: string;
}

export function sortSalesByDateTimeBranch (a:Sale, b:Sale):number {
    const aDateTime = new Date(a.saleDate);
    const bDateTime = new Date(b.saleDate);

    if(aDateTime < bDateTime) return -1;
    if(aDateTime > bDateTime) return 1;
    return a.branch.localeCompare( b.branch)
}
// eslint-disable-next-line simple-import-sort/sort
import { AppState } from 'services/redux-store';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { IClientLoginInfo } from 'Domain/Entities/user-client-login-info.entity';
import { Permissions } from './../../Domain/Entities/Permissions';

export enum OtherSessionType {
    TRANSFER = 'transfer',
    CLOSED = 'closed'
}

export interface BlockedSale {
    refNum: string;
    isBlocked: boolean;
}

export interface OtherSession {
    hasOtherSession: boolean,
    otherSessionType: OtherSessionType
}

const blockedSalesAdapter = createEntityAdapter<BlockedSale>({
    selectId: (entity) => entity.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

export type AccountState = {
    bearerToken: string;
    clientLoginInfo: IClientLoginInfo | undefined;
    permissions: Permissions;
    userRole: string;
    otherSession: OtherSession | undefined;
    blockedSales: EntityState<BlockedSale>;
    isLooker: boolean;
    userId: string;
};

export type ClientLoginInfoAction = {
    clientLoginInfo?: IClientLoginInfo;
    isLooker?: boolean;
};

const initialState: AccountState = {
    bearerToken: '',
    clientLoginInfo: undefined,
    permissions: {} as Permissions,
    userRole: '',
    otherSession: undefined,
    blockedSales: blockedSalesAdapter.getInitialState(),
    isLooker: false,
    userId: ''
};

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setBearerToken: (state: AccountState, { payload }: PayloadAction<string>) => {
            state.bearerToken = payload;

            return state;
        },
        setClientLoginInfo: (state: AccountState, { payload }: PayloadAction<IClientLoginInfo | undefined>) => {
            state.clientLoginInfo = payload;
            state.isLooker = payload?.ATP === "L";

            return state;
        },
        setPermissions: (state: AccountState, { payload }: PayloadAction<Permissions>) => {
            state.permissions = payload;

            return state;
        },
        setUserRole: (state: AccountState, { payload }) => {
            const { userRole } = payload;

            state.userRole = userRole;

            return state;
        },
        setUserId: (state: AccountState, { payload } : { payload : string }) => {
            state.userId = payload;

            return state;
        },
        upsertBlockedSale: (state: AccountState, { payload }: { payload: BlockedSale }) => {
            blockedSalesAdapter.upsertOne(state.blockedSales, payload);

            return state;
        },
        setHasOtherSession: (state: AccountState, { payload }: PayloadAction<OtherSession | undefined>) => {
            state.otherSession = payload;

            return state;
        }
    }
});

export const blockedSalesSelectors = blockedSalesAdapter.getSelectors<AppState>((state) => state.account.blockedSales);

export const { setBearerToken, setClientLoginInfo, setPermissions, setUserRole, upsertBlockedSale, setHasOtherSession, setUserId } = accountSlice.actions;

export default accountSlice.reducer;

import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { BlockedSale, blockedSalesSelectors } from 'Infrastructure/UserAccount/user-account.slice';
import React, { RefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { joinedSalesSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import { addAuctioneerMessage, ChatMessage, setUnreadMessageCount } from './chat-messaging.slice';

export const useInputDisabled = (refNum: string, inputRef: RefObject<HTMLInputElement>): { isDisabled: boolean } => {
    const { isBlocked: isUserBlocked } = useSelector((state: AppState) => (blockedSalesSelectors.selectById(state, refNum) as BlockedSale) || {});
    const { isLooker } = useSelector((state: AppState) => state.account);

    useEffect(() => {
        inputRef.current!.disabled = isUserBlocked || isLooker;
    }, [isUserBlocked, isLooker, inputRef.current]);

    return { isDisabled: isUserBlocked || isLooker };
};

export const useShowMessagingDisabledToast = (isOpen: boolean, isDisabled: boolean): void => {
    useEffect(() => {
        if (isOpen && isDisabled) {
            //toastr.info('Messaging Disabled', 'Messaging is currently disabled due to the auction being paused or your account being blocked');
        } else if (isOpen && !isDisabled) {
            //toastr.info('Messaging Enabled', 'Messaging has been re-enabled');
        }
    }, [isDisabled]);
};

export const useIsOpenContentHeight = (isOpen: boolean): string => { 
    const height = 32;
    const multiplier = 1.04;
    const [contentDivHeight, setContentDivHeight] = useState('0px');

    useEffect(() => {
        setContentDivHeight(isOpen ? `${height * multiplier}vh` : '0px');
    }, [isOpen]);
    
   return contentDivHeight;
};

export const useMobileChatContentHeight = (chatAreaRef: RefObject<HTMLDivElement>): string => { 
    const [contentHeight, setContentHeight] = useState("0px");
    const isMobile = useIsMobile();
    const { currentLot = {}} = useSelector((state: AppState) => state.mainSale);

    const handleMobileChatHeight = () => {
        if (isMobile || window.innerWidth <= 768) {
            const mobileFooter = document.getElementById('mobile-footer');
            const chatArea = chatAreaRef.current;

            if (mobileFooter && chatArea)
                setContentHeight(`${mobileFooter.getBoundingClientRect().top - chatArea.getBoundingClientRect().top}px`);
        }
    }

    useEffect(() => handleMobileChatHeight(), [isMobile, currentLot]);

    useEffect(() => {
        window.addEventListener('resize', handleMobileChatHeight);        
        return () => window.removeEventListener('resize', handleMobileChatHeight);
    }, []);

    return contentHeight;
};

export const useIsOpenClearUnreadMessage = (isOpen: boolean, refNum: string, unreadMessageCount: number): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (isOpen && unreadMessageCount > 0) {
            dispatch(
                setUnreadMessageCount({
                    refNum,
                    value: 0
                })
            );
        }
    }, [isOpen]);
};

export const useGetMessagesAndCount = (refNum: string) : { myMessages : ChatMessage[], myUnreadMessageCount: number } => {
    const { messages, unreadMessageCount } = useSelector((state: AppState) => state.chat);
    const myMessages = messages[refNum] ?? [];
    const myUnreadMessageCount = unreadMessageCount[refNum] ?? 0;

    return { myMessages, myUnreadMessageCount };
}

export const handleAuctioneerMessage = (message: any) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        try {
            const {
                account: { blockedSales: { entities = {} } = {} },
                auctions: { joinedSales: joinedSalesState }
            } = getState();

            const { refNum } = message;

            const joinedSales = joinedSalesSelectors.selectIds(joinedSalesState);

            const isBlocked = entities[refNum]?.isBlocked || false;

            if (!isBlocked && joinedSales.includes(refNum)) {
                dispatch(addAuctioneerMessage(message));
            }
        } catch (error) {
            window.logger.error(error);
        }
    };
};

import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import { useSelector } from 'react-redux';
import { SaleLots, saleLotsSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

export const useIsVendorsLot = (refNum: string, lot: string): boolean => {
    const { isMyLot = false } =
        useSelector((state: AppState) => saleLotsSelectors.selectById(state.auctions.saleLots, refNum) || ({} as SaleLots))?.lotsList?.find(
            (x) => x.lot === lot
        ) || ({} as IApiLotViewModel);
    return isMyLot;
};

import React, { FC, RefObject } from 'react';

import classes from './sale-preview.module.scss';

interface IBidDetailsLink {
    handleToggleBidHistory: (refNum: string, saleListViewRef: RefObject<HTMLDivElement>) => void;
    isBidHistoryOpen: boolean;
    refNum: string;
    saleListViewRef: RefObject<HTMLDivElement>;
}

const BidDetailsLink: FC<IBidDetailsLink> = ({ handleToggleBidHistory, refNum, saleListViewRef, isBidHistoryOpen }) => {

    return <a
               href="#"
               title="Toggle bid details"
               className={`${classes['bid-detail-trigger']} gtm-bid-detail-trigger ${isBidHistoryOpen ? 'gtm-active' : ''}`}
               onClick={() => handleToggleBidHistory(refNum, saleListViewRef)}>
               Bid Details
           </a>
};

export default BidDetailsLink;
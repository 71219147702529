import { HubConnectionState } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuctionsState } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import { useWebSocketConnection } from '../WebSocketProvider';
import { useWatchSignalRConnectionState } from './useWatchSignalRConnectionState.hook';

export const useSocketRejoinJoinSales = (): ((shouldRejoin: boolean) => void) => {
    const { sendSocketMessage, connectionStatus, connection } = useWebSocketConnection();
    const [shouldRejoin, setShouldRejoin] = useState(false);
    const { isReconnected } = useWatchSignalRConnectionState(connection);

    const { account: { userRole } = {}, auctions = {} as AuctionsState } = useSelector((state: AppState) => state);

    useEffect(() => {
        if ((shouldRejoin || isReconnected) && connectionStatus === HubConnectionState.Connected) {
            singalRLoginRejoinSales();
        }
    }, [shouldRejoin, connectionStatus, isReconnected]);

    const singalRLoginRejoinSales = async () => {
        const tasks: Promise<unknown>[] = [];

        if (connectionStatus !== HubConnectionState.Connected) {
            window.logger.info('Hub State Not Conntected', connectionStatus);
            return;
        }

        if (auctions) {
            const joinedSales = Object.keys(auctions.sales.entities)
                .filter((sale) => auctions.joinedSales.ids.find((s) => sale == s))
                .map((s) => auctions.sales.entities[s]);

            for (const sale of joinedSales) {
                tasks.push(sendSocketMessage('JoinSale', [sale?.siteId, sale?.refNum, userRole, true, false]));
            }
        }

        await Promise.all(tasks);

        setShouldRejoin(false);
    };

    return (shouldRejoin: boolean) => setShouldRejoin(shouldRejoin);
};

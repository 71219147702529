import withErrorBoundary from 'Common/ErrorBoundary/withErrorBoundary';
import React, { createContext, FC, PropsWithChildren, useContext, useRef, useState } from 'react';

import { JoinedChannel } from '../Entities/joined-channel.entity';
import { ChannelExpressObject, JoinChannelOptions, SimulcastMediaStream } from '../Entities/media-stream.entity';
import { PhenixStream } from '../Entities/phenix-stream.entity';
import { StreamStatus } from '../Enums/stream-status.enum';
import { useCreatePhenixChannel } from '../Hooks/useCreateChannel.hook';
import { joinChannelCurried, subscribeChannelCurried } from '../phenix-sdk.extensions';

const adminProxyClientBackendUri = 'https://kingfisher-systems.phenixrts.com/pcast';

// eslint-disable-next-line max-lines-per-function
const PhenixWebRtcProvider: FC<PropsWithChildren> = ({ children }) => {
    const { channel, createChannel } = useCreatePhenixChannel(adminProxyClientBackendUri);
    const joinedChannels = useRef<JoinedChannel[]>([]);
    let [mediaStreams,] = useState<SimulcastMediaStream[]>([]);
    let [channelExpressObjects,] = useState<ChannelExpressObject[]>([]);

    const joinChannel = (joinChannelOptions: JoinChannelOptions) => {

        if (!channel) return;

        try {
            const stream = mediaStreams.find((s) => s.alias === joinChannelOptions.alias);
            const channels = joinedChannels.current.filter((c: JoinedChannel) => c.alias === joinChannelOptions.alias);

            if (stream?.stream?.isActive()) {
                const joinedChannel = channels.find((c) => c.videoElement?.id === joinChannelOptions.videoElement.id);

                stream.stream._renderers = [];

                const renderer = stream.stream.createRenderer();

                if (joinedChannel && joinedChannel.videoElement) {
                    renderer.start(joinedChannel.videoElement);
                } else {
                    const newJoinedChannel = {
                        ...channels[0],
                        alias: joinChannelOptions.alias,
                        videoId: joinChannelOptions.videoElement.id,
                        videoElement: joinChannelOptions.videoElement,
                        refNum: joinChannelOptions.refNum
                    } as JoinedChannel;

                    renderer.start(joinChannelOptions.videoElement);

                    const index = joinedChannels.current.findIndex(x => x.refNum === newJoinedChannel.refNum);

                    if (index < 0)
                        joinedChannels.current.push(newJoinedChannel);
                    else {
                        joinedChannels.current.splice(joinedChannels.current.findIndex(x => x.refNum === newJoinedChannel.refNum), 1);
                        joinedChannels.current.push(newJoinedChannel);
                    }
                }
            } else {
                addChannel(joinChannelOptions);
            }
        } catch (error) {
            window.logger.error('PhenixWebRtcProvider-join-channel', error);
        }
    };

    const addChannel = (joinChannelOptions: JoinChannelOptions) => {

        const channelExpress = createChannel();
        const newJoinedChannel = {
            alias: joinChannelOptions.alias,
            videoElement: joinChannelOptions.videoElement,
            disposables: [],
            channel: channelExpress,
            refNum: joinChannelOptions.refNum
        } as JoinedChannel;

        if (channelExpressObjects.findIndex(x => x.joinChannelOptions.alias === newJoinedChannel.alias) < 0) {

            if (channelExpressObjects.findIndex(x => x.refNum === joinChannelOptions.refNum) > -1) {
                const alias = channelExpressObjects.filter(x => x.refNum === joinChannelOptions.refNum)[0].joinChannelOptions.alias;

                //Below code is to handle producer stream change from block
                if (alias !== joinChannelOptions.alias) {
                    const expressObjectIndex = channelExpressObjects.findIndex(x => x.refNum === joinChannelOptions.refNum);
                    const channelIndex = joinedChannels.current.findIndex(x => x.refNum === joinChannelOptions.refNum);

                    if (channelIndex > -1)
                        joinedChannels.current.splice(channelIndex, 1);

                    channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === joinChannelOptions.refNum).forEach((x: ChannelExpressObject) => x.ChannelExpress.dispose());

                    if (expressObjectIndex > -1)
                        channelExpressObjects.splice(expressObjectIndex, 1);

                    addChannel(joinChannelOptions);

                    if (joinedChannels.current.findIndex(x => x.alias === alias) > -1) {

                        const videoElement: HTMLVideoElement = joinedChannels.current.filter(x => x.alias === alias)[0]?.videoElement;
                        const refNum = joinedChannels.current.filter(x => x.alias === alias)[0]?.refNum;

                        const newJoinChannelOptions: JoinChannelOptions = {
                            videoElement: videoElement,
                            refNum: refNum,
                            alias: alias,
                            streamSelectionStrategy: 'high-availability'
                        }

                        addChannel(newJoinChannelOptions);

                    }
                }
            }
            else {
                channelExpress.joinChannel(
                    joinChannelOptions,
                    joinChannelCurried(newJoinedChannel),
                    subscribeChannelCurried(newJoinedChannel, addMediaStream, removeMediaStream, disposeChannelExpressObjectOnStreamRejoin)
                );
            }
        }

        const index = joinedChannels.current.findIndex(x => x.refNum === newJoinedChannel.refNum);

        if (index < 0)
            joinedChannels.current.push(newJoinedChannel);
        else {
            joinedChannels.current.splice(joinedChannels.current.findIndex(x => x.refNum === newJoinedChannel.refNum), 1);
            joinedChannels.current.push(newJoinedChannel);
        }
        if (channelExpressObjects.findIndex(x => x.joinChannelOptions.alias === newJoinedChannel.alias) < 0)
            channelExpressObjects.push({ refNum: joinChannelOptions.refNum, joinChannelOptions: joinChannelOptions, ChannelExpress: channelExpress })

    };

    const addMediaStream = (alias: string, stream: PhenixStream, videoId?: string) => {
        if (mediaStreams.some((s) => s.alias === alias)) {
            return;
        }
        const tempMediaStreams = [...mediaStreams];

        mediaStreams = [];
        mediaStreams.push(...tempMediaStreams);
        mediaStreams.push({ alias, stream });

        joinedChannels.current
            .filter((c) => c.alias === alias && c.videoElement?.id !== videoId)
            .forEach((c) => {
                if (c.videoElement && document.getElementById(c.videoElement.id)) {
                    const renderer = stream.createRenderer();

                    renderer.start(c.videoElement);
                }

                c.videoElement?.dispatchEvent(new CustomEvent('channel_status', { bubbles: true, detail: StreamStatus.NOT_PLAYING }));
            });
    };

    const removeMediaStream = (alias: string) => {
        joinedChannels.current
            .filter((x) => x.alias === alias)
            .forEach((c) => c.videoElement?.dispatchEvent(new CustomEvent('channel_status', { detail: StreamStatus.NO_STREAM_PLAYING })));


        mediaStreams.filter((s) => s.alias === alias).forEach((s) => s.stream.stop('removing-stream'));

        const index = mediaStreams.findIndex((x) => x.alias === (alias));
        if (index > -1)
            mediaStreams.splice(index, 1);

    };

    const disposeChannelExpressObjectOnStreamRejoin = (joinedChannel: JoinChannelOptions) => {
        const refNum: string = joinedChannel.refNum;

        if (channelExpressObjects.length > 0 && (channelExpressObjects.findIndex(x => x.refNum === refNum) > -1)) {
            //if stream stop and start again then dispose current object and re-initialize same object with joincall method

            const videoElement: HTMLVideoElement = joinedChannels.current.filter(x => x.refNum === refNum)[0]?.videoElement;
            const expressObjectIndex = channelExpressObjects.findIndex(x => x.refNum === refNum);

            if (document.getElementById(videoElement.id)) {
                const joinChannelOptions: JoinChannelOptions = channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum)[0]?.joinChannelOptions;

                channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum).forEach((x: ChannelExpressObject) => x.ChannelExpress.dispose());

                if (expressObjectIndex > -1)
                    channelExpressObjects.splice(expressObjectIndex, 1);

                if (videoElement) {
                    joinChannelOptions.videoElement = videoElement;
                    addChannel(joinChannelOptions);
                }
            }
            else {
                //This code is for tablet when secondary sale is minimised

                channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum).forEach((x: ChannelExpressObject) => x.ChannelExpress.dispose());

                if (expressObjectIndex > -1)
                    channelExpressObjects.splice(expressObjectIndex, 1);

                const channelIndex = joinedChannels.current.findIndex(x => x.refNum === refNum);

                if (channelIndex > -1)
                    joinedChannels.current.splice(channelIndex, 1);

                createExpressObjectForOtherChannel(joinedChannel);
            }

        }
        else {
            createExpressObjectForOtherChannel(joinedChannel);
        }
    }

    const createExpressObjectForOtherChannel = (joinedChannel: JoinChannelOptions,) => {
        const alias: string = joinedChannel.alias;

        //If default object is already disposed and still same alias channels are present then
        //new express object is created for remaining sales of same alias
        if (joinedChannels.current.findIndex(x => x.alias === alias) > -1) {
            const channel: JoinedChannel = joinedChannels.current.filter(x => x.alias === alias)[0];
            const videoElement: HTMLVideoElement = channel?.videoElement;
            const newRefNum: string = channel?.refNum;

            if (videoElement) {
                joinedChannel.videoElement = videoElement;
                joinedChannel.refNum = newRefNum;
                addChannel(joinedChannel);
            }
        }
    }

    const disposeChannelExpressObject = (refNum?: string | undefined) => {

        //On sale close remove channel from joinedChannels array and remove object from channelExpressObjects array
        if (refNum) {
            const channelIndex = joinedChannels.current.findIndex(x => x.refNum === refNum);
            const expressObjectIndex = channelExpressObjects.findIndex(x => x.refNum === refNum);

            if (channelIndex > -1)
                joinedChannels.current.splice(channelIndex, 1);

            const alias = channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum)[0]?.joinChannelOptions.alias;

            if (joinedChannels.current.findIndex(x => x.alias === alias) > -1) {
                //If channels are present but without default object then create new object for same alias and default object is disposed and removed from array

                const joinChannelOptions: JoinChannelOptions = channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum)[0]?.joinChannelOptions;

                channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum).forEach((x: ChannelExpressObject) => x.ChannelExpress.dispose());

                if (expressObjectIndex > -1)
                    channelExpressObjects.splice(expressObjectIndex, 1);

                const videoElement: HTMLVideoElement = joinedChannels.current.filter(x => x.alias === alias)[0]?.videoElement;
                const newRefNum = joinedChannels.current.filter(x => x.alias === alias)[0]?.refNum;

                joinChannelOptions.videoElement = videoElement;
                joinChannelOptions.refNum = newRefNum;
                joinedChannels.current.filter(x => x.alias === alias).forEach(x => x.videoElement.dispatchEvent(new CustomEvent('channel_status', { bubbles: true, detail: StreamStatus.NO_STREAM_PLAYING })));
                addChannel(joinChannelOptions);
            }
            else {
                //If  channels are not present for same alias then default object is disposed and removed from array
                channelExpressObjects.filter((s: ChannelExpressObject) => s.refNum === refNum).forEach((x: ChannelExpressObject) => x.ChannelExpress.dispose());
                if (expressObjectIndex > -1)
                    channelExpressObjects.splice(expressObjectIndex, 1);

            }
        }
        else {
            joinedChannels.current = [];
            channelExpressObjects.forEach((x: ChannelExpressObject) => x.ChannelExpress.dispose());
            channelExpressObjects = [];
        }
    }

    return (
        <PhenixWebRtcContext.Provider
            value={{
                joinedChannels: joinedChannels.current,
                joinChannel,
                removeMediaStream,
                disposeChannelExpressObject
            }}>
            {children}
        </PhenixWebRtcContext.Provider>
    );
};

interface IPhenixWebRtcContext {
    joinedChannels: JoinedChannel[];
    joinChannel: (joinChannelOptions: JoinChannelOptions) => void;
    removeMediaStream: (alias: string) => void;
    disposeChannelExpressObject: (refnum?: string | undefined) => void;
}

const PhenixWebRtcContext = createContext<IPhenixWebRtcContext>({
    joinedChannels: [],
    joinChannel: (joinChannelOptions: JoinChannelOptions) => {
        return;
    },
    removeMediaStream: (alias: string) => {
        return;
    },
    disposeChannelExpressObject: (refNum?: string | undefined) => {
        return;
    }
});

export const usePhenixWebRtcContext = (): IPhenixWebRtcContext => useContext(PhenixWebRtcContext);

export default withErrorBoundary(PhenixWebRtcProvider);

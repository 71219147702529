import { HubConnection } from '@microsoft/signalr';
import { LiveLotsStatus } from 'Common/Sales/Sales.interface';
import { resetBiddingState, setIsOnSale } from 'Features/AuctionControls/bidding-state.slice';
import { handleSendVehicleSoldEventToGTM } from 'Features/AuctionsList/auctions-list.service';
import { useWatchlistSoundContext } from 'Features/WatchlistSound/WatchlistSoundPovider';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUpdatedSaleLotsWatchlist,
    setSaleCurrentLot,
    setSaleCurrentLotExtra,
    setSaleCurrentLotFieldUpdate,
    setSaleCurrentLotLights,
    setSaleLotStatus
} from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useConfigureSignalRLotMethods = (connection: HubConnection | undefined): void => {
    const dispatch = useDispatch();
    const { setSaleLotInformation } = useWatchlistSoundContext();
    const { userRole } = useSelector((state: AppState) => state.account);
    const isGTMEnabled = JSON.stringify(process.env.REACT_APP_ENABLE_IS_GTM) === JSON.stringify("yes");

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on('currentLotFieldUpdate', (message) => {
            newrelic.addPageAction('signalr_event_currentLotFieldUpdate', message);

            dispatch(setSaleCurrentLotFieldUpdate(message));
        });

        connection.on('lotStatusUpdate', (message) => {
            newrelic.addPageAction('signalr_event_lotStatusUpdate', message);

            //TODOxMF: this will need to actually handle updating the status for the out of sequence message
            //It will need to update teh api lot data status, with the new redux changes.
            dispatch(setSaleLotStatus(message));

            if (message.status === LiveLotsStatus.Retracted) {
                const { refNum } = message;

                dispatch(resetBiddingState(message));

                dispatch(setIsOnSale({ refNum, isOnSale: false }));
            }
        });

        connection.on('lotUpdated', (message) => {
            newrelic.addPageAction('signalr_event_lotUpdated', message);

            dispatch(getUpdatedSaleLotsWatchlist(message.refNum));

            setSaleLotInformation(message.refNum, message.lot);

            dispatch(setSaleCurrentLot(message));
        });

        connection.on('lotUpdatedExtraDetails', (message) => {
            newrelic.addPageAction('signalr_event_lotUpdatedExtraDetails', message);

            dispatch(setSaleCurrentLotExtra(message));
        });

        connection.on('lightsUpdate', (message) => {
            newrelic.addPageAction('signalr_event_lightsUpdate', message);

            dispatch(setSaleCurrentLotLights(message));

            const { refNum, onSale } = message;

            dispatch(setIsOnSale({ refNum, isOnSale: onSale }));
        });

        connection.on('sendVehicleSoldEventToGTM', (message) => {
            if (isGTMEnabled) {
                newrelic.addPageAction('signalr_event_sendVehicleSoldEventToGTM', message);

                const { currentBidValue, lotNum, refNum } = message;

                dispatch(handleSendVehicleSoldEventToGTM(refNum, lotNum, currentBidValue));
            }
        });

        return () => {
            connection.off('currentLotFieldUpdate');

            connection.off('lotStatusUpdate');

            connection.off('lotUpdated');

            connection.off('lotUpdatedExtraDetails');

            connection.off('lightsUpdate');

            connection.off('sendVehicleSoldEventToGTM');
        };
    }, [connection, userRole]);
};

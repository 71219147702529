 
import Heading from 'Common/Heading/Heading';
import React, { FC, FormEvent, useState } from 'react';

import classes from '../account.module.scss';
import Promo from '../Components/Promo';

interface ILogin {
    handleLoginSubmit: (e: FormEvent<HTMLFormElement>, username: string, password: string) => void;
}

const defaultUsername = `${process.env.REACT_APP_DEV_USERNAME || ''}`;
const defaultPassword = `${process.env.REACT_APP_DEV_PASSWORD || ''}`;
const forgotPasswordLink = `${process.env.REACT_APP_FORGOT_PASSWORD_LINK || ''}`;
const registerLink = `${process.env.REACT_APP_REGISTER_LINK || ''}`;

const Login: FC<ILogin> = ({ handleLoginSubmit }) => {
    const [username, setUsername] = useState(defaultUsername);
    const [password, setPassword] = useState(defaultPassword);
    const [showPassword, setShowPassword] = useState(false);
    
    const handleHideOrShowPassword = () => 
    {
		setShowPassword(!showPassword);
    }

    return (
        <>
            <form className={classes.inner} noValidate={false} autoComplete="off" onSubmit={(e) => handleLoginSubmit(e, username, password)}>
                <Heading size={'large'} color={'dark-blue'}>
                    Welcome to
                    <br />
                    Manheim Simulcast
                </Heading>

                <div className="form">
                    <div className={classes.row}>
                        <input
                            type="text"
                            name="username"
                            placeholder="Username"
                            className={classes['input-text']}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            
                        />
                    </div>
                    <div className={`${classes.row} ${classes.password}`}>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            className={classes['input-text']}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        
                        />
                        <span className={`${classes['span-view-password-icon']} ${classes['eye-' + (showPassword ? 'view' : 'hide') + '-icon']}`}
							  onClick={handleHideOrShowPassword} title={`${showPassword ? 'Hide' : 'Show'} Password`} />
                        
                    </div>
                    <div className={classes.row}>
                        <button type="submit" className={`${classes['input-button']} ${classes['lozenge']}`} title="Login" id="login-button">
                            Login
                        </button>
                        <p className={`${classes.options} d-flex`}>
                            <a href={registerLink} className={`${classes.link} d-flex-50 text-left`} title="Register for account">
                                Register
                            </a>
                            <a href={forgotPasswordLink} className={`${classes.link} d-flex-50 text-right`} title="I have forgotten my password">
                                I have forgotten my password
                            </a>
                        </p>
                    </div>
                </div>
            </form>

            <Promo />
        </>
    );
};

export default Login;

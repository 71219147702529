import classes from 'Common/DataTable/data-table.module.scss';
import React, { FC } from 'react';

interface IFieldDisplayProps {
    displayName: string;
    value?: string;
    isWarning?: boolean;
}

const FieldDisplay: FC<IFieldDisplayProps> = ({ displayName, isWarning, value }) => {
    let displayValue = value;

    if (!value || value === '-') {
        displayValue = '\u00A0';
    }

    const displayWarning = () => {
        if (isWarning) {
            return { color: 'red' };
        }
    };

    return (
        <div className={classes['row']}>
            <div className={classes['key']}>{displayName}</div>
            <div className={classes['value']} style={displayWarning()}>
                {displayValue}
            </div>
        </div>
    );
};

export default FieldDisplay;

import { DateHelper } from 'Common/Helpers/DateHelper';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import moment from 'moment';
import React from 'react';

import { CheckPoint } from '../../Domain/Enums/checkpoint.enum';
import CheckpointIcon from './HelpTooltips/CheckpointIcon';
import SurecheckIcon from './HelpTooltips/SurecheckIcon';
import V5Icon from './HelpTooltips/V5Icon';
import StatusBadge from './StatusBadge';
import StatusLabel from './StatusLabel/StatusLabel';

export const createLotStatusBadges = (
    v5: boolean | null,
    surecheck: boolean,
    sureCheckText: string,
    sureCheckResult: string,
    checkpoint: number | undefined,
    isMicro = false
): Array<JSX.Element> => {
    const sureCheckColour = surecheckColour(sureCheckText);
    let checkpointColour = 'disabled';
    const isV5Disabled = isNullOrUndefined(v5);
    const sureCheckDisabled = isNullOrUndefined(surecheck) || !sureCheckText ? 'disabled' : '';
    const microClass = isMicro ? 'micro' : '';

    if (checkpoint) {
        if (CheckPoint.CP_Green === checkpoint)
            checkpointColour = 'positive';
        else if (CheckPoint.CP_Amber === checkpoint)
            checkpointColour = 'warning';
    }

    return [
        <StatusBadge key="v5" title={<></>} classNames={[`${(isV5Disabled ? 'no-lot' : !v5 ? 'type-1' : 'no-v5')}`, microClass]} gtmTitle='V5'>
            {isMicro ? undefined : <V5Icon />}
        </StatusBadge>,
        <StatusBadge
            key="surecheck" gtmTitle={'Sure Check'}
            title={<StatusLabel title={`${sureCheckText || ''} ${sureCheckText ? sureCheckResult : ''}`} isDisabled={sureCheckDisabled === 'disabled'} />}
            classNames={['type-2', 'status-badge-sc', microClass, sureCheckColour, sureCheckDisabled]} />,
        <StatusBadge key="checkpoint" title={<StatusLabel title="" />} classNames={['type-3', 'status-badge-mvp', microClass, checkpointColour]} gtmTitle='Check Point'>
            {isMicro ? undefined : <CheckpointIcon />}
        </StatusBadge>
    ];
};

const isNullOrUndefined = (value: any) => value === null || value === undefined;

const goldRegex = new RegExp('Gold', 'i');
const silverRegex = new RegExp('Silver', 'i');
const bronzeRegex = new RegExp('Bronze', 'i');
const lcvRegex = new RegExp('Lcv', 'i');
const lcvEVRegex = new RegExp('EV LCV', 'i');

export const surecheckColour = (sureCheckText: string): string => {
    if (goldRegex.test(sureCheckText)) {
        return 'gold';
    } else if (silverRegex.test(sureCheckText)) {
        return 'silver';
    } else if (bronzeRegex.test(sureCheckText)) {
        return 'bronze';
    } else if (lcvEVRegex.test(sureCheckText)) {
        return 'ev'
    } else if (lcvRegex.test(sureCheckText)) {
        return 'lcv';
    }

    return 'ev';
};

export const getMOTDisplayText =  (lotDetails: IApiLotViewModel): string => {

    const isMobile = useIsMobile();

    let motText = '';

    if (Object.keys(lotDetails).length > 0)
    {
        const { motExpiry = '', mot = '' } = lotDetails || {};

        let motDate = motExpiry;
        
        if(!motDate && mot)
        {
            motDate = DateHelper.ToDateString(mot);
        }

        const currentDate = moment(new Date()).toDate();

        currentDate.setHours(0, 0, 0, 0);

        const motExpiryDate = moment(motDate, 'DD/MM/YYYY').toDate();

        motText = (Object.keys(lotDetails)?.length) ?
            (motDate && motExpiryDate.valueOf() >= currentDate.valueOf()) ? isMobile ? `${motDate}` : `MOT ${motDate}` 
            : 'No MOT' : '';
    }

    return motText;
}

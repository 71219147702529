declare global {
    interface Array<T> {
        groupBy<T, K extends keyof any>(key: (item: T) => K): Record<K, T[]>;
    }
}

Array.prototype.groupBy = function <T, K extends keyof any>(this: T[], key: (item: T) => K) {
    return this.reduce((map$, item) => {
        const group = key(item);

        if (!map$[group]) {
            map$[group] = [];
        }

        map$[group].push(item);

        return map$;
    }, {} as Record<K, T[]>);
};

export {};

import React from 'react';

const OrientationLandscapeError = () => {

    return (
        <>
            <div className="orientation-error">
                <div>Please use this application in portrait mode.</div>
            </div>
        </>
    );
}

export default OrientationLandscapeError;
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { useBidLoggingContext } from 'Features/BidLogging/BidLoggingProvider';
import { useDispatch } from 'react-redux';

import { setHaveBid, setIsUserIn } from '../bidding-state.slice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useClickToBid = (): ((
    bid: number,
    lot: string,
    siteId: string,
    refNum: string,
    buyerNumber: string,
    workOrder: string,
    clickTime?: Date,
    responseTime?: string,
    partner?: string
) => void) => {
    const { sendSocketMessage } = useWebSocketConnection();
    const dispatch = useDispatch();
    const { addPlaceBid } = useBidLoggingContext();

    const handleClickToBid = (
        bid: number,
        lot: string,
        siteId: string,
        refNum: string,
        buyerNumber: string,
        workOrder: string,
        clickTime?: Date,
        responseTime = '',
        partner = ''
    ) => {
        dispatch(setHaveBid({ refNum, haveBid: true }));

        if (!clickTime) {
            clickTime = new Date();
        }

        addPlaceBid(refNum, lot, clickTime);

        const start = performance.now();

        sendSocketMessage('PlaceBid', [bid, lot, siteId, refNum, buyerNumber, workOrder, clickTime, responseTime, partner])
            .then(() => {
                const duration = performance.now() - start;

                newrelic.addPageAction('bid_button', {
                    status: 'updated',
                    duration
                });

                dispatch(setIsUserIn({ refNum, amIIn: true }));
            })
            .catch((error) => {
                window.logger.error(error);
            });
    };

    return handleClickToBid;
};

import moment from 'moment';
import React, { FC, ReactElement } from 'react';

import classes from './sale-preview.module.scss';

interface ISalePreviewHeader {
    branch: string;
    description: string;
    saleDate?: Date;
}

const SalePreviewHeader: FC<ISalePreviewHeader> = ({ branch, description, saleDate }) => {
    return (
        <div className={classes['head']}>
            <p className={classes['head-p']}>
                <span>{branch}</span>
                <br />
                <span className={classes['sale-header-desc']}>{description}</span>
                <br />
                <p>{saleDate && moment(saleDate).toLocaleString()}</p>
            </p>
        </div>
    );
};

export default SalePreviewHeader;

import Accordion from 'Common/Accordion/Accordion';
import AccordionTitle from 'Common/Accordion/AccordionTitle';
import MinMaxButton from 'Common/MinMaxButton/MinMaxButton';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import React, { FC, useState } from 'react';

import classes from './upcoming-vehicles.module.scss'
import UpcomingVehicleEntry from './UpcomingVehicleEntry';

interface IUpcomingVehiclesProps {
    currentLot: string;
    saleVehicles: IApiLotViewModel[];
}

const UpcomingVehicles: FC<IUpcomingVehiclesProps> = ({ currentLot, saleVehicles }) => {
    const upcomingVehicleTitle = 'Upcoming Vehicles';
    const [togglePanelOpen, setTogglePanelOpen] = useState(false);
    const showLotCount = 10;
    const currentLotIndex = saleVehicles?.findIndex((x) => x.lot === currentLot);
    const finalIndex = currentLotIndex + 1 + showLotCount;

    const lotsToDisplay = saleVehicles?.slice(currentLotIndex + 1, finalIndex);

    const upcomingVehicles = lotsToDisplay
        ?.filter((v) => v.status !== 1 && v.status !== 2 && v.status !== 3)
        .map((item, index) => {
            return <UpcomingVehicleEntry lot={item} key={index} />;
        });

    const onHeaderClick = () => {
        setTogglePanelOpen(!togglePanelOpen);
    };

    return (
        /*GTM: upcoming-vehicles id required for GA. */
        <div id="upcoming-vehicles" className={classes['upcoming-vehicles-container']}>
            <Accordion className='overlay-box' onClick={onHeaderClick}>
                <AccordionTitle title={upcomingVehicleTitle}>
                    <p>
                        {upcomingVehicleTitle}
                    </p>
                    <MinMaxButton isMinimised={!togglePanelOpen} />
                </AccordionTitle>

                <div className={classes['upcoming-vehicles']}>
                    {upcomingVehicles}
                </div>
            </Accordion>
        </div>
    );
};

export default UpcomingVehicles;

import React, { FC, ReactNode } from 'react';

export interface ITabContent 
{
    label: string;
    children: ReactNode;
}

const TabContent: FC<ITabContent> = ({ label, children }) => {
    return (
        <div key={label}>
            {children}
        </div>
    );
};

export default TabContent;

import React, { FC } from 'react';

import classes from "./minmaxbutton.module.scss";

interface IMinMaxButton {
	isMinimised: boolean;
	color?: 'blue' | 'white';
	gtmClass?: string;
	isHover?: boolean;
	onClick?: (e: any) => void;
}

const MinMaxButton: FC<IMinMaxButton> = ({ isMinimised, color, isHover, gtmClass, onClick }) => {
	return (
		<span className={`${isMinimised ? classes.minimised : ''} ${gtmClass ?? ''}`}
			title={isMinimised ? "Maximise" : "Minimise"}>
			<span className={`${classes.min_max_icon} ${color ? classes[color] : ''} ${isHover ? classes['hover'] : ''}`}
				onClick={onClick}>
				<span className={classes.d}></span>
				<span className={`${classes.a} ${classes.a_1}`}></span>
				<span className={`${classes.a} ${classes.a_2}`}></span>
			</span>
		</span>
	)
}

export default MinMaxButton;

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SaleLots, saleLotsSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import MainSaleView from './Views/MainSale.view';

interface IMainSaleContainer {
    isMobile: boolean;
}

const MainSale: FC<IMainSaleContainer> = ({ isMobile }) => {

    const { currentLot = {}, sale = {}, proxyBids = [] } = useSelector((state: AppState) => state.mainSale);

    const { lotsList: lots = [] } = useSelector((state: AppState) => saleLotsSelectors.selectById(state.auctions.saleLots, sale.refNum) || ({} as SaleLots));
     
    return <MainSaleView isMobile={isMobile} currentLot={currentLot} lots={lots} proxyBids={proxyBids} sale={sale} />;
};

MainSale.whyDidYouRender = true;

export default MainSale;

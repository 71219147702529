import SwapSaleArrowIcon from 'img/icon-a-swap-arrow.png';
import SwapSaleIcon from 'img/icon-a-swap.png';
import moment from 'moment';
import React, { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from './sale-summary.module.scss';

interface ISaleSummaryProps {
    displayName: string;
    laneNumber: string;
    saleDate: Date;
    saleDescription: string;
    isTabletMode?: boolean;
    onJoinSale?: (e?: any) => void;
    toggleShowPreview?: (e?: MouseEvent<HTMLDivElement>) => void;
    leavePrimarySale?: () => Promise<void>;
    isFlyoutOpen?: boolean;
}

const SaleSummary: FC<ISaleSummaryProps> = ({
    displayName,
    laneNumber,
    saleDate,
    saleDescription,
    isTabletMode = false,
    toggleShowPreview,
    leavePrimarySale,
    onJoinSale,
    isFlyoutOpen = true
}) => {
    const primarySale = useSelector((state: AppState) => state.mainSale);

    const leaveAndJoin = async () => {
        if (onJoinSale) onJoinSale();
        if (leavePrimarySale) await leavePrimarySale();
        // if (toggleShowPreview) toggleShowPreview();
    };

    if (isTabletMode && primarySale.sale) {
        const time = moment(saleDate).toLocaleString().split(' ').slice(4).join(' ');

        return (
            <div className={classes['summary']}>
                <span className={classes['lane']}>
                    {displayName} Lane {laneNumber}
                </span>
                <span className={classes['join-sale']} onClick={() => leaveAndJoin()}>
                    <div className={classes['swap-sale-image']}>
                        <img className={classes['swap-sale-arrow']} src={SwapSaleArrowIcon} alt="swap-sale-arrow" />
                        <img className={classes['swap-sale']} src={SwapSaleIcon} alt="swap-sale" />
                    </div>
                </span>
                <span className={classes['timestamp']}>
                    {saleDescription} <br />
                    {time}
                </span>
            </div>
        );
    }

    return (
        <>
            <div className={`${!isFlyoutOpen ? `${classes['sale-summary']} ${classes['minimised']}` : classes['sale-summary']}`}>
                <p className={`${classes['line']} ${classes['line-1']}`}>
                    <span className={classes['summary-name']}>{displayName}</span>
                    <span className={classes['summary-lane']}>Lane {laneNumber}</span>
                </p>
                <p className={`${classes['line']} ${classes['line-2']}`}>{saleDescription}</p>
                <p className={`${classes['line']} ${classes['line-3']}`}>{moment(saleDate).toLocaleString()}</p>
            </div>
        </>
    );
};

export default SaleSummary;

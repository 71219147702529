import React, { FC, ReactElement, RefObject } from 'react';

import classes from './sale-preview.module.scss';

interface ISwapIcon {
    handleSwapMainSaleOnClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const SwapIcon: FC<ISwapIcon> = ({ handleSwapMainSaleOnClick }) => {

    {/*   GTM:  make-primary-trigger class required for GA. */ }

    return <a href="#" onClick={handleSwapMainSaleOnClick} title="Swap with main window" className={`make-primary-trigger ${classes['a-icon']} ${classes['ac-swap']}`}>
               <span className={classes['decal']}>
                   <span className={classes['a']}></span>
               </span>
               <span className={classes['tooltip']}>Swap with main window</span>
           </a>
};

export default SwapIcon;
import React, { FC } from 'react';

import classes from '../account.module.scss';

interface IUserIsLooker {
    isLooker: boolean;
}

const UserIsLooker: FC<IUserIsLooker> = ({ isLooker }) => {
        return (
            isLooker ? 
                <div className={classes['lookers-overlay']}>
                    <span className={classes['lookers-message']}>
                        <p>Demo Only</p>
                    </span>
                </div>
                : null
        );
};

export default UserIsLooker;

import { ICurrentLot } from "Common/Interfaces/current-lot.interfaces";
import { createLotStatusBadges, getMOTDisplayText } from 'Common/LightStatusBadge/status-badge.service';
import moment from "moment";
import React, { FC, ReactElement } from 'react';
import { useSelector } from "react-redux";
import { saleLotsSelectors } from "services/auctions.slice";
import { AppState } from "services/redux-store";

import { IApiLotViewModel } from "../../../../Common/Sales/Sales.interface";
import classes from './sale-preview.module.scss';
import SalePreviewVendorLogo from './SalePreviewVendorLogo';

interface IPreviewStatusBadges {
    currentLot: ICurrentLot;
}

const PreviewStatusBadges: FC<IPreviewStatusBadges> = ({ currentLot }) => {

    const {
        lightStatus: { v5 = null, sureCheck = false, vendorOnline = false } = {},
        extraDetails: { checkPoint } = {}, lotDetails = {}, lot = '', refNum = ''
    } = currentLot;

    const { sureCheckText = '', sureCheckResult = '' } = (useSelector((state: AppState) => saleLotsSelectors.selectById(state.auctions.saleLots, refNum))?.lotsList.find(x => x.lot === lot)) ?? {} as IApiLotViewModel;

    const statusBadges = createLotStatusBadges(v5, sureCheck, sureCheckText, sureCheckResult, checkPoint, true); 

    const motText = getMOTDisplayText(lotDetails);

    return (<>
        <div className={classes['status-badges']}>
            <div className={classes['cells']}>
                <SalePreviewVendorLogo vendorOnline={vendorOnline} />
                <div className={classes['cell']}>{statusBadges.map((obj) => obj)} </div>
                <div className={classes['cell']}>
                    <div className={`${classes['mot-icon']}`}>
                        <span className={`${classes['mot-icon-positive']}`}>{motText}</span>
                    </div>
                </div>
            </div>
        </div>
    </>)
};

export default PreviewStatusBadges;
import React, { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'services/redux-store';

import { IVehicleImage, setIsGalleryOpen } from '../buyer-vendor.slice';
import GalleryThumbnailList from './GalleryThumbnailList';
import classes from './vehicle-gallery.module.css';

interface IVehicleGalleryProps {
    vehicleImages: IVehicleImage[];
}

const VehicleGallery: FC<IVehicleGalleryProps> = ({ vehicleImages }) => {
    const dispatch: AppDispatch = useDispatch();

    const hideGallery = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        dispatch(setIsGalleryOpen(false));
    };

    const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

    const handleImageChange = (e: MouseEvent<HTMLAnchorElement>, index: number) => {
        e.preventDefault();

        setCurrentImageIndex(index);
    };

    const totalImages = vehicleImages?.length ?? 0;

    const nextImage = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setCurrentImageIndex((imageIndex) => (imageIndex + 1) % totalImages);
    };

    const previousImage = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        setCurrentImageIndex((imageIndex) => {
            let nextValue = imageIndex - 1;

            if (nextValue < 0)
                nextValue = totalImages - 1;

            return nextValue;
        });
    };

    return (
        <div className={classes['lightbox']} id="gallery">
            <div className={classes['contents']}>
                <div className={classes['gallery-carousel']}>
                    <div className={classes['slides']}>
                        <div className={classes['track']}>
                            <a href="#" onClick={hideGallery} title="Close" className={classes['close']}>
                                Close
                            </a>
                            <div>
                                <div className={classes['description']}>{`${vehicleImages[currentImageIndex].photoCaption}`}</div>
                                <img className={classes['slide']} src={vehicleImages[currentImageIndex].lightboxImageUrl} />
                            </div>
                            {/* GTM: carousel-page-trigger class required for GA. */}
                            <a href="#" title="Prev Photo" onClick={previousImage} className={`${classes['prev']} ${classes['arrow']} carousel-page-trigger`}></a>
                            <a href="#" title="Next Photo" onClick={nextImage} className={`${classes['next']} ${classes['arrow']} carousel-page-trigger`}></a>
                        </div>
                    </div>
                    <GalleryThumbnailList vehicleImages={vehicleImages} currentImageIndex={currentImageIndex} handleImageChange={handleImageChange} />
                </div>
            </div>
        </div>
    );
};

export default VehicleGallery;

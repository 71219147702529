import React, { FC, MouseEvent } from 'react';

import classes from './vehicle-gallery.module.css';

interface IGalleryThumbnail {
    currentImageIndex: number;
    imageUrl: string;
    index: number;
    title: string;
    handleImageChange: (e: MouseEvent<HTMLAnchorElement>, index: number) => void;
}

const GalleryThumbnail: FC<IGalleryThumbnail> = ({ handleImageChange, index, imageUrl, title }) => {
    return (
        /*GTM: carousel-thumbnail class required for GA. */
        <a href="#" onClick={(event) => handleImageChange(event, index)} title={title} className="carousel-thumbnail">
            <img src={imageUrl} className={classes['thumbnail']} />
        </a>
    );
};

export default GalleryThumbnail;

import React, { FC, ReactElement } from 'react';

import classes from './sale-preview.module.scss';

interface ISalePreviewLotTitle {
    lotNumber: string;
    lotName: string;
}

const SalePreviewLotTitle: FC<ISalePreviewLotTitle> = ({ lotNumber, lotName }) => {
    return <p className={classes['title-2']}>Lot {lotNumber}: {lotName} </p>;
};

export default SalePreviewLotTitle;
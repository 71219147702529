import { useHandleLoginSubmit, useHandleRedirectLogin, useIsRedirectFromWebsite } from 'Pages/Account/account.service';
import React, { FC, useState } from 'react';

import classes from './account.module.scss';
import AuthenticateRedirect from './Views/AuthenticateRedirect.view';
import Login from './Views/Login.view';

const Account: FC = () => {
    const [isRedirectFromWebsite, setIsRedirectFromWebsite] = useState(false);

    const { user_email, query } = useIsRedirectFromWebsite(setIsRedirectFromWebsite);

    const handleLoginSubmit = useHandleLoginSubmit();

    const handleLoginRedirect = useHandleRedirectLogin(query, setIsRedirectFromWebsite);

    return (
        <>
            <div className={`${classes['content-overlay']}`} id="login-overlay">
                <div className={`${classes.inset} ${classes.box}`}>
                    {isRedirectFromWebsite ? (
                        <AuthenticateRedirect email={decodeURIComponent(user_email)} sessionId={query.get('sessionId') || ''} handleLoginRedirect={handleLoginRedirect} />
                    ) : (
                        <Login handleLoginSubmit={handleLoginSubmit} />
                    )}
                </div>

            </div>
        </>
    );
};

export default Account;

import React, { FC } from 'react';

import classes from './logout-button.module.scss';

interface ILogoutButton {
    handleOpenLogoutDialog?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const LogoutButton: FC<ILogoutButton> = ({ handleOpenLogoutDialog }) => {

    {/*   GTM: logout-trigger class required for GA. */ }

    return (
        <div className={classes['logout']}>
            <a href="#" onClick={handleOpenLogoutDialog} className={`logout-trigger ${classes['icon']}`} title="Logout">
                Logout
            </a>
        </div>

    )

}

export default LogoutButton;
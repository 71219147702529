import Grade from 'Common/Grade/Grade';
import { DateHelper } from 'Common/Helpers/DateHelper';
import { LotDisplayHelpers } from 'Common/Helpers/LotDisplayHelpers';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from './upcoming-vehicles.module.scss';

interface IUpcomingVehicleEntryProps {
    lot: IApiLotViewModel;
}

const UpcomingVehicleEntry: FC<IUpcomingVehicleEntryProps> = ({ lot }) => {
    const { mainSale: { sale = {} } = {} } = useSelector((state: AppState) => state);

    const auctionName = sale?.branch || '';

    return (
        /*GTM: gtm-auction class and gtm attributes required for GA. */
        <div
            className={`gtm-auction ${classes['auction-preview']}`}
            data-gtm-auction-name={auctionName}
            data-gtm-vehicle-name={lot.make ?? ''}
            data-gtm-vehicle-reg={lot.regNo ?? ''}>
            <p className={classes['title-2']}>
                {lot?.lot} - {LotDisplayHelpers.makeModelDerivativeDisplay(lot, true)}
            </p>
            <ul className={classes['stats']}>
                <li className={classes['stat']}>{lot?.bodyType}</li>
                <li className={classes['stat']}>{DateHelper.ToDateString(lot?.regDate ?? '')}</li>
                <li className={classes['stat']}>{lot?.regDate ? DateHelper.GetYearFromDate(lot?.regDate) : ''}</li>
                <li className={classes['stat']}>
                    {lot?.mileage} {lot?.mileageText}
                </li>
                <li className={`${classes['stat']} ${classes['no-sep']}`}>{lot.grade ? <Grade grade={lot?.grade} /> : null}</li>
            </ul>
            <div className={classes['cells']}>
                {lot.inspectionURL && (
                    <div className={classes['cell']}>
                        <InspectionReportLink variant="medium" linkUrl={lot.inspectionURL} text="Inspection report" />
                    </div>
                )}
                {/* "Add to watchlist" isn't a MVP feature. Expect in the near future. */}
                {/*<div className={`${classes['cell']} ${classes['d-none']}`}>*/}
                {/*    <a href="#" title="Add to watchlist" className="icon-text watchlist inactive">*/}
                {/*        Add to watchlist*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default UpcomingVehicleEntry;

import React, { FC } from 'react';

import classes from '../auction-control.module.scss';

export interface IReserveStatus {
    showReserve: boolean;
    reserve: string | undefined;
}

const ReserveStatus: FC<IReserveStatus> = ({ showReserve = false, reserve = '' }) => {
    return <p className={classes.reserve}>{showReserve && <span className={classes.label}>Reserve {reserve || ''}</span>}</p>;
};

export default ReserveStatus;

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { ActiveAndManheimBidders, activeAndManheimBiddersSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import classes from '../auction-control.module.scss';
import { useShowActiveBiddersCount } from '../Hooks/useShowActiveBiddersCount.hook';

export interface IActiveBidders {
    refNum: string;
}

const ActiveBidders: FC<IActiveBidders> = ({ refNum }) => {

    const isShowActiveBiddersCount = useShowActiveBiddersCount(refNum);

    const { activeBidders = [] } = useSelector((state: AppState) => activeAndManheimBiddersSelectors.selectById(state.auctions.activeAndManheimBidders, refNum) || {} as ActiveAndManheimBidders);

    return (
        <span>Active: <b>{isShowActiveBiddersCount ? activeBidders.length : "N/A"}</b></span>
    );
};

export default ActiveBidders;

import React, { FC, ReactElement } from 'react';

import classes from './data-table.module.scss';

interface IDataTableColumns {
    children: ReactElement | ReactElement[];
}

const DataTableColumns: FC<IDataTableColumns> = ({ children }) => {
    return (
        <div className={classes['columns']}>
            {children}
        </div>

    );
};

export default DataTableColumns;

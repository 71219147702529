import { useLogoutUser } from 'Pages/Account/account.service';
import React, { Dispatch, FC, SetStateAction } from 'react';

import { useDialogManagerContext } from '../../DialogManagerProvider';
import DialogContainer from '../DialogContainer/DialogContainer';
import classes from './logout-dialog.module.scss';

interface ILogoutDialog 
{
    setShowLogoutDialog: Dispatch<SetStateAction<boolean>>;
    showLogoutDialog: boolean;
}

const surveyLink = process.env.REACT_APP_EXIT_SURVEY_LINK || '';

const LogoutDialog: FC<ILogoutDialog> = ({ showLogoutDialog }) => {
    const handleLogout = useLogoutUser();
    const { hideModal } = useDialogManagerContext();

    const handleLogoutOnClick = async () => 
    {
        await handleLogout();
        hideModal();

        if (surveyLink)
            window.location.href = surveyLink;
    };

    const handleCloseDialog = () => hideModal();

    return (
        <DialogContainer isOpen={showLogoutDialog} handleClose={handleCloseDialog}>
            <h2>Close Simulcast</h2>
            <div className={classes['container']}>
                <p>Are you sure you want to close all auctions and exit Simulcast?</p>
                {/*   GTM:  "logout-trigger" class required for GA. */}
                <button className={`logout-trigger ${classes['logout-btn']}`} onClick={handleLogoutOnClick}>
                    Yes
                </button>
                <button className={classes['cancel-btn']} onClick={handleCloseDialog}>
                    No
                </button>
            </div>
        </DialogContainer>
    );
};

export default LogoutDialog;

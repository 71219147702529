export enum BidHistoryMessageTypes {
    NOT_STARTED = 0,
    LOT_CHANGED = 1,
    BID_UPDATED = 2,
    BID_UPDATED_FOR = 3,
    BID_REJECTED = 4,
    BIDDING_CLOSED = 5,
    BIDDING_PAUSED = 6,
    BIDDING_RESUMED = 7,
    BID_RETRACTED = 8,
    LOT_SOLD = 9,
    LOT_UNSOLD = 10,
    LOT_SOLD_PROVISIONAL = 11,
    AUCTIONEER_MESSAGE = 12,
    MESSAGE_ANNOUNCEMENT = 13,
    MESSAGE_REMARK = 14,
    MESSAGE_MILEAGE = 15,
    MESSAGE_BLOCK = 16,
    LOT_RETRACTED = 17,
    MESSAGE_UNBLOCK = 18,
    WARNING_1 = 19,
    WARNING_2 = 20,
    WARNING_3 = 21,
    NOT_ACTIVE_SALE = 22,
    OTHER = 23
}

import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import React, { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { browser_local } from 'services/browser-storage';
import { AppState } from 'services/redux-store';

import VideoIconButton from './Components/VideoIconButton';
import classes from './video-player-container.module.css';
import VideoDisplayView from './Views/VideoDisplay.view';

interface IVideoPlayer {
    refNum: string;
    videoUrl: string;
    vehicleImages: any[];
    videoPlayerClass: VideoPlayerType;
}

export enum VideoPlayerType {
    MAIN_SALE = 'shadow-1',
    PREVIEW_SALE = 'preview',
    MOBILE_SALE = ''
}

const VideoPlayer: FC<IVideoPlayer> = ({ refNum, videoUrl, vehicleImages = [], videoPlayerClass = VideoPlayerType.MAIN_SALE }) => {
    const { sale } = useSelector((state: AppState) => state.mainSale);
    const isMobile = useIsMobile();
    const [isMuted, setIsMuted] = useState(true);
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
        const showVideoCached = browser_local.get(`showVideo_${refNum}`);
        //if (showVideoCached === undefined || showVideoCached === null) handleSetShowVideo(!isMobile);
        //else setShowVideo(showVideoCached === 'true' && !isMobile);
        if (showVideoCached === undefined || showVideoCached === null)
            handleSetShowVideo(true);
        else
            setShowVideo(showVideoCached === 'true');
    }, [refNum]);

    useEffect(() => {

        if (sale && sale.refNum === refNum) {
            handleSetIsMuted(false);
        }
        else {
            handleSetIsMuted(true);
        }
    }, [refNum]);


    const toggleVideoMuteOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        handleSetIsMuted(!isMuted);
    };

    const handleSetIsMuted = (toMuteOrNotToMute: boolean) => {
        setIsMuted(toMuteOrNotToMute);
    };

    const showVideoOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        handleSetShowVideo(true);
    };

    const showImageOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        handleSetShowVideo(false);
    };

    const handleSetShowVideo = (toShowOrNotToShow: boolean) => {
        browser_local.set(`showVideo_${refNum}`, `${toShowOrNotToShow}`);

        setShowVideo(toShowOrNotToShow);
    };

    return (
        <div key={videoUrl} className={`${classes['auction-media']} ${classes[videoPlayerClass]}`}>
            <VideoDisplayView
                refNum={refNum}
                isMuted={isMuted}
                setIsMuted={handleSetIsMuted}
                vehicleImages={vehicleImages}
                showVideo={showVideo}
                videoUrl={videoUrl}
                isMobile={isMobile}>
                <div className={classes['auction-media-controls']}>
                    {/*   GTM: toggle-video-trigger,toggle-image-trigger and toggle-audio-trigger classes required for GA. */}
                    <VideoIconButton gtmClassName="gtm-toggle-image-trigger" icon="picture" handleOnClick={showImageOnClick} isActive={!showVideo} />
                    <VideoIconButton gtmClassName="gtm-toggle-audio-trigger" icon="sound" handleOnClick={toggleVideoMuteOnClick} isActive={!isMuted} />
                    <VideoIconButton gtmClassName="gtm-toggle-video-trigger" icon="video" handleOnClick={showVideoOnClick} isActive={showVideo} />
                </div>
            </VideoDisplayView>
        </div>
    );
};

export default VideoPlayer;

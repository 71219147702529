import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import React from 'react';
import ReduxToastr from 'react-redux-toastr';

const ToastAlert = () => {
    return (
        <>
            <ReduxToastr timeOut={6000} preventDuplicates position="top-left" transitionIn="fadeIn" transitionOut="fadeOut" closeOnToastrClick progressBar />
        </>
    );
};

export default ToastAlert;

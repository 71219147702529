import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

export const useAuthenticationStatus = (): boolean => 
{
    const { account: { bearerToken: token, clientLoginInfo } = {} } = useSelector((state: AppState) => state);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => setIsLoggedIn(token && clientLoginInfo ? true : false), [token, clientLoginInfo]);

    return isLoggedIn;
};

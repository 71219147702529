/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RTC } from 'phenix-web-sdk';

import { JoinedChannel } from './Entities/joined-channel.entity';
import { JoinChannelOptions } from './Entities/media-stream.entity';
import { StreamStatus } from './Enums/stream-status.enum';

export const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
export const isMobileAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
export const isOtherMobile = /Android|webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
export const isAndroid = /Android/.test(navigator.userAgent);
export const isIpadVersion13Plus = navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 4;
export const iosVersion =
    parseFloat(
        ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
            .replace('undefined', '3_2')
            .replace('_', '.')
            .replace('_', '')
    ) || 0;

export const joinChannelCurried = (joinedChannel: JoinedChannel) => {
    return (error: Error, response: any): void => {
        if (error) {
            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.JOIN_ERROR })
            );
            return;
        }

        if (response.status !== 'ok') {
            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.BAD_JOIN_STATUS })
            );
            return;
        }

        // Successfully joined channel
        if (response.status === 'ok' && response.channelService) {
            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.JOIN_SUCCESS })
            );
        }
    };
};

export const subscribeChannelCurried = (
    joinedChannel: JoinedChannel,
    addMediaStream: (alias: string, stream: any, videoId?: string) => void,
    removeMediaStream: (alias: string) => void,
    disposeChannelExpressObjectOnStreamRejoin: (joinedChannel: JoinChannelOptions) => void
) => {
    return (error: Error, response: any): void => {
        joinedChannel.disposables.forEach((d) => d.dispose());
        joinedChannel.disposables.length = 0;

        if (error) {
            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.SUBSCRIBE_ERROR })
            );
        }
  
        if (response.status === 'no-stream-playing') {
            removeMediaStream(joinedChannel.alias);
           return;
        } else if (response.status === 'stream-ended') {
            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.STREAM_ENDED })
            );
            return;
        }
        if (response.mediaStream) {

            response.mediaStream.on('ended', (reason: string) => { 
                joinedChannel.videoElement?.dispatchEvent(
                    new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.STREAM_ENDED })
                );
            });
            if (joinedChannel.videoElement?.isConnected) {

                addMediaStream(joinedChannel.alias, response.mediaStream, joinedChannel.videoElement?.id);
            }
            else {
                removeMediaStream(joinedChannel.alias);
                response.mediaStream.stop();
                disposeChannelExpressObjectOnStreamRejoin({ refNum: joinedChannel.refNum, videoElement: joinedChannel.videoElement, alias: joinedChannel.alias } as JoinChannelOptions);
            }
        }
        if (response.renderer) {
            joinedChannel.disposables.push(
                response.renderer.on('autoMuted', function handleAutoMuted() {
                    joinedChannel.isAutoMuted = true;
                })
            );

            joinedChannel.disposables.push(
                response.renderer.on('failedToPlay', function handleFailedToPlay(reason: string) {
                    joinedChannel.videoElement?.dispatchEvent(
                        new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.FAILED_TO_PLAY })
                    );
                    window.logger.info('FAILED_TO_PALY', reason);
                })
            );

            joinedChannel.disposables.push(
                response.renderer.on('ended', function handleEnded(reason: string) { 
                    joinedChannel.videoElement?.dispatchEvent(
                        new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.NO_STREAM_PLAYING })
                    );
                })
            );
        }

        if (isMobile) {
            joinedChannel.isAutoMuted = (isMobileAppleDevice || isOtherMobile) && !RTC.webrtcSupported;
        }

        if (response.status === 'ok') {

            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.STREAM_IS_PLAYING })
            );
        } else {
            joinedChannel.videoElement?.dispatchEvent(
                new CustomEvent<StreamStatus>('channel_status', { bubbles: true, detail: StreamStatus.BAD_SUBSCRIBE_STATUS })
            );
        }
    };
};

import MinMaxButton from 'Common/MinMaxButton/MinMaxButton';
import React, { FC } from 'react';

import classes from './auction-sale-header.module.scss';

interface AuctionSaleHeader {
    isTabletMode?: boolean;
    isFlyoutOpen?: boolean;
    handleToggleFlyoutMinMax?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
    hideMobileSalesList?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const AuctionSaleHeader: FC<AuctionSaleHeader> = ({
    isTabletMode,
    isFlyoutOpen = true,
    handleToggleFlyoutMinMax,
    hideMobileSalesList
}) => {

    return (
        <>
            <div className={isFlyoutOpen ? `${classes['header']}` : ` ${classes['header']} ${classes['minimised']}`}>
                <a title="Close" className={classes['close']} onClick={hideMobileSalesList}></a>
                <div className={classes['cells']}>
                    <div className={`${classes['title']} ${classes['cell']}`}>
                        <span className={`${classes['mobile-header-title']}`}>Select Sale</span>
                        <span className={`${classes['header-title']}`}>{isFlyoutOpen ? 'Enter More Sales' : 'Sales'}</span>
                    </div>
                    <div className={`${classes['icons']} ${classes['cell']}`}>
                        {!isTabletMode && <MinMaxButton isMinimised={!isFlyoutOpen} color={'white'} onClick={handleToggleFlyoutMinMax} 
                            gtmClass={`gtm-sales-${isFlyoutOpen ? 'minimise' : 'maximise'}-trigger`} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AuctionSaleHeader;

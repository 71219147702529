import './_utilities.scss';
import './simulcast.css';

import ErrorBoundary from 'Common/ErrorBoundary/ErrorBoundary';
import Layout from 'Common/Layout/Layout.container';
import NotFound from 'Common/NotFound/NotFound';
import { setUserRole } from 'Infrastructure/UserAccount/user-account.slice';
import { useUserAccountContext } from 'Infrastructure/UserAccount/UserAccountProvider';
import Account from 'Pages/Account/Account.container';
import {
    isClientLoginInfoBuyerOrLooker,
    isClientLoginInfoSuperUser,
    isClientLoginInfoVendor,
    isUrlRedirectBuyerOrLooker,
    isUrlRedirectVendor,
    useHasUserPreviouslyLoggedIn,
    useLogoutUser
} from 'Pages/Account/account.service';
import UserIsLooker from 'Pages/Account/Components/UserIsLooker';
import BuyerVendorContainer from 'Pages/BuyerVendorContainer/BuyerVendor.container';
import OrientationLandscapeError from 'Pages/OrientationError/OrientationLandscapeError';
import SuperUserRoute from 'Pages/Routes/SuperUserRoute';
import React, { FC, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { browser_local, browser_session } from 'services/browser-storage';
import { AppDispatch, AppState } from 'services/redux-store';

import isLandscape from './Common/Helpers/OrientationCheck';
import SelectUserRole from './Pages/SelectRole/SelectUserRole';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';

const App: FC = () => {
    useHasUserPreviouslyLoggedIn();

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn } = useUserAccountContext();
    const { clientLoginInfo, isLooker } = useSelector((state: AppState) => state.account);
    const [isTnCAccepted, setIsTnCAccepted] = useState(false);
    const handleLogout = useLogoutUser();
    const [isLandscapeMode, setLandscapeMode] = useState(isLandscape());

    useEffect(() => {
        // get last login timestamp
        const loggedInTimestamp = browser_local.get('__timestamp');

        // if last login was longer than 1 day (1000 * 60  * 60 * 24)
        if (!loggedInTimestamp || Date.now() - parseInt(loggedInTimestamp) > 86400000) {
            // user is still logged in
            if (isLoggedIn && clientLoginInfo?.Username) {
                // logout the user
                handleLogout();
            }
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (!isLoggedIn) {
            return;
        }

        //TODOxMF: Fix magic strings/maybe pull into service
        const _session = browser_session.get('__session');
        const queryParams = new URLSearchParams(_session ?? '');

        if (!isTnCAccepted && !browser_session.get('__TnC')) {
            navigate('/');

            return;
        }

        if (window.location.pathname !== '/') {
            if (window.location.pathname === '/buyer' && (isClientLoginInfoBuyerOrLooker(clientLoginInfo) ||
                isClientLoginInfoSuperUser(clientLoginInfo))) {
                dispatch(setUserRole({ userRole: 'buyer' }));
            } else if (window.location.pathname === '/vendor' && (isClientLoginInfoVendor(clientLoginInfo) ||
                isClientLoginInfoSuperUser(clientLoginInfo))) {
                dispatch(setUserRole({ userRole: 'vendor' }));
            }

            return;
        }

        if (isClientLoginInfoBuyerOrLooker(clientLoginInfo) || isUrlRedirectBuyerOrLooker(queryParams)) {
            dispatch(setUserRole({ userRole: 'buyer' }));

            navigate('/buyer');
        } else if (isClientLoginInfoVendor(clientLoginInfo) || isUrlRedirectVendor(queryParams)) {
            dispatch(setUserRole({ userRole: 'vendor' }));

            navigate('/vendor');
        } else if (isClientLoginInfoSuperUser(clientLoginInfo)) {
            navigate('/SelectApp');
        }
    }, [clientLoginInfo, isTnCAccepted, isLoggedIn]);

    useEffect(() => {
        if (!isMobileOnly) return;
        let clearTime: ReturnType<typeof setTimeout>;

        const handleOrientation = () => {
            clearTime = setTimeout(() => {
                setLandscapeMode(isLandscape());
            }, 250);
        };

        window.addEventListener('resize', handleOrientation);

        return () => {
            if (clearTime) clearTimeout(clearTime);
            window.removeEventListener('resize', handleOrientation);
        };
    }, []);

    if (isMobileOnly && isLandscapeMode && window.innerWidth <= 835) {
        return <OrientationLandscapeError />;
    }
    else if (isLoggedIn) {
        return (
            <ErrorBoundary>
                <Layout>
                    <Routes>
                        <Route path="/" element={<TermsAndConditions handleAcceptReject={setIsTnCAccepted} />} />
                        <Route path="/vendor" element={<BuyerVendorContainer />} />
                        <Route path="/buyer" element={<BuyerVendorContainer />} />
                        <Route element={<SuperUserRoute />}>
                            <Route path="/SelectApp" element={<SelectUserRole />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <UserIsLooker isLooker={isLooker} />
                </Layout>
            </ErrorBoundary>
        );
    }
    else {
        let redirectToPath = '/';

        if (browser_session.get('__token')) {
            redirectToPath = window.location.pathname;
        }

        return (
            <>
                {/* Navigation/Header?: */}
                <Routes>
                    <Route path="/" element={<Account />} />
                    <Route path="/account/reauth/:user_email" element={<Account />} />
                    <Route path="*" element={<Navigate to={redirectToPath} />} />
                </Routes>
            </>
        );
    }
};

export default App;

import React, { RefObject, useEffect } from 'react';

import { StreamStatus } from '../Enums/stream-status.enum';
import { isMobileAppleDevice, isOtherMobile } from '../phenix-sdk.extensions';
import { usePhenixWebRtcContext } from '../PhenixWebRtcContext/PhenixWebRtcProvider';

export const useMutedState = (videoUrl: string, videoRef: RefObject<HTMLVideoElement>, state: string, isMuted: boolean, setIsMuted: (muted: boolean) => void): void => {
    const { joinedChannels } = usePhenixWebRtcContext();
    const channel = joinedChannels.find((c) => c.alias === videoUrl && c.videoElement?.id === videoRef.current?.id);
    const isAutoMuted = channel?.isAutoMuted !== undefined && channel?.isAutoMuted;

    useEffect(() => {
        if (state !== StreamStatus.STREAM_IS_PLAYING) return;      

        if ((isMobileAppleDevice || isOtherMobile) && videoRef.current) { 
            videoRef.current.muted = isMuted ? true : isAutoMuted;
            videoRef.current.setAttribute('muted', `${isMuted ? true : isAutoMuted}`);
            setIsMuted(isMuted ? true : isAutoMuted);

        } else if (channel && videoRef.current) { 
            videoRef.current.muted = isMuted ? true : isAutoMuted;
            videoRef.current.setAttribute('muted', `${isMuted ? true : isAutoMuted}`);
            setIsMuted(isMuted ? true : isAutoMuted);
        }
    }, [state, channel]);
};

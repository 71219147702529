import React, { FC, ReactElement } from 'react';

import classes from '../auction-control.module.scss';
import { ICurrentBidStatus } from '../Components/CurrentBidStatus';
import { IOnSaleStatus } from '../Components/OnSaleStatus';
import { IReserveStatus } from '../Components/ReserveStatus';

interface IAuctionStats {
    children: ReactElement<IReserveStatus | ICurrentBidStatus | IOnSaleStatus> | ReactElement<IReserveStatus | ICurrentBidStatus | IOnSaleStatus>[];
}

const AuctionStats: FC<IAuctionStats> = ({ children }) => {
    return (
        <>
            <div className={classes['stats']}>{children}</div>
        </>
    );
};

export default AuctionStats;

import React, { FC, ReactElement } from 'react';

import classes from './data-table.module.scss';

interface IDataTableColumn {
    children: ReactElement;
}

const DataTableColumn: FC<IDataTableColumn> = ({ children }) => {
    return (
        <div className={classes['column']}>
            {children}
        </div>

    );
};

export default DataTableColumn;

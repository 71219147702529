import { DateHelper } from 'Common/Helpers/DateHelper';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { createLotStatusBadges, getMOTDisplayText } from 'Common/LightStatusBadge/status-badge.service';
import moment from 'moment';
import React, { FC } from 'react';

import NotifyBadgeList from '../../../../Common/NotifyBadgeIcon/NotifyBadgeList';
import { IApiLotViewModel } from '../../../../Common/Sales/Sales.interface';
import classes from './auction-header.module.scss';

interface ILotHeaderLights {
    checkpoint: number;
    v5: boolean;
    surecheck: boolean;
    surecheckText: string;
    sureCheckResult: string;
    announcement: string;
    remarks: string;
    watching: boolean;
    lotDetails: IApiLotViewModel;
    isShowVendorOnline: boolean;
    isVendorOnline?: boolean;
}

const LotHeaderLights: FC<ILotHeaderLights> = ({
    checkpoint, v5, surecheck, surecheckText, sureCheckResult, isVendorOnline, announcement, remarks, watching, lotDetails, isShowVendorOnline }) => {

    const isMobile = useIsMobile();

    const statusBadges = createLotStatusBadges(v5, surecheck, surecheckText, sureCheckResult, checkpoint, isMobile);

    const motText = getMOTDisplayText(lotDetails);

    return (
        <div className={`${classes['lot-header-lights']} ${classes['cells']}`}>
            <div>
                {isShowVendorOnline && (
                    <div className={classes['cell']}>
                        <div className={`${classes['vendor-logo']} ${isVendorOnline ? classes['online'] : classes['offline']}`} />
                    </div>
                )}
                {statusBadges.map((obj, i) => (
                    <div key={i} className={classes['cell']}>
                        {obj}
                    </div>
                ))}
                <div className={classes['cell']}>
                    <div className={`${classes['mot-icon']}`}>
                        <span className={`${classes['mot-icon-positive']}`}>{motText}</span>
                    </div>
                </div>
                {(lotDetails?.grade && lotDetails.grade != '0') && (<div className={classes['cell']}>
                    <div className={`${classes['grade-icon']}`}>
                        <span className={`${classes['grade-icon-' + lotDetails?.grade]}`}>{isMobile ? '' : 'Grade'}</span>
                    </div>
                </div>)}
            </div>
            {!isMobile && (<NotifyBadgeList announcement={announcement} remarks={remarks} watching={watching} />)}
            <div className={classes['vendor-section']}>
                <div className={classes['vendor-online']}>
                    <div className={`${isVendorOnline ? classes['active'] : ''} ${classes['online-status']}`}>Vendor online</div>

                </div>

            </div>
        </div>
    );
};

export default LotHeaderLights;
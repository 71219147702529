import React, { FC, MouseEvent } from 'react';

import classes from '../auction-control.module.scss';

interface IBidIntentButton {
    title: string;
    colour: string;
    trigger: string;
    isDisabled: boolean;
    onClick(e: MouseEvent<HTMLAnchorElement>): void;
}

const BidIntentButton: FC<IBidIntentButton> = ({ title = '', colour = '', trigger = '', isDisabled = false, onClick }) => {
    return (
        <a
            onClick={onClick}
            title={title}
            className={`gtm-bid-intent ${classes.lozenge} ${classes.button} ${classes['bid-intent']} ${trigger} ${isDisabled ? classes.disabled : classes[colour]}`}>
            {title}
        </a>
    );
};

export default BidIntentButton;

import React, { FC, ReactElement } from 'react';

import classes from './accordion.module.scss';

export interface IAccordionTitle {
    title?: string;
    children: ReactElement[];
}

const AccordionTitle: FC<IAccordionTitle> = ({ title = '', children }) => {
    return (
        /*GTM: gtm-accordion-expand-trigger class and title required for GA. */
        <div title={title} className={`gtm-accordion-expand-trigger ${classes['header-bar']}`}>
            {children}
        </div>
    );
};

export default AccordionTitle;

import React, { FC, ReactElement } from 'react';

import className from './list-table.module.scss';

interface IListTableCell {
    classes?: 'halign-right' | 'halign-center' | 'column-height';
    textColorClassVal1?: string;
    textColorClassVal2?: string;
    value1: string | ReactElement;
    value2?: string | ReactElement;
}

const ListTableCell: FC<IListTableCell> = ({ classes, textColorClassVal1, textColorClassVal2, value1, value2 }) => {

    return (
        <div className={`${className['col']} ${classes ? className[classes] : ''}`}>
            <div className={`${className['value-1']} ${className['multiline-ellipsis']} ${textColorClassVal1 ? className[textColorClassVal1] : ''}`}>{value1}</div>
            {value2 ? <div className={`${className['value-2']} ${textColorClassVal2 ? className[textColorClassVal2] : ''}`}>{value2}</div> : null}
        </div>
    );
};

export default ListTableCell;

import { HubConnectionState } from '@microsoft/signalr';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import classes from '../navigation-header.module.scss';

interface IReconnectButton {
    restartConnection: () => void;
    connectionState: HubConnectionState;
}

const ReconnectButton: FC<IReconnectButton> = ({ connectionState, restartConnection }) => {

    const { userRole } = useSelector((state: AppState) => state.account);  

    if (connectionState === HubConnectionState.Disconnected && userRole !== "") {
        return (
            <button onClick={() => restartConnection()} className={`${classes['btn-reconnect']} ${classes['cls-reconnect-button']}`}>
                <span className={`material-icons ${classes.icon}`}>person</span>
                <p>Reconnect</p>
            </button>
        );
    }

    return null;
};

export default ReconnectButton;

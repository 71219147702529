import { createSlice } from '@reduxjs/toolkit';

export enum ChatMessageType {
    operator = 'operator',
    caller = 'caller'
}

export type ChatMessage = {
    messageType: ChatMessageType;
    text: string;
};

export type ChatMessageValuePair = Record<string, Array<ChatMessage>>

export type UnreadValuePair = Record<string, number>;

interface IChatState {
    unreadMessageCount: UnreadValuePair;
    messages: ChatMessageValuePair;
    prevInputValue: string
}

const initialState: IChatState = {
    unreadMessageCount: {},
    messages: {},
    prevInputValue: ''
};

export const chatMessagingSlice = createSlice({
    name: 'chatMessaging',
    initialState,
    reducers: {
        addUserMessage: {
            reducer: (state: IChatState, { payload }: {
                payload: {
                    refNum: string; text: string; messageType: ChatMessageType
                }
            }) => {

                const { refNum, text, messageType } = payload;

                if (!state.messages[refNum]) {
                    state.messages[refNum] = [];
                }

                state.messages[payload.refNum].push({ text, messageType });

            },
            prepare: (data) => {
                const { refNum, message } = data;

                const payload = {
                    refNum: refNum,
                    text: message,
                    messageType: ChatMessageType.caller
                };

                return { payload };
            }
        },
        addAuctioneerMessage: {
            reducer: (state: IChatState, { payload }: { payload: { refNum: string; text: string; messageType: ChatMessageType } }) => {
                const { refNum, text, messageType } = payload;

                if (refNum) {
                    if (!state.messages[refNum]) {
                        state.messages[refNum] = [];
                    }

                    state.messages[refNum].push({
                        text,
                        messageType
                    });

                    if (!state.unreadMessageCount[refNum]) {
                        state.unreadMessageCount[refNum] = 1;
                    } else {
                        state.unreadMessageCount[refNum]++;
                    }
                }

                return state;
            },
            prepare: (data) => {
                const { refNum, incoming, message } = data;

                const payload = {
                    refNum,
                    text: message,
                    messageType: incoming ? ChatMessageType.operator : ChatMessageType.caller
                };

                return { payload };
            }
        },
        setUnreadMessageCount: (state: IChatState, { payload }: { payload: { refNum: string, value: number } }) => {
            state.unreadMessageCount[payload.refNum] = payload.value;
        },
        setInputBoxValue: (state: IChatState, { payload }: { payload: string }) => {
            state.prevInputValue = payload;
        }
    }
});

export const { addUserMessage, addAuctioneerMessage, setUnreadMessageCount, setInputBoxValue } = chatMessagingSlice.actions;

export default chatMessagingSlice.reducer;

import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import { useDispatch } from 'react-redux';

import { setIsOnSale } from '../bidding-state.slice';

export const useClickToSell = (): ((bid: number, lot: string, siteId: string, refNum: string, buyerNumber: string) => void) => {
    const { sendSocketMessage } = useWebSocketConnection();
    const dispatch = useDispatch();

    const handleClickToSell = (bid: number, lot: string, siteId: string, refNum: string, buyerNumber: string) => {
        dispatch(setIsOnSale({ refNum, isOnSale: true }));
        sendSocketMessage('SellItem', [bid, lot, siteId, refNum, buyerNumber])
            .then(() => {
               /* */
            })
            .catch((error) => {
                window.logger.error(error);
                dispatch(setIsOnSale({ refNum, isOnSale: false }));
            });
    };

    return handleClickToSell;
};

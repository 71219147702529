import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Dispatch, SetStateAction, useEffect } from 'react';

export const useStartSignalRConnection = (
    connection: HubConnection | undefined,
    setConnectionStatus: Dispatch<SetStateAction<HubConnectionState>>
): (() => Promise<void>) => {
    const startSignalR = async () => {
        if (connection && connection.state === HubConnectionState.Disconnected) {
            connection
                .start()
                .then(() => setConnectionStatus(connection.state))
                .catch((error: unknown) => {
                    window.logger.error('SignalR Start Error', error);
                    setConnectionStatus(HubConnectionState.Disconnected);
                });
        }
    };

    useEffect(() => {
        startSignalR();

        return () => {
            connection?.stop().then(() => window.logger.info('stopped'));
        };
    }, [connection]);

    return startSignalR;
};

import React from 'react';

import classes from '../account.module.scss';

const Promo = () => {
    return (
        <div className={classes.promo}>
            <h3 className={classes.title}>The UK{"'"}s most popular wholesale vehicle marketplace</h3>
            <a href="#" className={`${classes.lozenge} ${classes.cta}`} title="Find out more">
                Find out more about Manheim
            </a>
        </div>
    );
};

export default Promo;

import React, { FC, MouseEvent, ReactElement } from 'react';

import classes from '../auction-control.module.scss';
import AuctionControlButton from '../Components/AuctionControlButton';

interface IUserAuctionControls {
    btnTitle: string;
    btnColour: string;
    btnTrigger: string;
    btnIsDisabled: boolean;
    btnOnClick: (e: MouseEvent<HTMLButtonElement>) => void; 
    children:JSX.Element;
}

const UserAuctionControls: FC<IUserAuctionControls> = ({ btnTitle, btnColour, btnTrigger, btnIsDisabled, btnOnClick, children }) => {
    return (
        <div className={classes['controls']}>
            <AuctionControlButton title={btnTitle} colour={btnColour} trigger={btnTrigger} isDisabled={btnIsDisabled} onClick={btnOnClick} />
            {children}
        </div>
    );
};

export default UserAuctionControls;

import React, { RefObject, useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { StreamStatus } from '../Enums/stream-status.enum';
import { usePhenixWebRtcContext } from '../PhenixWebRtcContext/PhenixWebRtcProvider';

export const useJoinStream = (videoUrl: string, videoRef: RefObject<HTMLVideoElement>, state: string, refNum: string): void => {
    const { joinChannel, joinedChannels } = usePhenixWebRtcContext();
    const channel = joinedChannels.find((c) => c.alias === videoUrl && c.videoElement?.id === videoRef.current?.id);
    const [isVisibilityChange, setVisibilityChange] = useState(false);
    const { disposeChannelExpressObject } = usePhenixWebRtcContext();

    useEffect(() => {
        if (!videoUrl || !videoRef.current || state === StreamStatus.IS_ERRORED || state === StreamStatus.NO_STREAM_PLAYING) return;

        if (!channel && state === StreamStatus.NOT_PLAYING) {
            joinChannel({
                alias: videoUrl,
                videoElement: videoRef.current,
                streamSelectionStrategy: 'high-availability',
                refNum
            });
        }
    }, [videoUrl, videoRef, state, channel]);

    useEffect(() => {
        if (!videoUrl || !videoRef.current) return;

        if (!channel && isVisibilityChange) {
            joinChannel({
                alias: videoUrl,
                videoElement: videoRef.current,
                streamSelectionStrategy: 'high-availability',
                refNum
            });
        }
    }, [isVisibilityChange])

    const handleVisibilityChange = (e: any) => {
        if (document.visibilityState === 'visible') {
            setVisibilityChange(true);
        }
        else if (document.visibilityState === 'hidden') {
            disposeChannelExpressObject(refNum);
            setVisibilityChange(false);
        }
    };

    useEffect(() => {
        if (!(isIOS && isMobile))
            return;
        document.addEventListener('visibilitychange', handleVisibilityChange, false);

        return () => document.removeEventListener('visibilitychange', handleVisibilityChange, false);
    }, []);
};

import { isClientLoginInfoSuperUser, isClientLoginInfoVendor } from 'Pages/Account/account.service';
import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { browser_session } from 'services/browser-storage';
import { AppState } from 'services/redux-store';

const SuperUserRoute = () => {
    const { clientLoginInfo } = useSelector((state: AppState) => state.account);
    
    const navigateTo = browser_session.get('__TnC') ? isClientLoginInfoVendor(clientLoginInfo) ? "/vendor" : "buyer" : "/";

    return (
        isClientLoginInfoSuperUser(clientLoginInfo) ? <Outlet /> : <Navigate to={navigateTo} replace />
    )
}

export default SuperUserRoute;

import React, { FC } from 'react';

import classes from './user-role.module.scss';

interface IUserRole {
    userRole: string;
    releaseVersionNo: string;
}

const UserRole: FC<IUserRole> = ({ userRole, releaseVersionNo }) => {

    return (
        <div className={classes['user-role']}>{userRole + ' ' + releaseVersionNo}</div>
    );
}

export default UserRole;
import React, { FC, ReactElement, useEffect, useState } from 'react';

import classes from './accordion.module.scss';
import { IAccordionContent } from './AccordionContent';
import AccordionTitle, { IAccordionTitle } from './AccordionTitle';

interface IAccordion {
    isActive?: boolean | null;
    onClick?: () => void;
    children: ReactElement<IAccordionTitle | IAccordionContent>[];
    className?: 'overlay-box'
}

const Accordion: FC<IAccordion> = ({ isActive, onClick, children, className }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleIsOpenOnClick = () => {
        if (onClick)
            onClick();

        setIsOpen(!isOpen);

    };

    useEffect(() => {
        if (isActive === null || isActive === undefined) return;

        setIsOpen(isActive);
    }, [isActive]);

    return (
        <div className={`${classes['accordion']} ${className ? classes[className] : ''} ${isOpen ? (classes.expanded + ' gtm-accordion-expanded') : ''}`}>
            {children.map((element, i) => {
                return ((element.type === AccordionTitle) ? <div onClick={handleToggleIsOpenOnClick} key={i}> {element} </div>
                    : isOpen ? element : null)
            })}
        </div>
    );
};

export default Accordion;

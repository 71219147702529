import v5NotPresent from 'img/help/no-V5.svg';
import v5Present from 'img/help/V5-present.svg';
import React, { FC } from 'react';

import classes from "../status-badge.module.scss";

const V5Icon: FC = () => {
    return (
        <div className="content">
            <h3 className={classes['title']}>V5 status</h3>
            <p>
                A vehicle logbook (V5) is a legal document issued by the DVLA, outlining proof of ownership and specific vehicle details. If a V5 is present
                with the vehicle, this is indicated by a grey V5 symbol and the document will be provided free of charge. A red V5 symbol indicates there is no
                V5 present.{' '}
            </p>
            <div className={classes['table']}>
                <div className={classes['row']}>
                    <div className={classes['col']}>
                        <img className={classes['v5-tooltip']} src={v5Present} alt="V5 present" />
                    </div>
                    <div className={classes['col']}>V5 present</div>
                </div>
                <div className={classes['row']}>
                    <div className={classes['col']}>
                        <img className={classes['v5-tooltip']} src={v5NotPresent} alt="V5 not present" />
                    </div>
                    <div className={classes['col']}>V5 not present</div>
                </div>
            </div>
        </div>
    );
};

export default V5Icon;

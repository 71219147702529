import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider';
import React, { FC, FormEvent, MouseEvent, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { JoinedSale, joinedSalesSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import classes from './chat-messaging.module.css';
import {
    useGetMessagesAndCount,
    useInputDisabled,
    useIsOpenClearUnreadMessage,
    useIsOpenContentHeight,
    useMobileChatContentHeight,
    useShowMessagingDisabledToast
} from './chat-messaging.service';
import { addUserMessage } from './chat-messaging.slice';
import ChatMessagesList from './Views/ChatMessagesList.view';
import ChatMessagesSendForm from './Views/ChatMessagesSendForm.view';
import ChatMessagingHeader from './Views/ChatMessagingHeader.view';

interface IUserMessaging {
    refNum: string;
    siteId: string;
    lot: string;
}

const UserMessaging: FC<IUserMessaging> = ({ refNum, siteId = -1, lot = '0' }) => {
    const messagesTitle = 'Messages';
    const siteIdNum = parseInt(`${siteId}`);
    const dispatch: AppDispatch = useDispatch();
    const joinedSalesState = useSelector((state: AppState) => state.auctions.joinedSales);
    const { buyerNumber = '' } = (joinedSalesSelectors.selectById(joinedSalesState, refNum) as JoinedSale) || {};
    const { myMessages, myUnreadMessageCount } = useGetMessagesAndCount(refNum);
    const isMobile = useIsMobile();

    const [isOpen, setIsOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const mobileChatAreaRef = useRef<HTMLDivElement>(null);

    const { sendSocketMessage } = useWebSocketConnection();

    const { isDisabled } = useInputDisabled(refNum, inputRef);

    useShowMessagingDisabledToast(isOpen, isDisabled);

    const contentDivHeight = useIsOpenContentHeight(isOpen);

    const mobileChatContentHeight = useMobileChatContentHeight(mobileChatAreaRef);

    const isGTMEnabled = JSON.stringify(process.env.REACT_APP_ENABLE_IS_GTM) === JSON.stringify("yes");

    useIsOpenClearUnreadMessage(isOpen, refNum, myUnreadMessageCount);

    const handleToggleOpenMessagesOnClick = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();

        setIsOpen(!isOpen);
    };

    const handleSendChatMessage = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const message = inputRef.current?.value;

        if (message) {
            try {
                if (isGTMEnabled) {
                    const tagManagerArgs = {
                        dataLayer: {
                            event: 'chat_message_sent'
                        },
                        dataLayerName: 'dataLayer'
                    };

                    TagManager.dataLayer(tagManagerArgs);
                }

                await sendSocketMessage('MessageController', [{ AuctionNo: refNum, siteId: siteIdNum, buyerNumber, lot, bid: 0, msg: message, partner: '' }]);

                dispatch(
                    addUserMessage({
                        refNum,
                        message
                    })
                );
            } catch (error) {
                window.logger.error(error);

                //toastr.error('Error Sending Message', 'There was an error while trying to send your message to the auctioneer');
            }
        }
    };

    return (
        isMobile ?
            <div className={classes['live-chat']} ref={mobileChatAreaRef} style={{ height: mobileChatContentHeight }}>
                <ChatMessagesList messages={myMessages} />
                <ChatMessagesSendForm handleSubmit={handleSendChatMessage} inputRef={inputRef} />
            </div>
            :
            /*GTM: messages id required for GA. */
            <div className={`${classes['message-panel-container']} ${isOpen ? 'gtm-accordion-expanded' : ''}`}>
                <div key={refNum} className={`${classes['message-panel']} gtm-accordion-expand-trigger ${isOpen ? 'expanded' : ''}`}
                    data-max-height="700" title={messagesTitle} id="messages">
                    <ChatMessagingHeader messagesCount={myUnreadMessageCount} handleToggleOpenMessagesOnClick={handleToggleOpenMessagesOnClick} isMinimised={!isOpen} />

                    <div className={classes['content']} style={{ height: contentDivHeight }}>
                        <div className={classes['live-chat']}>
                            <ChatMessagesList messages={myMessages} />
                            <ChatMessagesSendForm handleSubmit={handleSendChatMessage} inputRef={inputRef} />
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default UserMessaging;

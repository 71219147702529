import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

import { useWebSocketConnection } from '../WebSocketProvider';

const apiUrl = process.env.REACT_APP_SIMULCAST_API || 'https://localhost:44339';
const hubUri = process.env.REACT_APP_SIGNALR_HUB || '/simulcast';
const isDevelopment = process.env.NODE_ENV === 'development';
const logLevel = `${process.env.REACT_APP_ENABLE_IS_DEBUGGING}` === 'true' ? LogLevel.Debug : LogLevel.Error;

export const useCreateSignalRConnection = (bearerToken: string): { connection: HubConnection | undefined } => {
    const [connection, setConnection] = useState<HubConnection>();
    const { sendSocketMessage}= useWebSocketConnection();
    const { otherSession } = useSelector((state: AppState) => state.account);

    useEffect(() => {
        const configureSignalR = async () => {
            if (bearerToken && !otherSession?.hasOtherSession) {
                const options: IHttpConnectionOptions = {
                    accessTokenFactory: () => bearerToken,
                    logMessageContent: isDevelopment,
                    logger: logLevel
                };

                const conn = new HubConnectionBuilder()
                    .withUrl(`${apiUrl}${hubUri}`, options)
                    .withAutomaticReconnect({
                        nextRetryDelayInMilliseconds: (retryContext) => {
                            if (retryContext.elapsedMilliseconds < 60000) {
                                // If we've been reconnecting for less than 60 seconds so far,
                                // wait retry attempt number * 10 secs.
                                return (retryContext.previousRetryCount + 1) * 10000;
                            } else {
                                sendSocketMessage('Logout', [false]);
                                connection?.stop();
                                return null;
                            }
                        }
                    })
                    .withHubProtocol(new JsonHubProtocol())
                    .configureLogging(logLevel)
                    .build();

                conn.serverTimeoutInMilliseconds = 120000;

                conn.keepAliveIntervalInMilliseconds = 10000;

                setConnection(conn);
                //TODO: Fix below as something not right with it.
            } else {
                sendSocketMessage('Logout', [false]);
                await connection?.stop();
                setConnection(undefined);
            }
        };

        configureSignalR();

    }, [bearerToken, otherSession]);

    return { connection };
};

import axios, { AxiosError } from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_SIMULCAST_API || 'https://localhost:44339',
    timeout: Number(process.env.REACT_APP_AXIOS_TIMEOUT || 15000),
    withCredentials: true
});

export type HttpError = AxiosError;

export default instance;

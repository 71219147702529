import { EntityState } from '@reduxjs/toolkit';
import { JoinedSale, joinedSalesSelectors, leaveJoinedSale, SaleLots, saleLotsSelectors, saleSelectors } from 'services/auctions.slice';
import { AppDispatch, AppState } from 'services/redux-store';

import { getProxyBidsLotsList, setMainSale, setMainSaleLots } from './main-sale.slice';

export const handleSetPrimarySale = (refNum: string) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        try {
            const { auctions: { sales: saleState } } = getState();
            const sales = saleSelectors.selectAll(saleState);
            const sale = sales.find((x) => x.refNum === refNum);

            dispatch(setMainSale({ sale }));
            dispatch(getProxyBidsLotsList(refNum)); //TODO: Check if this can be moved to component as maybe SignalR method that updates state
        } catch (error) {
            window.logger.error(error);
        }
    };
};

export const handleSetPrimarySaleLots = (refNum: string) => {
    return (dispatch: AppDispatch, getState: () => AppState): void => {
        try {
            const { auctions: { saleLots } } = getState();

            const { currentLot = {}, lotsList = [] } = (saleLotsSelectors.selectById(saleLots, refNum) as SaleLots) || {};

            dispatch(setMainSaleLots({ currentLot, lotsList }));
        } catch (error) {
            window.logger.error(error);
        }
    };
};

export const handleLeavePrimarySale = (refNum: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        try {
            await Promise.resolve(dispatch(leaveJoinedSale({ refNum, buyerNumber: '' })));

            const { auctions: { joinedSales } = {} } = getState();

            if (joinedSales) {
                const joinedSalesEntities = joinedSalesSelectors.selectIds(joinedSales as EntityState<JoinedSale>);

                const nextJoinedSaleRef = joinedSalesEntities[0]?.toString();

                if (nextJoinedSaleRef) {
                    dispatch(handleSetPrimarySale(nextJoinedSaleRef));
                    dispatch(handleSetPrimarySaleLots(nextJoinedSaleRef));
                }
            }
        } catch (error) {
            window.logger.error(error);
        }
    };
};

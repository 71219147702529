import React, { FC } from 'react';

import classes from '../auction-control.module.scss';

export interface IOnSaleStatus {
    show: boolean;
}

const OnSaleStatus: FC<IOnSaleStatus> = ({ show = false }) => {

    return show ? <p className={`${classes.status} ${classes['on-sale']}`}>On sale</p> 
                : null;
};

export default OnSaleStatus;

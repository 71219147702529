import { LotDisplayHelpers } from 'Common/Helpers/LotDisplayHelpers';
import { useIsMobile } from 'Common/Hooks/useIsMobile.hook';
import { ICurrentLot } from 'Common/Interfaces/current-lot.interfaces';
import MinMaxButton from 'Common/MinMaxButton/MinMaxButton';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { IApiVehicleDetailsViewModel } from 'Common/Sales/Sales.interface';
import AuctionControls from 'Features/AuctionControls/AuctionControls.container';
import { ISaleListItem } from 'Features/AuctionsList/Interfaces/sale-list-item.interface';
import VideoPlayer, { VideoPlayerType } from 'Features/VideoPlayer/VideoPlayer.container';
import React, { FC, MouseEvent, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { JoinedSale, joinedSalesSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import BidDetailsLink from './BidDetailsLink';
import PreviewStatusBadges from './PreviewStatusBadges';
import classes from './sale-preview.module.scss';
import SalePreviewHeader from './SalePreviewHeader';
import SalePreviewLotTitle from './SalePreviewLotTitle';
import SalePreviewStats from './SalePreviewStats';
import SwapIcon from './SwapIcon';

interface ISalePreviewProps {
    currentLot: ICurrentLot;
    handleSetMainSale: (refNum: string) => void;
    handleToggleBidHistory: (refNum: string, saleListViewRef: RefObject<HTMLDivElement>) => void;
    sale: ISaleListItem;
    saleListViewRef: RefObject<HTMLDivElement>;
    handleMinimiseSecondarysale: () => void;
    isMinimised: boolean;
    isBidHistoryOpen: boolean;
}

const SalePreview: FC<ISalePreviewProps> = ({
    currentLot,
    handleSetMainSale,
    handleToggleBidHistory,
    sale,
    saleListViewRef,
    handleMinimiseSecondarysale,
    isMinimised,
    isBidHistoryOpen
}) => {
    const { refNum, isJoined } = sale || '';
    const { joinedSales } = useSelector((state: AppState) => state.auctions);

    const { buyerNumber = '' } = (joinedSalesSelectors.selectById(joinedSales, refNum) as JoinedSale) || {};

    const { siteId = '' } = sale ?? {};

    const { userRole } = useSelector((state: AppState) => state.account);

    const { extraDetails: { conditionReportUrl = '' } = {} } = currentLot;

    const buyerNumberTitle = userRole === 'buyer' ? 'Bidder Number : ' : 'Seller Number : ';

    const lotDetails = ((currentLot.lotDetails as unknown) as IApiVehicleDetailsViewModel) || {};

    const lotName = LotDisplayHelpers.makeModelDerivativeDisplay(currentLot.lotDetails, false);

    const isMobile = useIsMobile();

    const handleSwapMainSaleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        handleSetMainSale(sale.refNum);
    };

    return (
        <div className={`${classes['auction-preview']} ${isBidHistoryOpen ? classes['w-detail'] : ''}`}>
            <SalePreviewHeader branch={sale?.branch} description={sale?.saleDescription} saleDate={sale.saleDate} />
            {!isMobile &&
                (<>
                    <div className={classes['auction-preview-minimise']}>
                        <MinMaxButton isMinimised={isMinimised} gtmClass={`gtm-auction-preview-${isMinimised ? 'maximise' : 'minimise'}`} onClick={handleMinimiseSecondarysale} />
                    </div>
                    <div className={classes['body']}>
                        <SalePreviewLotTitle lotNumber={currentLot?.lot} lotName={lotName} />

                        <div>
                            {currentLot.lot && (
                                <SalePreviewStats currentLot={currentLot} />
                            )}
                            <PreviewStatusBadges currentLot={currentLot} />
                            {!isMinimised && isJoined && (
                                <VideoPlayer
                                    refNum={sale.refNum}
                                    videoUrl={sale.vidUrl}
                                    vehicleImages={currentLot.vehicleImages}
                                    videoPlayerClass={VideoPlayerType.PREVIEW_SALE}
                                />
                            )}
                        </div>
                        <div className={classes['cells']}>
                            <div className={`${classes['cell']} ${classes['left']}`}>
                                <>
                                    {buyerNumberTitle} {buyerNumber}
                                </>
                            </div>
                            <div className={`${classes['cell']} ${classes['right']}`}>
                                <BidDetailsLink
                                    handleToggleBidHistory={(refNum, saleListViewRef) => handleToggleBidHistory(refNum, saleListViewRef)}
                                    saleListViewRef={saleListViewRef}
                                    refNum={sale.refNum}
                                    isBidHistoryOpen={isBidHistoryOpen}
                                />
                            </div>
                        </div>

                        <AuctionControls refNum={refNum} siteId={siteId} buyerNumber={buyerNumber} type={'preview'} lotDetails={lotDetails} />
                    </div>

                    <div className={classes['cells']}>
                        <div className={classes['cell']}>
                            <InspectionReportLink text="Inspection Report" linkUrl={conditionReportUrl} variant="large" />
                        </div>
                        <div className={`${classes['cell']} ${classes['right']}`}>
                            {/* Hidded as not yet implimented */}
                            {/*<a href="#" title="Pop out window" className="a-icon ac-popout" style={{ visibility: 'hidden' }}>*/}
                            {/*    <span className="decal">*/}
                            {/*        <span className="a"></span>*/}
                            {/*    </span>*/}
                            {/*    <span className="tooltip">Pop out window</span>*/}
                            {/*</a>*/}
                            <SwapIcon handleSwapMainSaleOnClick={handleSwapMainSaleOnClick} />
                        </div>
                    </div>
                </>)}
        </div>
    );
};

export default SalePreview;

import { DateHelper } from 'Common/Helpers/DateHelper';
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import { IApiVehicleDetailsViewModel } from 'Features/MainSale/Components/AuctionResults/AuctionResults.container';
import React, { FC } from 'react';

interface IAuctionResultsEntryProps {
    lot: IApiVehicleDetailsViewModel;
}

const TodaysAuctionResultsEntry: FC<IAuctionResultsEntryProps> = ({ lot }) => {
    return (
        <>
            <ListTableRow className='row-entry'>
                <ListTableCell value1={lot.siteName ?? ''} value2={lot.auctionName ?? ''} classes="column-height" />
                <ListTableCell value1={lot.make ?? ''} value2={lot.model ?? ''} classes="column-height" />
                <ListTableCell value1={DateHelper.ToDateString(lot.regDate ?? '')} value2={lot.engineSize ?? ''} classes="column-height" />
                <ListTableCell
                    value1={lot.capClean?.toCurreny() ?? ''}
                    value2={isNaN(parseInt(lot.reserve)) ? '' : parseInt(lot.reserve).toCurreny()}
                    classes="column-height"
                />
            </ListTableRow>


            <ListTableRow className='row-bottom'>
                <ListTableCell value1={lot.lot ?? ''} value2={lot.regNo ?? ''} classes="column-height" />
                <ListTableCell value1={lot.variant ?? ''} value2={lot.bodyType ?? ''} classes="column-height" />
                <ListTableCell value1="&nbsp;" value2="&nbsp;" classes="column-height" />
                <ListTableCell
                    value1={`${lot.saleResult ?? ''} ${lot.hammerPrice?.toCurreny()}`}
                    value2="&nbsp;"
                    classes="column-height"
                    textColorClassVal1={lot.saleResultClass}
                /> 
            </ListTableRow>
        </>
    );
};

export default TodaysAuctionResultsEntry;

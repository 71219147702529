import { useEffect, useState } from 'react';

import { BidIntentButtonColours } from '../Enums/bid-intent-btn-colour.entity';
import { BidIntentButtonTitle } from '../Enums/bid-intent-btn-title.entity';
import { BidIntentButtonTrigger } from '../Enums/bid-intent-btn-trigger.enum';

export const useBiddingIntentProps = (isLooker: boolean, isUserIn: boolean): { intentTitle: string; intentColour: string; intentTrigger: string } => {
    const [intentButtonState, setIntentButtonState] = useState({
        intentTitle: BidIntentButtonTitle.MAY_BID_INTENT_TITLE,
        intentColour: BidIntentButtonColours.MAY_BID_INTENT_COLOUR,
        intentTrigger: BidIntentButtonTrigger.MAY_BID_INTENT_TRIGGER
    });

    useEffect(() => {
        if (isLooker) {
            setIntentButtonState({
                intentTitle: BidIntentButtonTitle.VIEW_ONLY_INTNET_TITLE,
                intentColour: BidIntentButtonColours.VIEW_ONLY_INTNET_COLOUR,
                intentTrigger: BidIntentButtonTrigger.VIEW_ONLY_INTENT_TRIGGER
            });
        } else if (isUserIn) {
            setIntentButtonState({
                intentTitle: BidIntentButtonTitle.IM_OUT_INTENT_TITLE,
                intentColour: BidIntentButtonColours.IM_OUT_INTENT_COLOUR,
                intentTrigger: BidIntentButtonTrigger.IM_OUT_INTENT_TRIGGER
            });
        } else {
            setIntentButtonState({
                intentTitle: BidIntentButtonTitle.MAY_BID_INTENT_TITLE,
                intentColour: BidIntentButtonColours.MAY_BID_INTENT_COLOUR,
                intentTrigger: BidIntentButtonTrigger.MAY_BID_INTENT_TRIGGER
            });
        }
    }, [isUserIn, isLooker]);

    return { ...intentButtonState };
};

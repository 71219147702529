import { HubConnection } from '@microsoft/signalr';
import { setIsPaused } from 'Features/AuctionControls/bidding-state.slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSaleLotsList, resetActiveAndManheimBidders, setActiveAndManheimBidders, setSaleClientsOnline, setSaleProxyClientsOnline, setVendorSaleClientsOnline, updateJoinedSale, updateSaleMediaSource } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useConfigureSignalRSaleMethods = (connection: HubConnection | undefined): void => {
    const dispatch = useDispatch();
    const { userRole } = useSelector((state: AppState) => state.account);

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on('saleStatusUpdate', (message) => {
            newrelic.addPageAction('signalr_event_saleStatusUpdate', message);

            dispatch(setIsPaused(message));
        });

        connection.on('stockUpdate', (refNum) => {
            newrelic.addPageAction('signalr_event_stockUpdate', refNum);

            dispatch(getSaleLotsList(refNum));
        });

        connection.on('siteMediaUpdate', (message) => {
            window.logger.info('SiteMediaUpdate', message);

            dispatch(updateSaleMediaSource(message));
        });

        connection.on('clientsOnline', (response) => {
            newrelic.addPageAction('signalr_event_clientsOnline', response);

            //window.logger.log('clientsOnline',response);

            dispatch(setSaleClientsOnline(response));
        });

        connection.on('vendorClientsOnline', (response) => {
            newrelic.addPageAction('signalr_event_vendorClientsOnline', response);

            dispatch(setVendorSaleClientsOnline(response));
        });

        connection.on('activeAndManheimBiddersUpdate', (response) => {
            dispatch(setActiveAndManheimBidders(response));
        });

        connection.on('resetActiveAndManheimBidders', (response) => {
            dispatch(resetActiveAndManheimBidders(response));
        });

        connection.on('proxyBidClientsUpdated', (response) => {
            newrelic.addPageAction('signalr_event_proxyBidClientsUpdated', response);

            //window.logger.log('proxyBidClientsUpdated',response);

            dispatch(setSaleProxyClientsOnline(response));
        });

        connection.on('buyerNumberUpdate', (response) => dispatch(updateJoinedSale(response)));

        return () => {
            connection.off('saleStatusUpdate');

            connection.off('stockUpdate');

            connection.off('siteMediaUpdate');

            connection.off('clientsOnline');

            connection.off('vendorClientsOnline');

            connection.off('proxyBidClientsUpdated');

            connection.off('buyerNumberUpdate');
        };
    }, [connection, userRole]);
};

import React, { FC, ReactElement } from 'react';

import classes from './grade.module.scss';

export interface IGrade {
    grade: string;
}

const Grade: FC<IGrade> = ({ grade }) => {
    return (
        (grade && grade != '0') ? <div className={`${classes['grade']} ${classes['grade-' + grade]} ${classes['compact']}`}> {grade}</div> : <></>
    )
};

export default Grade;

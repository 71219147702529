import React, { FC } from 'react';

import classes from '../status-badge.module.scss';
interface IStatusLabelProps {
    title: string;
    isDisabled?: boolean;
}

const StatusLabel: FC<IStatusLabelProps> = ({ title, isDisabled = false }) => {
    const iconStyles = isDisabled ? { backgroundColor: '#DFE0E2' } : {};

    return (
        <>
            <span className={classes['icon']} style={iconStyles}></span>
            <span className={classes['label']}> {title || ''}</span>
        </>
    );
};

export default StatusLabel;

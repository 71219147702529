import React, { FC } from 'react';

import classes from './accordion.module.scss';

type IconType = {
    type?: 'arrow' | 'plus';
}

const ExpandIcon: FC<IconType> = ({ type = "plus" }) => {
    return (
        <a className={classes['a-icon']}>
            {
                type == 'arrow' ? <span className={classes['arrow-icon']}>&#xFE65;</span> :
                    <>
                        <span className={`${classes['d']}`}></span>
                        <span className={`${classes['d']} ${classes['d-2']}`}></span>
                    </>
            }
        </a>
    );
};

export default ExpandIcon;

import { LiveStockExtraDetails } from 'Domain/Entities/live-stock-extra-details.entity';

export interface ILiveClientsOnline {
    accountNo: string;
    accType: string;
    bidderNo: string;
    company: string;
    name: string;
    proxyBidder: boolean;
}

export interface IApiLiveCustomerTypes {
    allowMsg?: boolean;
    custType: string;
    description: string;
    hasImOut?: boolean;
    hasPredefinedMsgs?: boolean;
    showBidder?: boolean;
}

export interface IApiPartnerRole {
    clientID: string;
    customerType: IApiLiveCustomerTypes;
    name: string;
    role: string;
}

export interface IApiSiteMedia {
    audSrc: string;
    mediaId: number;
    siteId: number;
    srcName: string;
    vidSrc: string;
}

export interface IApiLotViewModel extends IApiLotSummaryViewModel {
    announcement?: string;
    auctioneers?: string[];
    consignerID?: string;
    exteriorColour?: string;
    formerKeepers?: string;
    glassesGuide?: number;
    import?: string;
    inspectionFuelType?: string;
    inspectionURL?: string;
    interiorColour?: string;
    lastServiceDate?: string;
    location?: string;
    mot?: string;
    motExpiry?: string;
    motStatus?: string;
    odometerAtLastService?: string;
    privateHire?: boolean;
    regExpDate?: string;
    remarks?: string;
    runner?: string;
    serviceCount?: string;
    specification?: string;
    translatedModel?: string;
    v5Location?: string;
    variant?: string;
    vatStatus?: string;
    vCar?: string;
    vehicleGrade?: string;
    vehicleImages?: IApiVehicleImageViewModel[];
    vehicleServiceHistory?: IApiVehicleServiceHistoryViewModel[];
    vendorLogoUrl?: string;
    vendorGroupName?: string;
    lightboxImageUrl?: string;
    v5?: boolean;
    sureCheck?: boolean;
    sureCheckText?: string;
    sureCheckResult?: string;
    checkPointCertificateId?: number;
}

export interface IApiLotSummaryViewModel {
    bodyType?: string;
    capAverage?: number;
    capClean?: number;
    capRetail?: number;
    consignerID?: string;
    derivative?: string;
    engineSize?: string;
    grade?: string;
    hammer?: number;
    inspectionFuelType?: string;
    inspectionURL?: string;
    isMyLot?: boolean;
    lot?: string;
    make?: string;
    mileage?: number;
    mileageText?: string;
    model?: string;
    regDate?: string;
    regNo?: string;
    reserve?: string;
    serviceCount?: string;
    seats?: string;
    status?: number;
    transmission?: string;
    vendor?: string;
    vin?: string;
    warranted?: boolean;
    watching?: boolean;
    workOrder?: string;
}

export interface IApiProxyBidLotViewModel extends IApiLotSummaryViewModel {
    bidValue?: number;
}

export interface IApiSoldLotViewModel extends IApiLotSummaryViewModel {
    soldStatus?: number;
}

export interface IApiCurrentLotDetails {
    askingPrice?: number;
    cldId?: number;
    currentBid?: number;
    currentBidUser?: string;
    haveBid: boolean;
    imIn: boolean;
    lightStatus?: IApiLightStatusViewModel;
    lot?: string;
    lotDetails?: IApiLotViewModel;
}

export interface IApiBidHistory {
    lot: string;
    messages: IApiBidHistoryMessage[];
}

export interface IApiBidHistoryMessage {
    message: string;
    type: BidHistoryMessageType;
}

export interface IApiBidHistoryUpdate {
    lot: string;
    message: string;
    refNum: string;
    type: BidHistoryMessageType;
}

export interface IApiBidInformationUpdate {
    askingPrice: number;
    cldId: number;
    currentBid: number;
    currentBidUser: string;
    refNum: string;
}

export interface IApiBuyerNumberUpdate {
    buyerNumber: string;
    refNum: string;
}

export enum BidHistoryMessageType {
    NewEntry,
    Normal,
    Alert,
    Highlight,
    Bid,
    Green,
    Blue,
    Red,
    LotSold,
    Message
}

export interface IApiLightStatusViewModel {
    asIs: boolean;
    assured: boolean;
    caution: boolean;
    generallyOk: boolean;
    noVat: boolean;
    offSite: boolean;
    onSale: boolean;
    refNum: string;
    saleDay: boolean;
    sureCheck: boolean;
    sureCheckText: string;
    title: boolean;
    titleColour: string;
    v5: boolean;
    vendorOnline: boolean;
}

export interface IApiLotChangedDetailsViewModel {
    cldId: string;
    lightStatus: IApiLightStatusViewModel;
    lot: string;
    lotDetails: IApiLotViewModel;
    refNum: string;
    siteId: number;
}

export interface IApiLotChangedExtraDetailsViewModel {
    extraDetails: LiveStockExtraDetails;
    lot: string;
    refNum: string;
    vehicleImages: IApiVehicleImageViewModel[];
    vehicleServiceHistory: IApiVehicleServiceHistoryViewModel[];
    workOrder: string;
}

export enum LiveLotsStatus {
    Current = -1,
    Unknown = 0,
    Sold = 1,
    Unsold = 2,
    ProvisionallySold = 3,
    Retracted = 4
}

export interface IApiLotStatusUpdateViewModel {
    lot: string;
    refNum: string;
    status: LiveLotsStatus;
}

export interface IApiVehicleDetailsViewModel {
    auctionName: string;
    bodyType: string;
    CAPClean?: number;
    engineSize: string;
    formattedRegDate: string;
    grade: string;
    hammerPrice: number;
    inspectionURL: string;
    lot: string;
    make: string;
    mileage?: number;
    mileageText: string;
    model: string;
    refNum: string;
    regDate?: string;
    regExpDate: string;
    regNo: string;
    reserve: string;
    saleDateTime: string;
    saleResult: string;
    saleResultClass: string;
    saleTime: string;
    siteId: string;
    siteName: string;
    variant: string;
    warrantedText: string;
}

export interface IApiVehicleImageViewModel {
    imageUrl: string;
    imgIndex: number;
    isMain?: boolean;
    lightboxImageUrl: string;
    mediaType?: number;
    photoCaption: string;
}

export interface IApiVehicleServiceHistoryViewModel {
    glassesGuide?: number;
    serviceDate?: string;
    serviceIndex: number;
    serviceOdometer: string;
    serviceStation: string;
    serviceType: string;
    vendorGroupName: string;
}

export interface IApiBlockedStatusUpdateViewModel {
    isBlocked: boolean;
    refNum: string;
}

export interface IWebHaveBidUpdate {
    haveBid: boolean;
    refNum: string;
}

export interface IWebImInUpdate {
    imIn: boolean;
    refNum: string;
}

export interface IWebJoinSaleCommand {
    refNum: string;
    username: string;
}

export interface IApiFieldUpdateViewModel {
    fieldName: string;
    refNum: string;
    value: string;
}

import Heading from 'Common/Heading/Heading';
import bidHammer from 'img/bid-hammer.png';
import React from 'react';
import { useNavigate } from 'react-router';
import { browser_session } from 'services/browser-storage';

import classes from './not-found.module.css';

const NotFound = () => {

  const navigate = useNavigate();

  const handleGoBack = () => navigate('/SelectApp', { replace: true });

  const isTnCAccepted = browser_session.get('__TnC');

  return (
    isTnCAccepted ?
      <section className={classes['section-not-found']}>
        <div className={classes.text404}>4 <img src={bidHammer} /> 4</div>
        <Heading size='large' color={'dark-blue'}>
          Sorry, we couldn’t find the page you were looking for.
        </Heading>
        <button type='button' onClick={handleGoBack} className={classes['btn-go-back']}>Go Back</button>
      </section> : <></>
  )
}

export default NotFound

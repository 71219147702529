import React, { FC, ReactElement } from 'react';

import classes from './no-result.module.scss';

interface INoResult {
    children: ReactElement; 
}

const NoResult: FC<INoResult> = ({ children }) => {
    return (
        <div className={classes['no-results']}>
            {children}
        </div>
    );
};

export default NoResult;

import withErrorBoundry from 'Common/ErrorBoundary/withErrorBoundary';
import image from 'img/video_unavailable.jpg';
import React, { FC, RefObject } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useJoinStream } from './Hooks/useJoinStream.hook';
import { useMutedState } from './Hooks/useMutedState.hook';
import classes from './phenix-video-player.module.scss'; 

interface IPhenixVideoPlayer {
    refNum: string;
    show: boolean;
    state: string;
    videoRef: RefObject<HTMLVideoElement>;
    videoUrl: string;
    isMuted: boolean;
    setIsMuted: (muted: boolean) => void;
}

const PhenixVideoPlayer: FC<IPhenixVideoPlayer> = ({ refNum,show, state, videoRef, videoUrl, isMuted, setIsMuted }) => {
    const id = uuidv4();
 
    useJoinStream(videoUrl, videoRef, state, refNum);

    useMutedState(videoUrl, videoRef, state, isMuted, setIsMuted);
 
    return (
        <div style={{ display: show ? 'block' : 'none' }} className={classes['phenix-video-item']}>
            <video ref={videoRef} id={id} muted={isMuted} poster={image} preload="auto" playsInline disablePictureInPicture autoPlay></video>
        </div>
    );
};

export default withErrorBoundry(PhenixVideoPlayer);

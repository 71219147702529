import Heading from 'Common/Heading/Heading';
import React, { FC } from 'react';

import VendorLogo from '../VendorLogo/VendorLogo';
import classes from './auction-header.module.scss';

interface ILotTitle {
    bodyType: string;
    derivative: string;
    fuelType: string;
    lot: number;
    make: string;
    model: string;
    transmission: string;
    vendorLogoUrl: string;
    isMobile: boolean;
    regNo: string;
}

const LotTitle: FC<ILotTitle> = ({ fuelType = '', lot, make = '', model = '', derivative = '', transmission = '', bodyType = '', vendorLogoUrl, isMobile, regNo }) => {
    const lotName = `${make || ''} - ${model || ''} - ${derivative || ''}`;
    const vehicleDetails = ` ${transmission || ''} - ${fuelType} - ${bodyType || ''}`;

    return (
        <div className={classes['lot-header']}>
            <div className={classes['lot-header-title']}>
                <Heading size={'small'} color={'dark-blue'}>
                    Lot {lot}
                </Heading>
                {lot && (
                    <div className={classes['cell']}>
                        <p className={classes['vehicle-reg']}>{regNo}</p>
                    </div>
                )}
            </div>
            {lot && (
                <div className={classes['sale-item']}>
                    {isMobile ? <p> {lotName} {vehicleDetails} </p> :
                        <><p> {lotName} </p>
                            <p> {vehicleDetails} </p>
                        </>}
                </div>
            )}
            {!isMobile && (<div className={classes['vendor-logo']}>
                <VendorLogo vendorLogoURL={vendorLogoUrl ?? ''} />
            </div>)}

        </div>
    );
};

export default LotTitle;

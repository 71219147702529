import MinMaxButton from 'Common/MinMaxButton/MinMaxButton';
import React, { FC, MouseEvent } from 'react';

import classes from '../chat-messaging.module.css';
import ChatUnreadMessageCount from '../Components/ChatUnreadMessageCount';

interface IChatMessagingHeader {
    messagesCount: number;
    handleToggleOpenMessagesOnClick: (e: MouseEvent<HTMLDivElement>) => void;
    isMinimised: boolean;
}

const ChatMessagingHeader: FC<IChatMessagingHeader> = ({ messagesCount, handleToggleOpenMessagesOnClick, isMinimised }) => {
    return (
        /*GTM: gtm-accordion-expand-trigger class required for GA. */
        <div className={`gtm-accordion-expand-trigger ${classes['header-bar']} ${classes['cells']}`} onClick={handleToggleOpenMessagesOnClick}>
            <div className={`title ${classes['cell']}`}>
                Messages <ChatUnreadMessageCount unreadCount={messagesCount} />
            </div>
            <div className={`${classes['right']} ${classes['cell']}`}>
                <MinMaxButton isMinimised={isMinimised} />
            </div>
        </div>
    );
};

export default ChatMessagingHeader;

import React, { FC } from 'react';

import classes from './connection-disconnect-dialog.module.scss'
interface IConnectionDisconnectDialog {
    toastTitle: string;
    iconName: string;
}
const ConnectionDisconnectDialog: FC<IConnectionDisconnectDialog> = ({ toastTitle, iconName }) => {

    return (
        <>
            <div id="myModal" className={classes['modal']}>
                <div className={classes['modal-content']}>
                    {iconName && <span className={classes['material-icons']}>
                        {iconName}</span>}  <p>{toastTitle}</p>
                </div>

            </div>
        </>
    );

}

export default ConnectionDisconnectDialog;
import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

interface IMobileSideNavContext {
    isMobileSideNavOpen: boolean;
    toggleMobileSideNav: () => void;
}

const MobileSideNavContext = createContext<IMobileSideNavContext>({
    isMobileSideNavOpen: false,
    toggleMobileSideNav: () => { return; }
});

const MobileSideNavProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isMobileSideNavOpen, setIsMobileSideNavOpen] = useState<boolean>(false);

    const toggleMobileSideNav = () => {
        setIsMobileSideNavOpen(!isMobileSideNavOpen);
    }

    return (
        <MobileSideNavContext.Provider value={{ isMobileSideNavOpen, toggleMobileSideNav }}>
            {children}
        </MobileSideNavContext.Provider>
    );
}

export const useMobileSideNavContext = () => useContext(MobileSideNavContext);

export default MobileSideNavProvider;

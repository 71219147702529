import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useAuthenticationStatus } from './Hooks/useAuthenticationStatus.hook';
import { useUserAuthentication } from './Hooks/useUserAuthentication.hook';
import { useUserClientLoginInfo } from './Hooks/useUserClientLoginInfo.hook';

const UserAccountProvider: FC<PropsWithChildren> = ({ children }) => {
    const { loginUser, logoutUser } = useUserAuthentication();

    useUserClientLoginInfo();
    const isLoggedIn = useAuthenticationStatus();

    return <UserAccountContext.Provider value={{ isLoggedIn, login: loginUser, logout: logoutUser }}>{children}</UserAccountContext.Provider>;
};

interface IUserAccountContext 
{
    isLoggedIn: boolean;
    login: (username: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

const UserAccountContext = createContext<IUserAccountContext>({
    isLoggedIn: false,
    login: (username: string, password: string) => Promise.reject('Unimplemented'),
    logout: () => Promise.reject('Unimplemented')
});

export const useUserAccountContext = (): IUserAccountContext => useContext(UserAccountContext);

export default UserAccountProvider;

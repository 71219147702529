const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
const dateFormatter = new Intl.DateTimeFormat('en-GB', dateOptions);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
const timeFormatter = new Intl.DateTimeFormat('en-GB', timeOptions);

const GetYearFromDate = (date: string | Date): number => new Date(date).getFullYear();

const ToDateString = (date: Date | string | null): string => (!date ? '' : dateFormatter.format(new Date(date)));

const ToDateDotString = (date: Date | string | null): string => {
    if (!date) return '';

    return dateFormatter
        .formatToParts(new Date(date))
        .map(({ type, value }) => (type === 'literal' ? '.' : value))
        .join('');
};

const GetTime = (date: Date | string | null): string => (!date ? '' : timeFormatter.format(new Date(date)));

const compareDate = (date1: Date, date2: Date): 0 | 1 | -1 => {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    if (date1 > date2) return 1;
    if (date2 > date1) return -1;

    return 0;
};

export const DateHelper = {
    GetTime,
    GetYearFromDate,
    ToDateDotString,
    ToDateString,
    compareDate
};

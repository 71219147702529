import React, { FC, ReactElement } from 'react'; 

import classes from './data-table.module.scss';

interface IDataTableCell {
    item: string | number;
}

const DataTableCell: FC<IDataTableCell> = ({ item }) => {
    return (
        <div className={classes['value']}>
            {item}
        </div>

    );
};

export default DataTableCell;

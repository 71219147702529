import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import React, { FC } from 'react';

interface IAuctionResultsSummaryProps {
    totalSold: number;
    totalProvisional: number;
    totalUnsold: number;
}

const TodaysAuctionResultsSummary: FC<IAuctionResultsSummaryProps> = ({ totalSold, totalProvisional, totalUnsold }) => {
    return (

        <ListTableRow className='header'>
            <ListTableCell value1={`Total Vehicle Sold: ${totalSold}`} classes="column-height" />
            <ListTableCell value1={`Total Vehicle Provisional: ${totalProvisional}`} classes="column-height" />
            <ListTableCell value1={`Total Vehicle Unsold: ${totalUnsold}`} classes="column-height" />
            <ListTableCell value1="" classes="column-height" />
        </ListTableRow>

    );
};

export default TodaysAuctionResultsSummary;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null
    };

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, error };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        window.logger.error('Uncaught error:', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1>Sorry.. there was an error</h1>
                    <span>
                        {this.state.error?.name} {this.state.error?.message}
                    </span>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;

import { useSelector } from 'react-redux';
import { ActiveAndManheimBidders, activeAndManheimBiddersSelectors } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

import { BiddingMessage } from '../bidding-details.slice';

export const useGetBidUpdateMessage = (bidMessage: BiddingMessage, isShowManheimBids: boolean, userId: string, userRole: string): { message: string, css: string, bidType: string } => {
    const { message, refNum, currentBidUser } = bidMessage;
    const isBidCurrentUser = (!userId.isEmptyOrWhitespace() && userId.isEqualIgoreCase(currentBidUser));
    const isVendor = userRole === 'vendor';
    const messageText = isVendor ? 'received' : 'against you';

    let finalMsg = message + " " + messageText;

    if (isBidCurrentUser) {
        finalMsg = `${currencyRegex.test(message) ? message.match(currencyRegex)![0] : ''} Your Bid`;
    }
    else if (isVendor && isShowManheimBids && internetRegex.test(message)) {

        const { manheimBidders } = useSelector((state: AppState) => activeAndManheimBiddersSelectors.selectById(state.auctions.activeAndManheimBidders, refNum)) as ActiveAndManheimBidders;

        finalMsg = manheimBidders.includes(currentBidUser) ? message.split(internetRegex)[0] : finalMsg;
    }

    const bidType = (hallOrInternetBidRegex.test(finalMsg) && finalMsg.match(hallOrInternetBidRegex)![0].toLowerCase()) || '';

    return { message: finalMsg, css: isBidCurrentUser ? 'for' : 'against', bidType };
};

const currencyRegex = new RegExp(/\W{1}(\d+,?\d+)(\.\d\d)?/);
const internetRegex = new RegExp('internet', 'i');
const hallOrInternetBidRegex = /hall|internet/gi;


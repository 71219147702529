import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import http from 'Infrastructure/HttpService/http.service';
import { AppDispatch, AppState } from 'services/redux-store';
import { setObjectKeyValue } from 'utils/object.utils';

export type MainSaleState = {
    sale: any;
    lots: any[];
    currentLot: any;
    proxyBids: Array<any>;
};

const initialState: MainSaleState = {
    sale: undefined,
    lots: [],
    currentLot: undefined,
    proxyBids: new Array<any>()
};

const primarySaleUpdated = createAction<any>('auctions/setSaleCurrentLot');
const primarySaleExtraUpdated = createAction<any>('auctions/setSaleCurrentLotExtra');
const primarySaleRetracted = createAction<any>('auctions/setSaleLotRetracted');
const primarySaleLeft = createAction<any>('auctions/leaveJoinedSale');
const primarySaleFieldUpdate = createAction<any>('auctions/setSaleCurrentLotFieldUpdate');
const primarySaleStatusUpdate = createAction<any>('auctions/setSaleLotStatus');
const primarySaleLightsUpdate = createAction<any>('auctions/setSaleCurrentLotLights');
const primarySaleUpdateMedia = createAction<any>('auctions/updateSaleMediaSource');

export const getProxyBidsLotsList: any = createAsyncThunk<any, string, { dispatch: AppDispatch; state: AppState; getState: () => AppState }>(
    'mainSale/getProxyBidsLotsList',
    async (refNum, { getState }) => {
        try {
            const {
                account: { bearerToken = '' }
            }: AppState = getState();
            const response = await http.get(`api/lot/proxybidlots?refNum=${refNum}`, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`
                }
            });
            const { data } = response;
            return { data, refNum };
        } catch (error) {
            window.logger.error(error);

            //toastr.error('Error', 'An error occurred getting proxy bid lots list');
        }
    }
);

const mainSaleSlice = createSlice({
    name: 'mainSale',
    initialState,
    reducers: {
        setMainSale: (state, { payload }) => {
            const { sale } = payload;

            state.sale = sale;

            return state;
        },
        setMainSaleLots: (state, { payload }) => {
            const { lotsList, currentLot } = payload;

            state.lots = [...lotsList];

            state.currentLot = currentLot;

            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(primarySaleUpdated, (state, { payload }) => {
            try {
                const { currentLot = {}, lots = [], sale = {} } = state;

                const apiLot = lots?.find((x: any) => x.lot === payload.lot) || {};

                const isPayloadCurrentSale = payload.refNum === sale?.refNum;
                const isPayloadCurrentLot = payload.lot === currentLot?.lot;

                if (isPayloadCurrentSale && isPayloadCurrentLot) {
                    state.currentLot = Object.assign(currentLot || {}, payload, { lotDetails: { ...payload.lotDetails, watching: apiLot.watching } });
                } else if (isPayloadCurrentSale || !sale) {
                    state.currentLot = Object.assign({ lotDetails: apiLot }, payload, {
                        lotDetails: { ...payload.lotDetails, watching: apiLot.watching }
                    });
                }
            } catch (error) {
                window.logger.error(error);
            }

            return state;
        }),
            builder.addCase(primarySaleExtraUpdated, (state, { payload }) => {
                const { currentLot = {}, lots = [], sale } = state;

                try {
                    const apiLot = lots?.find((x: any) => x.lot === payload.lot) || {};

                    const isPayloadCurrentSale = payload.refNum === sale?.refNum;
                    const isPayloadCurrentLot = payload.lot === currentLot?.lot;

                    if (isPayloadCurrentSale && isPayloadCurrentLot) {
                        state.currentLot = Object.assign(currentLot, payload);
                    } else if (isPayloadCurrentSale) {
                        state.currentLot = Object.assign({ lotDetails: apiLot }, payload);
                    }
                } catch (error) {
                    window.logger.error(error);
                }

                return state;
            }),
            builder.addCase(primarySaleRetracted, (state, { payload }) => {
                if (payload.refNum === state.sale?.refNum && payload?.status && payload.status === 4) {
                    state.currentLot = undefined;
                }

                return state;
            }),
            builder.addCase(primarySaleStatusUpdate, (state, { payload }) => {
                const LOT_RETRACTED = 4;
                const LOT_STATUS = [1, 2, 3];
                const { refNum, lot: lotNo, status } = payload;
                const { currentLot, lots, sale } = state;

                //Check the last lot in lot list to clear current lot
                let lastLot = lots.filter((x) => x.status !== 1 && x.status !== 2 && x.status !== 3).slice(-1)[0];

                if (lastLot) {
                    lastLot = lastLot.lot;
                }

                if (sale.refNum === refNum && currentLot?.lot === lotNo) {
                    const lot = lots.find((x: any) => x.lot === lotNo);

                    const index = lots.indexOf(lot);

                    state.lots[index] = setObjectKeyValue(lot, 'status', status);

                    state.currentLot = status === LOT_RETRACTED || (lastLot === lotNo && LOT_STATUS.includes(status)) ? undefined : currentLot;
                }

                return state;
            }),
            builder.addCase(primarySaleLeft, (state, { payload }) => {
                if (payload.refNum === state.sale?.refNum) {
                    state.sale = undefined;

                    state.lots = [];

                    state.currentLot = undefined;

                    state.proxyBids = [];
                }

                return state;
            }),
            builder.addCase(primarySaleFieldUpdate, (state, { payload }) => {
                const { currentLot } = state;
                const { refNum, fieldName, value } = payload;

                if (state.sale.refNum === refNum && currentLot) {
                    state.currentLot.lotDetails = setObjectKeyValue(currentLot.lotDetails, fieldName, value);
                }

                return state;
            }),
            builder.addCase(primarySaleLightsUpdate, (state, { payload }) => {
                try {
                    const { sale, currentLot = {} } = state;

                    const isPayloadCurrentSale = payload.refNum === sale?.refNum;

                    if (isPayloadCurrentSale && currentLot) {
                        state.currentLot = Object.assign(currentLot, { lightStatus: payload });
                    }
                } catch (error) {
                    window.logger.error(error);
                }

                return state;
            }),
            builder.addCase(getProxyBidsLotsList.fulfilled, (state: MainSaleState, { payload }) => {
                state.proxyBids = payload.data;

                return state;
            }),
            builder.addCase(
                primarySaleUpdateMedia,
                (state: MainSaleState, { payload }: { payload: { refNum: string; siteId: number; mediaId: number; vidUrl: string } }) => {
                    const { refNum, vidUrl } = payload;

                    if (state.sale?.refNum === refNum) {
                        state.sale.vidUrl = vidUrl;
                    }

                    return state;
                }
            );
    }
});

export const { setMainSale, setMainSaleLots} = mainSaleSlice.actions;

export default mainSaleSlice.reducer;

import React, { FC, ReactElement, RefObject } from 'react';

import classes from './list-table.module.scss';

interface IListTableRowGtm {
    askingPrice: number | '';
    auctionName: string;
    children: ReactElement[];
    isCurrentLot: boolean;
    isFated: boolean
    make: string;
    regNo: string;
    currentLotRef: RefObject<HTMLDivElement>;
}

const ListTableRowGtm: FC<IListTableRowGtm> = ({ children, isCurrentLot, auctionName, regNo, make, askingPrice, isFated, currentLotRef }) => {
    return (
        /*GTM: gtm-auction class and gtm attributes required for GA. */
        <div className={`${classes['row']} ${isFated ? classes['fated-lot'] : ''} gtm-auction`}
            data-gtm-auction-name={auctionName}
            data-gtm-vehicle-name={make}
            data-gtm-vehicle-reg={regNo}
            data-gtm-next-bid-value={askingPrice} style={{ backgroundColor: isCurrentLot ? '#dfeeff' : '' }}
            ref={isCurrentLot ? currentLotRef : null}>
            {children}
        </div>

    );
};

export default ListTableRowGtm;

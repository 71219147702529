import { EntityState } from '@reduxjs/toolkit';
import { DateHelper } from 'Common/Helpers/DateHelper';
import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRowGtm from 'Common/ListTable/ListTableRowGtm';
import WatchlistIcon from 'Common/NotifyBadgeIcon/WatchlistIcon';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import { BiddingState, biddingStateSelectors } from 'Features/AuctionControls//bidding-state.slice';
import { useGetGradeHtml } from 'Features/AuctionControls/Hooks/useGetGradeHtml.hook';
import React, { FC, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

export interface IAllLotsItem {
    lot: IApiLotViewModel;
    isCurrentLot: boolean;
    divCurrentLotRef: RefObject<HTMLDivElement>;
}

const AllLotsListItem: FC<IAllLotsItem> = ({ lot = {} as IApiLotViewModel, isCurrentLot = false, divCurrentLotRef }) => {
    const { mainSale: { sale = {} } = {}, bidding = {} as EntityState<BiddingState> } = useSelector((state: AppState) => state);

    const { askingPrice = '' } = useSelector((state: AppState) => biddingStateSelectors.selectById(state, sale.refNum) || ({} as BiddingState));

    const auctionName = sale?.branch || '';

    const isFated = [1, 2, 3].includes(lot.status || 0);

    const odometerHtml = () => {
        return (
            <>
                {lot.mileage && lot.mileage > 0 ? lot.mileage : ''} &nbsp;
                {lot.mileageText && lot.mileageText.toLowerCase() !== 'unknown' ? lot.mileageText : '—'}
            </>
        );
    };

    const gradehtml = useGetGradeHtml(lot?.grade);

    return (
        <ListTableRowGtm
            isCurrentLot={isCurrentLot}
            auctionName={auctionName}
            regNo={lot.regNo ?? ''}
            make={lot.make ?? ''}
            askingPrice={isCurrentLot ? askingPrice : ''}
            isFated={isFated}
            currentLotRef={divCurrentLotRef}>
            <ListTableCell value1={lot.lot ?? ''} value2={lot.regNo ?? ''} />
            <ListTableCell value1={lot.make ?? ''} value2={DateHelper.ToDateString(lot.regDate ?? '')} />
            <ListTableCell value1={lot.model ?? ''} value2={lot.engineSize ?? ''} />
            <ListTableCell value1={lot.derivative ?? ''} value2={lot.bodyType ?? ''} />
            <ListTableCell
                value1={odometerHtml()}
                value2={<InspectionReportLink variant="small" linkUrl={lot.inspectionURL} />}
                classes="halign-right"
            />
            <ListTableCell value1={gradehtml} classes="halign-center" />
            <ListTableCell value1={lot.watching ? <WatchlistIcon /> : ''} classes="halign-center" />
        </ListTableRowGtm>
    );
};

export default AllLotsListItem;

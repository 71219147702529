import React, { FC, MouseEvent } from 'react';

import classes from '../auction-control.module.scss';

interface IAuctionControlButton {
    title: string;
    colour: string;
    trigger: string;
    isDisabled: boolean;
    onClick: (e: MouseEvent<HTMLButtonElement>) => void; 
}

const AuctionControlButton: FC<IAuctionControlButton> = ({ title = 'Title', colour = 'prepare', trigger = '', isDisabled = true, onClick}) => {
     
    return (
        <button
            title={title}
            onClick={onClick}
            className={`${classes.lozenge} ${classes['button']} ${classes['make-bid']} ${trigger} ${isDisabled ? classes['unauthorised'] : classes[colour]}`}
            disabled={isDisabled}>
            {title}
        </button>
    );
};

export default AuctionControlButton;

import React, { FC, ReactElement } from 'react';

import classes from './dropdown.module.scss';

export interface IDropdownItem {
    children: ReactElement | ReactElement[];
}

const DropdownItem: FC<IDropdownItem> = ({ children }) => {
    return <div className={classes.option}>{children}</div>;
};

export default DropdownItem;

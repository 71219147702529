import ListTableCell from 'Common/ListTable/ListTableCell';
import ListTableRow from 'Common/ListTable/ListTableRow';
import RefreshLink from 'Common/ListTable/RefreshLink';
import React, { FC } from 'react';

interface IProxyBidsProps {
    onRefresh: () => void;
}

const ProxyBidsHeader: FC<IProxyBidsProps> = ({ onRefresh }) => {

    const handleOnRefresh = (e: React.MouseEvent<HTMLAnchorElement>): void => {         
         
        e.preventDefault();

        onRefresh();
    }; 

    return (
        <ListTableRow className='header'>
            <ListTableCell value1="Lot" value2="&nbsp;" />
            <ListTableCell value1="Make" value2="Reg. Date" />
            <ListTableCell value1="Model" value2="Reg." />
            <ListTableCell value1="Bid Value" value2="Body Style" />
            <ListTableCell value1={<RefreshLink handleOnRefresh={handleOnRefresh} />} value2="Odometer" />
        </ListTableRow>
    );
};

export default ProxyBidsHeader;

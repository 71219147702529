import { useLogoutUser } from 'Pages/Account/account.service';
import React, { FC } from 'react';

import DialogContainer from '../DialogContainer/DialogContainer';
import classes from './existing-session-dialog.module.scss';

interface IHasSessionDialog {
    hasSession: boolean;
    handleOnClickClose: () => void;
    isLoggedIn?: boolean;
    username?: string;
}

interface ILoggedInHasSession {
    handleOnClickClose: () => void;
    username: string;
}

interface INotLoggedInHasSession {
    handleOnClickClose: () => void;
}

const LoggedInHasSession: FC<ILoggedInHasSession> = ({ handleOnClickClose, username }) => {
    const handleLogout = useLogoutUser();

    const handleLogoutOnClick = async () => 
    {
        await handleLogout();
        window.location.href = '';
    };

    return (
        <>
            <p>
                We detect that there is already an active session of Simulcast using the credentials you have entered, you will therefore be unable to join any
                sale.
                <br />
                To make this your primary session please logout and log back in.
            </p>
            <button style={{ backgroundColor: 'rgba(112, 184, 80, 1)' }} onClick={handleLogoutOnClick}>
                Logout
            </button>
            <button onClick={handleOnClickClose}>Close</button>
        </>
    );
};

const NotLoggedInHasSession: FC<INotLoggedInHasSession> = ({ handleOnClickClose }) => {
    return (
        <>
            <p>
                We detect that there is already an active session of Simulcast using the credentials you have entered, and therefore unable to log you in,
                please contact buyer services 0333 136 1750 for further support
            </p>
            <button onClick={handleOnClickClose}>Close</button>
        </>
    );
};

const HasSessionDialog: FC<IHasSessionDialog> = ({ hasSession, handleOnClickClose, isLoggedIn = false, username = '' }) => {
    return (
        <DialogContainer isOpen={hasSession} handleClose={handleOnClickClose}>
            <div className={classes['session-dialog']}>
                <h2>Active Session</h2>
                <div className={classes['container']}>
                    {isLoggedIn ? (
                        <LoggedInHasSession handleOnClickClose={handleOnClickClose} username={username} />
                    ) : (
                        <NotLoggedInHasSession handleOnClickClose={handleOnClickClose} />
                    )}
                </div>
            </div>
        </DialogContainer>
    );
};

export default HasSessionDialog;

import FatedStatusWithCurrency from 'Common/FatedStatusWithCurrency/FatedStatusWithCurrency';
import Heading from 'Common/Heading/Heading';
import InspectionReportLink from 'Common/Sales/InspectionReportLink';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';
import AutoHeightScrollbar from 'Common/ScrollbarTab/AutoHeightScrollbar';
import noImageURL from 'img/content/no-image.svg';
import React, { FC } from 'react';

import NoResult from '../../../../Common/NoResult/NoResult';
import headerClasses from '../MainSaleHeader/auction-header.module.scss';
import classes from './winninglotmobileview.module.css';

interface IWinningLotMobileViewProps {
    winningLots: IApiLotViewModel[];
    refNum?: string;
}

const WinningLotMobileView: FC<IWinningLotMobileViewProps> = ({ winningLots = [], refNum }) => {

    const renderLotCard = (lot: IApiLotViewModel) => {
        const card = (
            <div className={classes['winninglot-container']} key={lot.workOrder}>
                <div className={`${classes['img-container']}`}>
                    <div className={`${classes['lot-label']}`}>Lot {lot.lot}:</div>
                    <div>
                        <img loading="lazy" src={lot.lightboxImageUrl ?? noImageURL} onError={({ currentTarget }) => (currentTarget.src = noImageURL)} />
                    </div>
                    <p className={headerClasses['vehicle-reg']}>{lot.regNo}</p>
                </div>
                <div className={`${classes['heading-container']}`}>
                    <div className={`${classes['heading']}`}>
                        <Heading size="small" color="blue">
                            {lot.make} {lot.model} {lot.derivative}
                            <span>
                                {lot.mileage} {lot.mileageText}
                            </span>
                        </Heading>
                    </div>
                    <div className={`${classes['pricing']}`}>
                        <FatedStatusWithCurrency price={lot.hammer ?? 0} itemStatus={lot.status} />
                        <span>
                            <InspectionReportLink variant="small" linkUrl={lot.inspectionURL} />
                        </span>
                    </div>
                </div>
            </div>
        );

        return card;
    };

    const winnigLotDetails = () => {
        return (
            <>
                <h4>Total Purchases: {winningLots.reduce((sum: number, b: IApiLotViewModel) => sum + (b.hammer || 0), 0).toCurreny()}</h4>
                <AutoHeightScrollbar>
                    <div className={classes['scroll-container']}>
                        {winningLots.map((lot: IApiLotViewModel, i) => {
                            return renderLotCard(lot);
                        })}
                    </div>
                </AutoHeightScrollbar>
            </>
        );
    };

    if (!refNum)
        return (
            <NoResult>
                <span>To view your winning lots please select a sale</span>
            </NoResult>
        );

    return winningLots.length > 0 ? (
        winnigLotDetails()
    ) : (
        <NoResult>
            <span>NO PURCHASES</span>
        </NoResult>
    );
};

export default WinningLotMobileView;

import Heading from 'Common/Heading/Heading';
import isLandscape from 'Common/Helpers/OrientationCheck';
import { useLogoutUser } from 'Pages/Account/account.service';
import React, { FC, useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { browser_session } from 'services/browser-storage';

import classes from './terms-and-conditions.module.scss';

interface ITermsAndConditions {
    handleAcceptReject(value: boolean): void;
}

const websiteRedirectUrl = process.env.REACT_APP_EXIT_SURVEY_LINK || '';

const TermsAndConditions: FC<ITermsAndConditions> = ({ handleAcceptReject }) => {
    const handleLogout = useLogoutUser();

    const handleAcceptRejectClicked = (accept: boolean): void => {
        browser_session.set('__TnC', accept ? 'true' : 'false');

        handleAcceptReject(accept);

        if (!accept) {
            handleLogout();

            if (websiteRedirectUrl) {
                window.location.href = websiteRedirectUrl;
            }
        }
    };

    useEffect(() => {
        if (isIOS && window.visualViewport!.pageTop > 0 && isLandscape())
            window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <div className={classes['main-center']}>

                <Heading color={'dark-blue'}>
                    Terms and Conditions for Online Bidding & Sales
                </Heading>
                <div className={classes['contents']}>
                    <p>
                        Today&apos;s auction will be conducted in accordance with Manheim&apos;s Standard Auction Terms and Conditions. These are available &nbsp;
                        <a className={classes['link']} href="https://www.manheim.co.uk/legal" rel="noreferrer" target="_blank">
                            here
                        </a>.
                    </p>
                    <br />
                    <p>
                        If purchasing a Car/LCV, these are subject to the following the applicable Terms and Conditions are available &nbsp;
                        <a className={classes['link']} href="https://www.manheim.co.uk/legal/terms-and-conditions" rel="noreferrer" target="_blank">
                            here
                        </a>.
                    </p>
                    <br />
                    <p>
                        If purchasing a HGV/Industrial asset, these are subject to the following the applicable Terms and Conditions are available &nbsp;
                        <a className={classes['link']} href="https://www.manheim.co.uk/legal/industrial-terms-and-conditions" rel="noreferrer" target="_blank">
                            here
                        </a>.
                    </p>
                    <br />
                    <p>
                        By bidding today, you acknowledge that you have read all of the aforementioned applicable Terms and Conditions (including the pertinent provisions referred to at the outset of the applicable Standard Auction Terms and Conditions) and agree to be bound by them. You are reminded to review all information made available regarding the vehicle prior to bidding, including the Inspection Report. Please post any questions and we will do our best to answer them as the sale proceeds. We will give you all every opportunity to submit your bids but would ask that you do use the &quot;May bid&quot; button.
                        <br /> <br />
                        A selection of vehicles in today&apos;s sale qualify for our SureCheck assurance product(s). Cover is provided where the purchase price and all fees have been paid in full. The auctioneer(s) will announce known faults that Manheim are aware of, however we would remind you that vehicles without our Sure Check product are sold as seen without any promise of quality or condition. All non-runners are sold as seen with no comebacks; we will relay any known faults but cannot guarantee any other issues. In all instances you are responsible for ensuring any vehicles you purchase are roadworthy and can be legally used on the road including arranging road tax and insurance prior to driving.
                        <br /> <br />
                        Please make yourself aware of the location of all vehicles offered in today&apos;s sale before bidding as they may not necessarily be at the host auction. We will announce location if it is different.
                        <br /> <br />
                        The hammer price plus all relevant fees must be paid for by BACS/EFT payment by 5pm tomorrow.
                    </p>
                    <br />
                </div>
                <div className={classes['inner-form']}>
                    <button className={`${classes['lozenge']} ${classes['accept-button']}`} title="Accept" id="accept" onClick={() => handleAcceptRejectClicked(true)}>
                        Accept
                    </button>
                    <button className={`${classes['lozenge']} ${classes['reject-button']}`} title="Reject" id="reject" onClick={() => handleAcceptRejectClicked(false)}>
                        Reject
                    </button>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;

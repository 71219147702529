import { useEffect, useState } from 'react';

import { useIsVendor } from '../auction-controls.hooks';

export const useIsButtonDisabled = (isLooker: boolean, isPaused: boolean, isBlocked: boolean, currentLot: string, askingPrice: number, currentBid:number): boolean => {
    const [isDisabled, setIsDisabled] = useState(false);
    const isVendor = useIsVendor();
    const isVendorPrepareToSell = () => isVendor && (!currentLot || !currentBid);

    useEffect(() => {
        setIsDisabled(isPaused || isBlocked || !currentLot || !askingPrice || isLooker || isVendorPrepareToSell() );
    }, [isLooker, isPaused, isBlocked, currentLot, askingPrice]);

    return isDisabled;
};

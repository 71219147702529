import isLandscape from 'Common/Helpers/OrientationCheck';
import React, { useEffect, useState } from 'react';

export const useIsMobile = (): boolean => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    let clearTime: ReturnType<typeof setTimeout>;

    const shouldShowMobileView = (): boolean => {
        const width = window.innerWidth;
        const userAgent = navigator.userAgent.toLowerCase();
        const mobileUserAgents = ['iphone', 'android', 'mobile', 'ipad', 'macintosh'];

        // test width
        if (width <= 1024 && !isLandscape()) return true;

        // test user agent
        for (const mobileUserAgent of mobileUserAgents) {
            if (userAgent.includes(mobileUserAgent)) {
                if (window.innerWidth < window.innerHeight) {
                    const root = document.getElementById('root');

                    if (root) {

                        root.style.marginLeft = 'auto';

                        root.style.marginRight = 'auto';
                    }

                    return true;
                }
            }
        }

        return false;
    };

    const handleIsMobile = () => {
        clearTime = setTimeout(() => {
            setIsMobile(shouldShowMobileView());

            if (window.visualViewport!.pageTop > 0 && isLandscape())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            
        }, 250);
    } 

    useEffect(() => {
        handleIsMobile();

        window.addEventListener('resize', handleIsMobile);

        return () => {
            clearTimeout(clearTime);
            window.removeEventListener('resize', handleIsMobile);
        }
    }, []);

    return isMobile;
}
import { BiddingHistory, biddingHistorySelectors } from 'Features/BiddingHistory/bidding-details.slice';
import { useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

export const useShowActiveBiddersCount = (refNum: string): boolean => {

    const { messages = [] } = useSelector((state: AppState) => biddingHistorySelectors.selectById(state.history, refNum)) ?? {} as BiddingHistory;

    //TODO: Find a better way to resolve it as can't rely on Strings like "Asking Price" accordingly to Backend

    const isShowCount = messages.some( x => x.message.toLowerCase().includes("asking price"));

    return isShowCount;
};
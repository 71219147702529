import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { Sale, sortSalesByDateTimeBranch } from 'Domain/Entities/sale.entity';
import { LotFieldUpdate } from 'Features/BiddingHistory/bidding-details.slice';
import { IApiVehicleDetailsViewModel } from 'Features/MainSale/Components/AuctionResults/AuctionResults.container';
import { ILiveClientsOnline } from 'Features/MainSale/Components/ClientsOnline/ClientsOnline.container';
import http from 'Infrastructure/HttpService/http.service';
import { AppDispatch, AppState } from 'services/redux-store';
import { setObjectKeyValue } from 'utils/object.utils';

export type SaleAuctionResults = {
    refNum: string;
    saleAuctionResults: IApiVehicleDetailsViewModel[];
};

const auctionResultsAdapter = createEntityAdapter<SaleAuctionResults>({
    selectId: (sale) => sale.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

export type SaleClientsOnline = {
    refNum: string;
    saleClientsOnline: ILiveClientsOnline[];
};

export interface VendorSaleClientsOnline {
    refNum: string;
    noOfClients: number;
}

export interface IActiveAndManheimBidder {
    auctionNo: string;
    bidderId: string;
    isManheimBidder: boolean;
}

export type ActiveAndManheimBidders = {
    refNum: string;
    activeBidders: string[];
    manheimBidders: string[];
}

const clientsOnlineAdapter = createEntityAdapter<SaleClientsOnline>({
    selectId: (saleClientsOnline) => saleClientsOnline.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

const vendorClientsOnlineAdapter = createEntityAdapter<VendorSaleClientsOnline>({
    selectId: (vendorSaleClientsOnline) => vendorSaleClientsOnline.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

const activeAndManheimBiddersAdapter = createEntityAdapter<ActiveAndManheimBidders>({
    selectId: (activeBidders) => activeBidders.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

const proxyClientsOnlineAdapter = createEntityAdapter<SaleClientsOnline>({
    selectId: (saleProxyClientsOnline) => saleProxyClientsOnline.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

export type JoinedSale = {
    refNum: string;
    buyerNumber: string;
};

const joinedSalesAdapter = createEntityAdapter<JoinedSale>({
    selectId: (joinedSale) => joinedSale.refNum
});

export type SaleLots = {
    refNum: string;
    lotsList: any[];
    currentLot: any;
};

const saleAdapter = createEntityAdapter<Sale>({
    selectId: (sale) => sale.refNum,
    sortComparer: (a, b) => sortSalesByDateTimeBranch(a, b)
});

const saleLotsAdapter = createEntityAdapter<SaleLots>({
    selectId: (sale) => sale.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

//Think this will belong to common but or next to the feature AuctionSale.container?
export type AuctionsState = {
    auctionResults: EntityState<SaleAuctionResults>;
    clientsOnline: EntityState<SaleClientsOnline>;
    vendorSaleClientsOnline: EntityState<VendorSaleClientsOnline>,
    joinedSales: EntityState<JoinedSale>;
    proxyClientsOnline: EntityState<SaleClientsOnline>;
    saleLots: EntityState<SaleLots>;
    sales: EntityState<Sale>;
    salesIsLoading: boolean;
    activeAndManheimBidders: EntityState<ActiveAndManheimBidders>;
};

const initialState: AuctionsState = {
    auctionResults: auctionResultsAdapter.getInitialState(),
    clientsOnline: clientsOnlineAdapter.getInitialState(),
    vendorSaleClientsOnline: vendorClientsOnlineAdapter.getInitialState(),
    joinedSales: joinedSalesAdapter.getInitialState(),
    proxyClientsOnline: proxyClientsOnlineAdapter.getInitialState(),
    sales: saleAdapter.getInitialState(),
    saleLots: saleLotsAdapter.getInitialState(),
    salesIsLoading: false,
    activeAndManheimBidders: activeAndManheimBiddersAdapter.getInitialState()
};

export const getSalesList: any = createAsyncThunk<Sale[], void, { dispatch: AppDispatch; state: AppState; getState: () => AppState }>(
    'auctions/getSalesList',
    async (_, { getState }) => {
        try {
            const {
                account: { bearerToken = '' }
            }: AppState = getState();
            const response = await http.post<Sale[]>(
                'api/sale/sales',
                {},
                {
                    headers: {
                        Authorization: `Bearer ${bearerToken}`
                    }
                }
            );
            const { data = [] } = response;

            return data;
        } catch (error) {
            window.logger.error(error);

            return [] as Sale[];
        }
    }
);

export const getSaleLotsList: any = createAsyncThunk<any, string, { dispatch: AppDispatch; state: AppState; getState: () => AppState }>(
    'auctions/getSaleLotsList',
    async (refNum, { getState }) => {
        try {
            const {
                account: { bearerToken = '' }
            }: AppState = getState();
            const response = await http.get(`api/lot/lots?refNum=${refNum}`, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`
                }
            });
            const { data } = response;

            return { data, refNum };
        } catch (error) {
            window.logger.error(error);

            //toastr.error('Error', 'An error occurred getting lots list');
        }
    }
);

export const getUpdatedSaleLotsWatchlist: any = createAsyncThunk<any, string, { dispatch: AppDispatch; state: AppState; getState: () => AppState }>(
    'auctions/getUpdatedSaleLotsWatchlist',
    async (refNum, { getState }) => {
        try {
            const {
                account: { bearerToken = '' },
                auctions: { saleLots }
            }: AppState = getState();

            const workOrders = saleLots.entities[refNum]?.lotsList
                .filter((lot) => lot.status !== 1 || (lot.status !== 2 && lot.status !== 3))
                .map((lot) => lot.workOrder);

            if (!workOrders) return;

            const branchIdAuctionId = refNum.split('/');

            const response = await http.post(`/api/sale/${branchIdAuctionId[0]}/${branchIdAuctionId[1]}/lots/watchlist`, workOrders, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`
                }
            });

            const { branchId, auctionId, workOrderWatchlistStatus } = response.data;

            return { workOrderWatchlistStatus, refNum };
        } catch (error) {
            window.logger.error(error);

            //toastr.error('Error', 'An error occurred getting lots list');
        }
    }
);

const auctionsSlice = createSlice({
    name: 'auctions',
    initialState,
    reducers: {
        addJoinedSale: (state: AuctionsState, { payload }: { payload: JoinedSale }) => {
            joinedSalesAdapter.addOne(state.joinedSales, payload);

            saleLotsAdapter.addOne(state.saleLots, { refNum: payload.refNum, lotsList: [], currentLot: {} } as SaleLots);

            clientsOnlineAdapter.addOne(state.clientsOnline, { refNum: payload.refNum, saleClientsOnline: [] });

            vendorClientsOnlineAdapter.addOne(state.vendorSaleClientsOnline, { refNum: payload.refNum, noOfClients: 0 });

            proxyClientsOnlineAdapter.addOne(state.proxyClientsOnline, { refNum: payload.refNum, saleClientsOnline: [] });

            activeAndManheimBiddersAdapter.addOne(state.activeAndManheimBidders, { refNum: payload.refNum, activeBidders: [], manheimBidders: [] });

            return state;
        },
        leaveJoinedSale: (state: AuctionsState, { payload }: { payload: JoinedSale }) => {
            const { refNum } = payload;

            saleLotsAdapter.removeOne(state.saleLots, refNum);

            auctionResultsAdapter.removeOne(state.auctionResults, refNum);

            clientsOnlineAdapter.removeOne(state.clientsOnline, refNum);

            vendorClientsOnlineAdapter.removeOne(state.vendorSaleClientsOnline, refNum);

            proxyClientsOnlineAdapter.removeOne(state.proxyClientsOnline, refNum);

            joinedSalesAdapter.removeOne(state.joinedSales, refNum);

            activeAndManheimBiddersAdapter.removeOne(state.activeAndManheimBidders, refNum);

            return state;
        },
        resetActiveAndManheimBidders: (state: AuctionsState, { payload }: { payload: string }) => {
            const update = { id: payload, changes: { activeBidders: [], manheimBidders: [] } }

            activeAndManheimBiddersAdapter.updateOne(state.activeAndManheimBidders, update);
        },
        setActiveAndManheimBidders: (state: AuctionsState, { payload }: { payload: IActiveAndManheimBidder }) => {
            const { auctionNo, bidderId, isManheimBidder } = payload;

            const { activeBidders, manheimBidders } = activeAndManheimBiddersSelectors.selectById(state.activeAndManheimBidders, auctionNo) as ActiveAndManheimBidders;

            const isExistsInActiveBidders = activeBidders.includes(bidderId);

            const isExistsInManheimBidders = manheimBidders.includes(bidderId);

            if (isManheimBidder && !isExistsInManheimBidders) {
                const update = { id: auctionNo, changes: { manheimBidders: [...manheimBidders, bidderId] } }

                activeAndManheimBiddersAdapter.updateOne(state.activeAndManheimBidders, update);
            }
            else if (!isExistsInActiveBidders && !isExistsInManheimBidders && !isManheimBidder) {
                const update = { id: auctionNo, changes: { activeBidders: [...activeBidders, bidderId] } }

                activeAndManheimBiddersAdapter.updateOne(state.activeAndManheimBidders, update);
            }

            return state;
        },
        setSaleClientsOnline: (state: AuctionsState, { payload }) => {
            const { refNum, clientsOnline } = payload;

            const update = { id: refNum, changes: { saleClientsOnline: clientsOnline } };

            clientsOnlineAdapter.updateOne(state.clientsOnline, update);

            return state;
        },
        setVendorSaleClientsOnline: (state: AuctionsState, { payload }) => {

            const { refNum, noOfClients } = payload;

            const update = { id: refNum, changes: { noOfClients: noOfClients } };

            vendorClientsOnlineAdapter.updateOne(state.vendorSaleClientsOnline, update);

            return state;
        },
        setSaleProxyClientsOnline: (state: AuctionsState, { payload }) => {
            const { refNum, clientsOnline } = payload;

            const update = { id: refNum, changes: { saleClientsOnline: clientsOnline } };

            proxyClientsOnlineAdapter.updateOne(state.proxyClientsOnline, update);

            return state;
        },
        removeSaleLot: (state: AuctionsState, { payload }: { payload: SaleLots }) => {
            saleLotsAdapter.removeOne(state.saleLots, payload.refNum);

            return state;
        },
        removeSales: (state, { payload }) => {
            saleAdapter.removeMany(state.sales, payload);

            return state;
        },
        setSaleCurrentLot: (state: AuctionsState, { payload }) => {
            const { refNum } = payload;

            const entity = state.saleLots.entities[refNum];

            if (entity) {
                try {
                    const lot = entity?.lotsList?.find((x) => x.lot === payload.lot) || {};

                    const changes = {
                        currentLot: Object.assign({ lotDetails: lot }, payload, { lotDetails: { ...payload.lotDetails, watching: lot.watching } })
                    };

                    saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes });
                } catch (error) {
                    window.logger.error(error);
                }
            }

            return state;
        },
        setSaleLotRetracted: (state: AuctionsState, { payload }) => {
            const { refNum } = payload;

            const entity = state.saleLots.entities[refNum];

            if (entity && payload?.status && payload.status === 4) {
                const changes = {
                    currentLot: undefined
                };

                saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes });
            }

            return state;
        },
        setSaleLotStatus: (state, { payload }) => {
            const LOT_RETRACTED = 4;
            const LOT_STATUS = [1, 2, 3];
            const { refNum, lot: lotNo, status } = payload;

            const entity = state.saleLots.entities[refNum];

            if (entity) {
                const { currentLot, lotsList = [] } = entity;
                const lot = lotsList.find((x) => x.lot === lotNo);
                const index = lotsList.indexOf(lot);

                //Check the last lot in lot list to clear current lot
                let lastLot = lotsList.filter((x) => x.status !== 1 && x.status !== 2 && x.status !== 3).slice(-1)[0];

                if (lastLot) {
                    lastLot = lastLot.lot;
                }

                //Do not update the lot status if it is retracted as this throws the lot out of sequence message out.
                //The alternative would be to add the status to the isLotUpdateOutOfSequence() and check for status 0 & status 4.
                if (LOT_STATUS.includes(status)) {
                    lotsList[index] = setObjectKeyValue(lot, 'status', status);
                }

                const changes = {
                    currentLot: status === LOT_RETRACTED || (lastLot === lotNo && LOT_STATUS.includes(status)) ? undefined : currentLot,
                    lotsList
                };

                saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes });
            }

            return state;
        },
        setSaleCurrentLotExtra: (state: AuctionsState, { payload }) => {
            const { refNum } = payload;

            const entity = state.saleLots.entities[refNum];

            if (entity) {
                const changes = {
                    currentLot: Object.assign(entity.currentLot, payload)
                };

                saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes });
            }

            return state;
        },
        setSaleCurrentLotLights: (state: AuctionsState, { payload }) => {
            const { refNum } = payload;

            const entity = state.saleLots.entities[refNum];

            if (entity) {
                const apiLot = entity?.lotsList?.find((lot) => lot.refNum === refNum) || {};

                const changes = {
                    currentLot: Object.assign({ lotDetails: apiLot }, entity.currentLot || {}, { lightStatus: payload })
                };

                saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes });
            }

            return state;
        },
        setSaleCurrentLotFieldUpdate: (state: AuctionsState, { payload }: { payload: LotFieldUpdate }) => {
            const { refNum, fieldName, value } = payload;

            const entity = state.saleLots.entities[refNum];

            if (entity && entity.currentLot) {
                const changes = {
                    currentLot: setObjectKeyValue(entity.currentLot, fieldName, value)
                };

                saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes });
            }

            return state;
        },
        updateSaleMediaSource: (state: AuctionsState, { payload }: { payload: { refNum: string; siteId: number; mediaId: number; vidUrl: string } }) => {
            const { refNum, siteId, vidUrl } = payload;

            const entity = state.sales.entities[refNum];

            if (entity) {
                const changes = {
                    vidUrl
                };

                saleAdapter.updateOne(state.sales, { id: refNum, changes });
            }

            return state;
        },
        updateJoinedSale: (state: AuctionsState, { payload }: { payload: JoinedSale }) => {
            joinedSalesAdapter.upsertOne(state.joinedSales, payload);

            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSalesList.pending, (state: AuctionsState, { payload }) => {
            state.salesIsLoading = true;

            return state;
        }),        
        builder.addCase(getSalesList.fulfilled, (state: AuctionsState, { payload }) => {
            state.salesIsLoading = false;

            try {
                saleAdapter.removeAll(state.sales);

                saleAdapter.upsertMany(state.sales, payload);
            } catch (e) {
                window.logger.error('sale error ', e);
            }

            return state;
        }),
        builder.addCase(getSaleLotsList.fulfilled, (state, { payload }) => {
            state.salesIsLoading = false;

            const { refNum, data } = payload;

            const entity = state.saleLots.entities[refNum];

            saleLotsAdapter.upsertOne(state.saleLots, { refNum, lotsList: data, currentLot: entity?.currentLot ?? {} });

            return state;
        }),
        builder.addCase(getUpdatedSaleLotsWatchlist.fulfilled, (state: AuctionsState, { payload }) => {
            if (payload) {
                const { refNum, workOrderWatchlistStatus } = payload;

                const entity = state.saleLots.entities[refNum];

                if (entity) {
                    const lotsList = entity.lotsList.map((lot) => {
                        const workOrder = lot.workOrder!.toString();

                        lot.watching = workOrderWatchlistStatus[workOrder];

                        return lot;
                    });

                    saleLotsAdapter.updateOne(state.saleLots, { id: refNum, changes: { lotsList } });
                }
            }

            return state;
        });
    }
});

export const {
    addJoinedSale,
    leaveJoinedSale,
    removeSales,
    setSaleClientsOnline,
    setSaleCurrentLot,
    setSaleLotRetracted,
    setSaleCurrentLotExtra,
    setSaleCurrentLotLights,
    setSaleCurrentLotFieldUpdate,
    setSaleLotStatus,
    setSaleProxyClientsOnline,
    updateJoinedSale,
    updateSaleMediaSource,
    setVendorSaleClientsOnline,
    setActiveAndManheimBidders,
    resetActiveAndManheimBidders
} = auctionsSlice.actions;

export const activeAndManheimBiddersSelectors = activeAndManheimBiddersAdapter.getSelectors();

export const auctionResultSelectors = auctionResultsAdapter.getSelectors();

export const clientsOnlineSelectors = clientsOnlineAdapter.getSelectors();

export const vendorClientsOnlineSelectors = vendorClientsOnlineAdapter.getSelectors();

export const joinedSalesSelectors = joinedSalesAdapter.getSelectors();

export const proxyClientsOnlineSelectors = proxyClientsOnlineAdapter.getSelectors();

export const saleSelectors = saleAdapter.getSelectors();

export const saleLotsSelectors = saleLotsAdapter.getSelectors();

export default auctionsSlice.reducer;

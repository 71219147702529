import iconUrl from 'img/icon-report-2.png';
import React, { FC } from 'react';

import classes from './inspection-report-link.module.scss';

interface IInspectionReportLink {
    linkUrl: string | undefined;
    variant?: 'xs' | 'small' | 'medium' | 'large';
    text?: string;
}

const InspectionReportLink: React.FC<IInspectionReportLink> = ({ linkUrl, text, variant = 'medium' }) => {
    if (!linkUrl) {
        return null;
    }

    return (
        /*   GTM: inspection-report-trigger class required for GA. */
        <a
            href={linkUrl}
            title={'View Inspection Report'}
            target="_blank"
            rel="noopener noreferrer"
            className={`${classes['inspection-link']} ${classes['icon-text']} ${classes[variant]} inspection-report-trigger`}>
            <img src={iconUrl} />
            {text && (<span>{text}</span>)}
        </a>
    );
};

export default InspectionReportLink;

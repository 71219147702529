import safelyParseJson from 'Common/Helpers/SafelyParseJsonHelper';
import React, { FC } from 'react';

import classes from './announcement-dropdown.module.scss';

interface Announcement {
    type: string;
    title?: string;
    description?: string;
    link?: string;
}

const AnnouncementDialog: FC = () => {
    const announcements = safelyParseJson<Announcement[]>(process.env.REACT_APP_ANNOUNCEMENTS || '[]');

    if (!announcements.length) {
        return <div> No Announcements </div>;
    }

    const navigateToTheLink = (link = '') => {
        if (link.length)
            window.open(link, '_blank');
    }

    return (
        <div className={classes.container}>
            {
                announcements.map(({ type = 'News', title = '', description = '', link = '' }, index) => (
                    <div key={index} className={classes['announcement-row']} onClick={() => navigateToTheLink(link)}>
                        <div className={classes['announcement-type']}>{type}</div>
                        <b>{title}</b>
                        <div className={classes['announcement-description']}>{description}</div>
                        {
                            link && <span className={classes['btn-view-now']} title='View Now'>VIEW NOW</span>
                        }
                    </div>
                ))
            }
            <a href="https://www.manheim.co.uk/news" target="_blank" rel="noreferrer" className={classes['btn-show-more']} title='Show More'>SHOW MORE</a>
        </div>
    );
};

export default AnnouncementDialog;
import { IApiVehicleImageViewModel } from 'Common/Sales/Sales.interface';
import noImageURL from 'img/content/no-image.svg';
import prevIconUrl from 'img/icon-pager-left.png';
import nextIconUrl from 'img/icon-pager-right.png';
import { setIsGalleryOpen } from 'Pages/Common/buyer-vendor.slice';
import React, { FC, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'services/redux-store';

import classes from './vehicle-images.module.scss';

interface IVehicleImageDisplayProps {
    images?: IApiVehicleImageViewModel[];
    isSlideShow: boolean;
}

const VehicleImages: FC<IVehicleImageDisplayProps> = ({ images, isSlideShow }) => {
    const dispatch: AppDispatch = useDispatch();

    const [imageIndex, setImageIndex] = React.useState(0);
    const totalImages = images?.length ?? 0;

    const setGalleryActive = () => {
        if (images && images.length > 0) {
            dispatch(setIsGalleryOpen(true));
        }
    };

    React.useEffect(() => {
        setImageIndex(0);
    }, [images]);

    const nextImage = () => {
        setImageIndex((imageIndex) => (imageIndex + 1) % totalImages);
    };

    const previousImage = () => {
        setImageIndex((imageIndex) => {
            let nextValue = imageIndex - 1;

            if (nextValue < 0) {
                nextValue = totalImages - 1;
            }

            return nextValue;
        });
    };

    React.useEffect(() => {
        if (isSlideShow) {
            const interval = setInterval(() => {
                nextImage();
            }, 5000);
            return () => clearInterval(interval);
        }
    }, [isSlideShow]);

    const currentImage = images && images[imageIndex];

    return (
        <>
            <div className={classes['mini-gallery']}>
                <div className={classes['sides']}>
                    <div className={classes['mini-gallery-buttons']}>
                        <button className={`${classes['gallery-button']} gtm-gallery-trigger`} onClick={setGalleryActive}>
                            Gallery
                        </button>
                        {currentImage?.photoCaption && (
                            <span className={classes['caption-container']}>
                                <span className={classes['caption']}>{currentImage?.photoCaption}</span>
                            </span>
                        )}
                    </div>
                    {totalImages > 0 && (
                        /*GTM: gallery-page-trigger class required for GA. */
                        <div className={classes['mini-gallery-controls']}>
                            <a onClick={previousImage} className={`${classes['nav-button']} gallery-page-trigger`} title="Prev Photo">
                                {'<'}
                            </a>
                            <span className={classes['spacer']}></span>
                            <a onClick={nextImage} className={`${classes['nav-button']} ${classes['right']} gallery-page-trigger`} title="Next Photo">
                                {'>'}
                            </a>
                        </div>
                    )}
                    <img src={currentImage?.imageUrl ?? noImageURL} className={classes['slide']} />
                </div>
            </div>
        </>
    );
};

export default VehicleImages;

import React, { createContext, FC, ReactNode, useContext, useState } from 'react';

import ConnectionDisconnectDialog from './Components/ConnectionDisconnectDialog/ConnectionDisconnectDialog';
import HasSessionDialog from './Components/ExistingSessionDialog/ExistingSessionDialog';
import LogoutDialog from './Components/LogoutDialog/LogoutDialog';
import { DIALOG_TYPES } from './Enums/dialog-types.enum';


const Dialog_Components = {
    [DIALOG_TYPES.LOGOUT_DIALOG]: LogoutDialog,
    [DIALOG_TYPES.SESSION_DIALOG]: HasSessionDialog,
    [DIALOG_TYPES.CONNECTION_DISCONNECT_DIALOG]: ConnectionDisconnectDialog
};

interface IStore {
    type: DIALOG_TYPES | null;
    props?: any;
}
interface IDialogManagerProvider {
    children: ReactNode;
}

const DialogManagerProvider: FC<IDialogManagerProvider> = ({ children }) => {

    const [store, setStore] = useState<IStore>();

    const showModal = (type: DIALOG_TYPES, props?: any) => {
        setStore({ ...store, type, props });
    }

    const hideModal = () => {
        setStore({ ...store, type: null, props: {} })
    }

    const render = () => {
        const { type, props = {} } = store || {};

        if (!type) return null;

        const Component = Dialog_Components[type];

        return <Component {...props} />;
    };

    return (
        <DialogManagerContext.Provider value={{ showModal, hideModal, store }}>
            {render()}
            {children}
        </DialogManagerContext.Provider>
    );
};

interface IDialogManagerContext {
    showModal: (type: DIALOG_TYPES, props?: any) => void,
    hideModal: () => void,
    store: any
}


const DialogManagerContext = createContext<IDialogManagerContext>({
    showModal: (type: DIALOG_TYPES, props?: any) => {
        return
    },
    hideModal: () => {
        return
    },
    store: {}
})

export const useDialogManagerContext = (): IDialogManagerContext => useContext(DialogManagerContext);

export default DialogManagerProvider;
import Heading from 'Common/Heading/Heading';
import React, { FC, useEffect } from 'react';

import classes from '../account.module.scss';
import Promo from '../Components/Promo';

interface IAuthenticationRedirect {
    email: string;
    sessionId: string;
    handleLoginRedirect: (username: string, sessionId: string) => void;
}

const AuthenticateRedirect: FC<IAuthenticationRedirect> = ({ email, sessionId, handleLoginRedirect }) => {
    useEffect(() => {
        const tryLogUserIn = () => {
            handleLoginRedirect(email, sessionId);
        };

        tryLogUserIn();
    }, []);

    return (
        <>
            <div className={classes.inner}>
                <Heading size={'large'} color={'dark-blue'}>
                    Welcome to
                    <br />
                    Manheim Simulcast
                </Heading>

                <div className="form">
                    <div className={classes.row}>
                        <br />
                    </div>
                    <div className={classes.row}>
                        <p className={classes['input-text']}>Loading...</p>
                    </div>

                    <div className={classes.row}>
                        <br />
                        <p className={classes.options}>
                            <br />
                        </p>
                    </div>
                </div>
            </div>

            <Promo />
        </>
    );
};

export default AuthenticateRedirect;

import Heading from 'Common/Heading/Heading';
import React from 'react';

import classes from './welcome-screen.module.css';

const WelcomeScreen = () => {
    return (
        <div className={classes.welcome_screen} id="welcome">
            <div className={classes.wrapper}>
                <div className={classes.inner}>
                    <Heading size={'extra-large'} color={'dark-blue'}>Welcome to Manheim Simulcast</Heading>
                    <Heading size={'medium'} color={'blue'}>Enter a sale by selecting from left.</Heading>
                </div>
            </div>
        </div>
    );
};

export default WelcomeScreen;

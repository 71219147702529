import { createAction, createSlice } from '@reduxjs/toolkit';

export interface IVehicleImage {
    imageUrl: string;
    imgIndex: number;
    isMain: boolean;
    lightboxImageUrl: string;
    mediaType?: any;
    photoCaption: string;
}

export type BuyerState = {
    isGalleryOpen: boolean;
    vehicleImages: Array<IVehicleImage>;
};

const setGalleryImagesAction = createAction<any>('auctions/setSaleCurrentLotExtra');

const initialState: BuyerState = {
    isGalleryOpen: false,
    vehicleImages: new Array<any>()
};

const buyerSlice = createSlice({
    name: 'buyer',
    initialState,
    reducers: {
        setIsGalleryOpen: (state: BuyerState, { payload }: { payload: boolean }) => {
            return Object.assign(state, { isGalleryOpen: payload });
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setGalleryImagesAction, (state, { payload }) => {
            const { vehicleImages } = payload;

            return Object.assign(state, { isGalleryOpen: false, vehicleImages: [...vehicleImages] });
        });
    }
});

export const { setIsGalleryOpen } = buyerSlice.actions;

export default buyerSlice.reducer;

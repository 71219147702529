import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useWatchSignalRConnectionState = (
    connection: HubConnection | undefined
): { connectionStatus: HubConnectionState; setConnectionStatus: Dispatch<SetStateAction<HubConnectionState>>; isReconnected: boolean; } => {
    const [connectionStatus, setConnectionStatus] = useState<HubConnectionState>(HubConnectionState.Disconnected);
    const [isReconnected, setIsReconnected] = useState(false);

    useEffect(() => {

        setConnectionStatus(connection?.state || HubConnectionState.Disconnected);

        connection?.onclose((error) => {
            newrelic.addPageAction('signalr_event_on_close', error);
            setConnectionStatus(connection.state);
            setIsReconnected(false);
        });

        connection?.onreconnecting((error) => {
            newrelic.addPageAction('signalr_event_on_reconnecting', error);
            setConnectionStatus(connection.state);
        });

        connection?.onreconnected((connectionId) => {
            newrelic.addPageAction('signalr_event_on_reconnected', connectionId);
            setConnectionStatus(connection.state);
            setIsReconnected(true);
        });

    }, [connection, connection?.state]);

    return { connectionStatus, setConnectionStatus, isReconnected };
};

import React, { FC, ReactElement } from 'react';

import classes from './data-table.module.scss';

interface IDataTableRow {
    children: ReactElement | ReactElement[];
}

const DataTableRow: FC<IDataTableRow> = ({ children }) => {
    return (
        <div className={classes['row']}>
            {children}
        </div>

    );
};

export default DataTableRow;

import { CurrencyOptions, IPricingInformationProps, Prices } from 'Domain/Entities/PricingInfoEntities.entity';

const currencyOption: CurrencyOptions = {
    minFractionDigits: 1,
    maxFractionDigits: 5
}

const isShowGlassesGuide = (JSON.stringify(process.env.REACT_APP_ENABLE_GLASSES_GUIDE) === JSON.stringify("true"));

export const useGetPricingInfo = ({capRetail, capClean, capAverage, glassesGuide, inspectionDamageCost, siv} : IPricingInformationProps): Prices[] => {

    const prices: Prices[] = [
        {
            label: 'cap retail',
            value: capRetail ? capRetail.toCurreny() : '',
            className: 'cap-retail'
        },
        {
            label: 'cap clean',
            value: capClean ? capClean.toCurreny() : '',
            className: 'cap-clean'
        },
        {
            label: 'cap average',
            value: capAverage ? capAverage.toCurreny() : '',
            className: 'cap-average'
        }
    ];

    if (isShowGlassesGuide) {
        prices.push(
            {
                label: 'glass trade',
                value: glassesGuide ? glassesGuide.toCurreny() : '',
                className: 'glass-trade'
            }
        );
    }

    if (inspectionDamageCost) {
        const inspectionCost = parseFloat(inspectionDamageCost);

        prices.push(
            {
                label: 'inspection cost',
                value: inspectionCost || inspectionCost === 0 ? inspectionCost.toCurreny(currencyOption) : '',
                className: 'inspection-cost'
            }
        );
    }

    if (siv) {
        prices.push(
            {
                label: 'SIV',
                value: siv ? siv.toCurreny() : '',
                className: 'siv'
            }
        );
    }

    return prices;
};

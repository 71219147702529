import { Sale } from 'Domain/Entities/sale.entity';
import { useJoinSale } from 'Features/AuctionsList/auctions-list.service';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { saleSelectors } from 'services/auctions.slice';
import { browser_cookies, browser_session } from 'services/browser-storage';
import { AppState } from 'services/redux-store';

//Also move to common as will be required for vendors too.
export const useJoinSessionSale = () => {
    const sales = saleSelectors.selectAll(useSelector((state: AppState) => state.auctions.sales));
    const suid = browser_cookies.get('__suid');

    const { handleJoinSale } = useJoinSale();

    useEffect(() => {
        const _session = browser_session.get('__session');

        if (!_session || !sales.length) return;

        const queryParams = new URLSearchParams(_session);
        const auctionId = decodeURIComponent(queryParams.get('auctionId') ?? '');

        if (+auctionId <= 0) return;

        const { siteId = '', refNum = '' } = sales.find((x) => x.refNum === auctionId) || ({} as Sale);

        handleJoinSale(siteId, refNum, suid, true, false);

        browser_session.remove('__session');
    }, [sales]);
};

export default {};

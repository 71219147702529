import { HubConnection } from '@microsoft/signalr';
import { handleAuctioneerMessage } from 'Features/UserMessaging/chat-messaging.service';
import { OtherSession, OtherSessionType, setHasOtherSession, setUserId, upsertBlockedSale } from 'Infrastructure/UserAccount/user-account.slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'services/redux-store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useConfigureSignalRUserMethods = (connection: HubConnection | undefined): void => {
    const dispatch = useDispatch();
    const { userRole } = useSelector((state: AppState) => state.account);

    const onBeforeUnload = () => {
        connection?.invoke('Logout', false).then(() => connection.stop());
    }

    useEffect(() => {
        if (!connection) {
            return;
        }

        connection.on('userLoggedInElsewhere', (message) => {
            const payload: OtherSession = { 
                hasOtherSession: message, 
                otherSessionType: OtherSessionType.CLOSED 
            };

            dispatch(setHasOtherSession(payload));
        });

        connection.on('blockedStatusUpdate', (message) => {
            newrelic.addPageAction('signalr_event_blockedStatusUpdate', message);

            dispatch(upsertBlockedSale(message));
        });

        connection.on('blockMessage', (message) => {
            newrelic.addPageAction('signalr_event_blockMessage', message);

            dispatch(handleAuctioneerMessage(message));
        });

        connection.on('UserId', (message) => {
            dispatch(setUserId(message));
        });

        window.addEventListener('beforeunload', onBeforeUnload);

        return () => {
            connection.off('UserHasExistingSession');

            connection.off('blockedStatusUpdate');

            connection.off('blockMessage');

            connection.off('UserId');

            window.removeEventListener('beforeunload', onBeforeUnload);

        };
    }, [connection, userRole]);
};

import React, { FC, MouseEvent } from 'react';

import classes from './video-icon-button.module.css';

interface IVideoIconButton {
    icon: 'video' | 'picture' | 'sound';
    gtmClassName: 'gtm-toggle-video-trigger' | 'gtm-toggle-image-trigger' | 'gtm-toggle-audio-trigger'
    handleOnClick: (e: MouseEvent<HTMLAnchorElement>) => void;
    isActive: boolean;  
}

const VideoIconButton: FC<IVideoIconButton> = ({ gtmClassName, icon: iconType, handleOnClick, isActive}) => (
    <a href="#" className={`${gtmClassName} ${classes['a-icon']} ${classes[iconType]} ${isActive ? classes.active + ' gtm-active' : ''}`}
        onClick={handleOnClick} />
);

VideoIconButton.displayName = 'VideoIconButton';

export default VideoIconButton;

import React, { FC } from 'react';

import classes from '../chat-messaging.module.css';

interface IChatUnreadMessage {
    unreadCount: number;
}

const ChatUnreadMessageCount: FC<IChatUnreadMessage> = ({ unreadCount }) => {
    return (
        <span className={`${classes['badge']}`}>{unreadCount}</span>
    );
};

export default ChatUnreadMessageCount;

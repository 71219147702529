import { createAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { JoinedSale } from 'services/auctions.slice';
import { AppState } from 'services/redux-store';

type BiddingInfoMessage = {
    askingPrice: number;
    currentBid: number;
    isUser: boolean;
    refNum: string;
    userId: string;
    cid: string;
    cidb: string;
};

export type BiddingState = {
    askingPrice: number;
    currentBid: number;
    currentLot: string;
    haveBid: boolean;
    highestBid: number;
    isPaused: boolean;
    isUser: boolean;
    isUserIn: boolean;
    refNum: string;
    isOnSale: boolean;
};

const biddingAdapter = createEntityAdapter<BiddingState>({
    selectId: (entity) => entity.refNum,
    sortComparer: (a, b) => a.refNum.localeCompare(b.refNum)
});

const joinedSaleAction = createAction<JoinedSale>('auctions/addJoinedSale'); //From Auctions slice
const leaveSaleAction = createAction<JoinedSale>('auctions/leaveJoinedSale'); //From Auctions Slice
const lotUpdatedAction = createAction<any>('auctions/setSaleCurrentLot'); //From Auctions Slice

const biddingSlice = createSlice({
    name: 'bidding',
    initialState: biddingAdapter.getInitialState(),
    reducers: {
        setBiddingInfo: (state, { payload }: { payload: BiddingInfoMessage }) => {
            const { askingPrice, currentBid, isUser, refNum, userId, cidb } = payload;
            const entity = state.entities[refNum];
            const haveCurrentUserBid = () => (isUser ? true : (!userId.isEmptyOrWhitespace() && userId.isEqualIgoreCase(cidb)));

            if (entity) {
                const changes = {
                    currentBid,
                    haveBid: haveCurrentUserBid(),
                    askingPrice,
                    highestBid: haveCurrentUserBid() ? currentBid : -1,
                    isUser,
                    isUserIn: haveCurrentUserBid() || entity.isUserIn
                };

                biddingAdapter.updateOne(state, { id: refNum, changes });
            }

            return state;
        },
        setIsUserIn: (state, { payload }) => {
            const { refNum, amIIn } = payload;

            const entity = state.entities[refNum];

            if (entity) {
                const changes = {
                    isUserIn: amIIn
                };

                biddingAdapter.updateOne(state, { id: refNum, changes });
            }

            return state;
        },
        setHaveBid: (state, { payload }) => {
            const { refNum, haveBid } = payload;

            const entity = state.entities[refNum];

            if (entity) {
                const changes = {
                    haveBid
                };

                biddingAdapter.updateOne(state, { id: refNum, changes });
            }

            return state;
        },
        resetBiddingState: (state, { payload }) => { 
            const { refNum } = payload;

            const entity = state.entities[refNum];

            if (entity) {
                const changes = {
                    askingPrice: 0,
                    currentBid: 0,
                    isUserIn: false,
                    haveBid: false,
                    isUser: false,
                    highestBid: -1
                };

                biddingAdapter.updateOne(state, { id: refNum, changes });
            }
        },
        setIsPaused: (state, { payload }) => {
            const { refNum, isPaused } = payload;

            const entity = state.entities[refNum];

            if (entity) {
                const changes = {
                    isPaused
                };

                biddingAdapter.updateOne(state, { id: refNum, changes });
            }
        },
        setIsOnSale: (state, { payload }) => {
            const { refNum, isOnSale } = payload;

            const entity = state.entities[refNum];

            if (entity) {
                const changes = {
                    isOnSale: isOnSale
                };

                biddingAdapter.updateOne(state, { id: refNum, changes });
            }
        }
    },
    //Side effects when joining/leaving sale create/delete bidding history object
    extraReducers: (builder) => {
        builder.addCase(joinedSaleAction, (state, { payload = {} }) => {
            const { refNum = '' } = payload;
             
            if (refNum && !state.ids.includes(refNum)) {
                biddingAdapter.addOne(state, {
                    refNum,
                    currentLot: '',
                    isUser: false,
                    isUserIn: false,
                    askingPrice: 0,
                    currentBid: 0,
                    highestBid: -1,
                    haveBid: false,
                    isPaused: false,
                    isOnSale: false
                });
            }
        }),
            builder.addCase(leaveSaleAction, (state, { payload }) => {
                const { refNum } = payload;
                 
                if (state.ids.includes(refNum)) {
                    biddingAdapter.removeOne(state, refNum);
                }
            }),
            builder.addCase(lotUpdatedAction, (state, { payload }) => {

                const { refNum, lot, lightStatus: { onSale = false } } = payload;

                const entity = state.entities[refNum];
                 
                if (entity && entity.currentLot !== lot) {
                    const changes = {
                        currentLot: lot,
                        askingPrice: 0,
                        currentBid: 0,
                        isUserIn: false,
                        haveBid: false,
                        isUser: false,
                        highestBid: -1,
                        isOnSale: onSale
                    };

                    biddingAdapter.updateOne(state, { id: refNum, changes });
                }
            });
    }
});

export const { setBiddingInfo, setIsUserIn, setHaveBid, resetBiddingState, setIsPaused, setIsOnSale } = biddingSlice.actions;

export const biddingStateSelectors = biddingAdapter.getSelectors<AppState>((state) => state.bidding);

export default biddingSlice.reducer;

import Grade from 'Common/Grade/Grade';
import { DateHelper } from 'Common/Helpers/DateHelper';
import { ICurrentLot } from 'Common/Interfaces/current-lot.interfaces';
import React, { FC, ReactElement } from 'react';

import NotifyBadgeList from '../../../../Common/NotifyBadgeIcon/NotifyBadgeList';
import classes from './sale-preview.module.scss';

interface ISalePreviewStats {
    currentLot: ICurrentLot;
}

const SalePreviewStats: FC<ISalePreviewStats> = ({ currentLot }) => {

    const {
        lotDetails: { watching = false, announcement = '', remarks = '', transmission = '', regNo = '' } = {}
    } = currentLot;

    return (
        <ul className={classes['stats']}>
            <li className={classes['stat']}>{DateHelper.ToDateString(currentLot?.lotDetails?.regDate ?? '')}</li>
            <li className={classes['stat']}>{currentLot?.lotDetails?.regDate ? DateHelper.GetYearFromDate(currentLot?.lotDetails?.regDate) : ''} </li>
            <li className={classes['stat']}>{regNo}</li>
            <li className={classes['stat']}>
                {currentLot?.lotDetails?.mileage} {currentLot?.lotDetails?.mileageText}
            </li>
            <li className={classes['stat']}>
                {currentLot?.lotDetails?.engineSize} {transmission}
            </li>
            <li className={classes['stat']}>{currentLot?.lotDetails?.inspectionFuelType ?? ''} </li>
            <li className={classes['stat']}>{currentLot?.lotDetails?.bodyType} </li>
            <li className={`${classes['stat']} ${classes['no-sep']}`}>
                {currentLot?.lotDetails?.grade && (
                    <Grade grade={currentLot?.lotDetails?.grade} />
                )}
            </li>
            <li className={classes['badge-list']}>
                <NotifyBadgeList announcement={announcement} remarks={remarks} watching={watching} isSecondarySale={true} />
            </li>
        </ul>
    );
};

export default SalePreviewStats;
import React, { FC, ReactElement } from 'react';

import classes from './list-table.module.scss';

interface IRefreshLink {
    handleOnRefresh: (e: React.MouseEvent<HTMLAnchorElement>)=>void;
}

const RefreshLink: FC<IRefreshLink> = ({ handleOnRefresh}) => {
    return (        
            <a href="" title="Refresh" className={classes['refresh-link']} onClick={handleOnRefresh}>
                Refresh 
            </a>
    );
};

export default RefreshLink;

import { ILotDetails } from 'Common/Interfaces/current-lot.interfaces';
import { IApiLotViewModel } from 'Common/Sales/Sales.interface';

const makeModelDerivativeDisplay = (vehicleDetails: IApiLotViewModel | ILotDetails, includeRegNo: boolean): string => {
    const formatValue = (value: string | undefined, dash?: boolean) => {
        return !value ? '' : `${value}${dash ? ' - ' : ''}`;
    };

    const displayString =
        formatValue(vehicleDetails?.make, true) +
        formatValue(vehicleDetails?.model, true) +
        formatValue(vehicleDetails?.derivative, includeRegNo) +
        (includeRegNo ? formatValue(vehicleDetails?.regNo, false) : '');

    return displayString;
};

export const LotDisplayHelpers = {
    makeModelDerivativeDisplay
};

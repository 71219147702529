export enum AuctionControlColours {
    PREPARE_TO_BID_COLOUR = 'prepare',
    NOT_HIGEST_BIDDER_COLOUR = '',
    IS_HIGHEST_BIDDER_COLOUR = 'on-sale',
    BID_SUBMITTED_COLOUR = 'on-sale',
    IS_LOOKER_COLOUR = 'unauthorised',
    PREPARE_TO_SELL_COLOUR = 'unauthorised',
    CLICK_TO_SELL_COLOUR = '',
    ON_SALE_COLOUR = 'on-sale',
    UNAUTHORIZED_COLOUR = 'on-sale'
}

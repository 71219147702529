import ToggleSwitch from 'Common/ToggleSwitch/ToggleSwitch';
import React, { FC, MouseEvent } from 'react';

interface ISaleToggleControlProps {
    isActive: boolean;
    toggleShowPreview: (e: MouseEvent<HTMLDivElement>) => void;
}

const SaleToggleControl: FC<ISaleToggleControlProps> = ({ isActive, toggleShowPreview }) => {
    /*GTM: CSS Classes prefixed with gtm are required for GA. */
    return (
        <div className={`control gtm-toggle-switch gtm-auction-toggle ${isActive ? 'gtm-active' : ''}`} onClick={toggleShowPreview}>
			<ToggleSwitch isActive={isActive} />
        </div>
    );
};

export default SaleToggleControl;

import { Action, AnyAction, combineReducers, configureStore, Reducer, ThunkAction } from '@reduxjs/toolkit';
import biddingSlice from 'Features/AuctionControls/bidding-state.slice';
import historySlice from 'Features/BiddingHistory/bidding-details.slice';
import mainSale from 'Features/MainSale/main-sale.slice';
import chatSlice from 'Features/UserMessaging/chat-messaging.slice';
import accountSlice from 'Infrastructure/UserAccount/user-account.slice';
import buyerSlice from 'Pages/Common/buyer-vendor.slice';
import { reducer as toastrReducer } from 'react-redux-toastr';

import auctionsSlice from './auctions.slice';
import { browser_session } from './browser-storage';

const loadState = (): AppState => {
    const rawState = browser_session.get('__state');

    if (!rawState) {
        return {} as AppState;
    }

    const state = JSON.parse(rawState) as AppState;

    state.mainSale.lots = [];

    state.mainSale.proxyBids = [];

    state.mainSale.sale = undefined;

    state.mainSale.proxyBids = [];

    state.mainSale.currentLot = undefined;

    state.auctions.joinedSales = {
        entities: {},
        ids: []
    };

    state.history = {
        entities: {},
        ids: []
    };

    return state;
};

const saveState = (state: AppState): void => {
    const jsonSate = JSON.stringify(state);

    browser_session.set('__state', jsonSate);
};

//Changes made to clear redux state on logout,
//for explination see: https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki/61943631#61943631
const combinedReducers = combineReducers({
    account: accountSlice,
    buyer: buyerSlice,
    auctions: auctionsSlice,
    mainSale,
    toastr: toastrReducer,
    history: historySlice,
    bidding: biddingSlice,
    chat: chatSlice
});

const rootReducer: Reducer = (state: AppState = loadState(), action: AnyAction) => {
    if (action.type === 'logout') {
        state = {} as AppState;
    }

    return combinedReducers(state, action);
};

export const store = configureStore({
    reducer: rootReducer
});

store.subscribe(() => {
    saveState(store.getState());
});

export type AppState = ReturnType<typeof combinedReducers>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<unknown, AppState, null, Action<any>>;

export default store;

import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'services/redux-store';

import { setClientLoginInfo } from '../user-account.slice';
import { useUserAuthentication } from './useUserAuthentication.hook';

export const useUserClientLoginInfo = (): void => {
    const dispatch = useDispatch<AppDispatch>();
    const { account: { bearerToken: token } = {} } = useSelector((state: AppState) => state);
    const { logoutUser } = useUserAuthentication();

    useEffect(() => {
        if (token) {
            try {
                const decoded = jwt_decode<any>(token);

                dispatch(setClientLoginInfo(decoded?.cli ? JSON.parse(decoded['cli']) : undefined));
            } catch (error) {
                logoutUser();
            }
        } else {
            dispatch(setClientLoginInfo(undefined));
        }
    }, [token]);
};

import React, { FC, ReactElement } from 'react';

import classes from './list-table.module.scss';

interface IListTableRow {
    children: ReactElement|ReactElement[];
    className?: 'header' | 'row-entry' | 'row-bottom' | 'auction-result';
}

const ListTableRow: FC<IListTableRow> = ({ children, className}) => {
    return (
        <div className={`${classes['row']} ${className ? classes[className]:'' }`}>
            {children}
        </div>

    );
};

export default ListTableRow;

import { useWebSocketConnection } from 'Common/WebSocketConnection/WebSocketProvider'; 

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const useBidIntentImOut = (): ((refNum: string, siteId: string, lot: string, buyerNumber: string, bid: number, partner?: string) => void) => {
    const { sendSocketMessage } = useWebSocketConnection(); 

    const handleBidIntentImOut = (refNum: string, siteId: string, lot: string, buyerNumber: string, bid: number, partner = '') => {
        const start = performance.now();

        sendSocketMessage('ImOut', [bid, lot, siteId, refNum, buyerNumber, partner])
            .then(() => {
                const duration = performance.now() - start;

                newrelic.addPageAction('im_out_button', {
                    status: 'updated',
                    duration
                });
                 
            })
            .catch((error) => {
                window.logger.error(error);
            });
    };

    return handleBidIntentImOut;
};
